import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { GlobalSettingsCacheComponent } from "./GlobalSettingsCacheComponent";

export class GlobalSettingsRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Global Settings";

    render() {
        return <SellToWebAdminPageComponent>
            <nav className="nav nav-pills">
                <Link to="/Global/Settings/Cache" className={"nav-link " + (window.location.pathname.toLocaleLowerCase().trim("/", "") === "/global/settings" || window.location.pathname.toLocaleLowerCase().indexOf("/cache") !== -1 ? "active" : "")}>Cache</Link>
            </nav>
            <br />
            <div className="admin-flex-item">
                <Routes>
                    <Route index element={<GlobalSettingsCacheComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                    <Route path="/Cache" element={<GlobalSettingsCacheComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                </Routes>
            </div>
        </SellToWebAdminPageComponent>;
    }
}