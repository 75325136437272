import React, { Component, Fragment } from "react";

export class SellToWebTopPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfoBox: false,
        };

        this.onClick = this.onClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClickOutside);
    }

    onClick(event) {
        event.stopPropagation();
        this.setState(prevState => ({
            showInfoBox: !prevState.showInfoBox
        }));
    }

    handleClickOutside(event) {
        this.setState({ showInfoBox: false });
    }

    render() {
        return <aside className="consumer-top-panel">
            <div className="consumer-top-panel-container">
                {
                    this.state.showInfoBox
                        ? <div className="consumer-top-panel-evox-info-box d-flex align-items-center">
                            <img src="images/photo-camera.svg" className="me-3" alt="EVOX" />
                            <div>
                                <b>Images provided by EVOX</b>
                                <p className="mb-0">Image may not be a perfect match for your vehicle's model year, trim, body style, or color.</p>
                            </div>
                            <i className="bi bi-x"></i>
                        </div>
                        : null
                }
                <div className="d-flex">
                    <div className="position-relative">
                        {this.props.imageUrl ?
                            <Fragment>
                                <img src={this.props.imageUrl} className="consumer-top-panel-car-image" alt="Car" />
                                <img src="images/evox-info.svg" className="consumer-top-panel-evox-info-icon" alt="Info" onClick={this.onClick} />
                            </Fragment>
                            :
                            <p> No Image Available.</p>
                        }
                    </div>
                    <p className="consumer-top-panel-text" style={{fontSize:"16px", fontWeight:"500"}}>{this.props.makePlusModel}
                        <br />
                        <span className="fw-normal" style={{fontSize:"16px"}}>{this.props.trim}</span>
                    </p>
                </div>
            </div>
        </aside>
    }
}
