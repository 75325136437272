import React, { Component, Fragment } from "react";

export class SellToWebAdminEditBoxComponent extends Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (props.text !== state.prevText) {
            newState.editText = props.text ? props.text : "";
            newState.prevText = props.text;
        }
        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            editText: "",
            prevText: ""
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onTextChange(event) {
        switch (this.props.dataType) {
            case "money":
            case "decimal":
            case "percent":
                const decimalRegex = /^\d*(\.)?(\d{1,2})?$/;
                if (event.target.value === "" || decimalRegex.test(event.target.value)) {
                    this.setState({ editText: event.target.value });
                }
                break;

            case "number":
                const numberRegex = /^\d*$/;
                if (event.target.value === "" || numberRegex.test(event.target.value)) {
                    this.setState({ editText: event.target.value });
                }
                break;

            default:
                this.setState({ editText: event.target.value });
                break;
        }
    }

    onKeyDown(event) {
        switch (event.keyCode) {
            case 13:
                if (!this.props.readOnly) {
                    this.props.onClick(event, this.state.editText);
                }
                break;
            default:
                break;
        }
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                this.props.onHideClick();
                break;
            default:
                break;
        }
    }

    onClick(event) {
        if (event.stopPropagation) {
            event.stopPropagation();
            if (event.nativeEvent.stopImmediatePropagation) {
                event.nativeEvent.stopImmediatePropagation();
            }
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }
    }

    render() {
        let className = "";
        if (this.props.dataType === "comment") {
            className = "admin-input-comment";
        }

        return (
            this.props.showEdit
                ? <div style={{ position: "absolute", top: this.props.top, left: this.props.left, zIndex: "101" }} className={className} onClick={(event) => this.onClick(event)}>
                    <div className="input-group input-group-sm">
                        {
                            this.props.dataType === "comment"
                                ? <div className="card">
                                    <div className="card-header">
                                        {this.props.headerText}
                                        <button type="button" className="btn btn-link float-end" onClick={(event) => this.props.onHideClick(event)}>
                                            <span className="fas fa-times text-danger admin-icon" />
                                        </button>
                                        {
                                            !this.props.readOnly
                                                ? <button type="button" className="btn btn-link float-end" onClick={(event) => this.props.onClick(event, this.state.editText)}>
                                                    <span className="fas fa-check" />
                                                </button>
                                                : null
                                        }
                                    </div>
                                    <div className="card-block">
                                        <textarea type="text" className="form-control" disabled={this.props.disabled} readOnly={this.props.readOnly} placeholder={this.props.placeholder} value={this.state.editText} rows="5" onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} autoFocus />
                                    </div>
                                </div>
                                : <Fragment>
                                    <input type="text" disabled={this.props.disabled} readOnly={this.props.readOnly} className="form-control" placeholder={this.props.placeholder} value={this.state.editText} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} autoFocus />
                                    {
                                        !this.props.readOnly
                                            ? <button className="btn btn-secondary" type="button" onClick={(event) => this.props.onClick(event, this.state.editText)}>
                                                <i className="fas fa-check" />
                                            </button>
                                            : null
                                    }
                                    <button className="btn btn-secondary" type="button" onClick={(event) => this.props.onHideClick(event)}>
                                        <span className="fas fa-times text-danger" />
                                    </button>
                                </Fragment>
                        }
                    </div>
                </div>
                : null
        );
    }
}