import React, { Component } from "react";

export class SellToWebAdminToggleComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.checked, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        let controlSpan = this.props.controlSpan ?? "col-auto";
        let label = "";
        let label2 = "";
        if (this.props.className) {
            colSpan += " " + this.props.className;
        }
        if (this.props.controlClassName) {
            controlSpan += " " + this.props.controlClassName;
        }

        if (this.props.label) {
            label = <span dangerouslySetInnerHTML={{ __html: this.props.label }} />;
        }

        if (this.props.label2) {
            label2 = <span dangerouslySetInnerHTML={{ __html: this.props.label2 }} />;
        }

        return <div className={"admin-control " + colSpan}>
            <div className="input-group">
                {
                    this.props.label
                        ? <label className={"admin-label " + labelSpan}>{label}&nbsp;</label>
                        : null
                }
                <div className={"input-group admin-toggle " + controlSpan}>
                    <div className="form-check form-switch admin-toggle-form-switch">
                        <input type="checkbox" className="form-check-input" id={this.props.id} disabled={this.props.disabled} checked={this.props.value ? this.props.value : false} onChange={(event) => this.onChange(event)} />
                        <label className="form-check-label" htmlFor={this.props.id} />
                    </div>
                </div>
                {
                    this.props.label2
                        ? <label className="admin-label col-form-label">&nbsp;{label2}</label>
                        : null
                }
            </div>
        </div>;
    }
}
