import React, { Component } from "react";

export class SellToWebInputComponent extends Component {
    constructor(props) {
        super(props);

        this.onTextChange = this.onTextChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    onTextChange(event) {
        let value = event.target.value;
        if (this.props.onTextChange) {
            this.props.onTextChange(this.props.id, value);
        }
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.id, event.target.value);
        }
    }

    onKeyUp(event) {
        this.props.onTextChange(this.props.id, event.target.value);
    }

    render() {
        return <div className="input-group">
            {
                this.props.label ?
                    <label className={this.props.labelCss}>{this.props.label} : </label>
                    : null
            }
            <div className={"input-group ac-site-input " + (this.props.className ?? "")}>
                {
                    this.props.imagePath
                        ? <span className={"input-group-text consumer-input-group-text " + (this.props.heightCss ?? "")} flag-error={this.props.isError?.toString()} style={{ "borderRight": "none" }}>
                            <img src={this.props.imagePath} alt="Not Found" />
                        </span>
                        : <span className={"input-group-text consumer-input-group-text " + (this.props.heightCss ?? "")} flag-error={this.props.isError?.toString()} style={{ "padding": "7.5px", "borderRight": "none" }}></span>
                }
                <input type="text" id={this.props.id} placeholder={this.props.placeholder} aria-label={this.props.placeholder} value={this.props.value}
                    className={"form-control consumer-input-text " + (this.props.appendText ? "consumer-input-append-text " : "")} flag-error={this.props.isError?.toString()} style={{ "fontSize": "14px", "fontWeight": "500", "borderRight": this.props.isError && this.props.appendText ? "none" : "" }}
                    onChange={this.onTextChange} onKeyDown={this.props.onKeyDown} onBlur={this.onBlur} onKeyUp={this.onKeyUp} />
                {
                    this.props.helperText && !this.props.value
                        ? <span style={{ "position": "absolute", "color": "#757575", "right": "20px", "top": "20px", "fontStyle": "italic", "zIndex": "999", "fontSize": "12px" }}>{this.props.helperText}</span>
                        : null
                }
                {
                    this.props.appendText
                        ? <span className="input-group-text consumer-input-border-right-radius consumer-input-group-text" flag-error={this.props.isError?.toString()} style={{ "fontSize": "12px", "fontWeight": "500", "borderLeft": this.props.isError ? "none" : "" }}>{this.props.appendText}</span>
                        : null
                }
            </div>
            {
                this.props.isError
                    ? <span className="fas fa-arrow-left ac-site-error" />
                    : null
            }
            {
                this.props.isChecked
                    ? <span className="fas fa-check ac-site-check" />
                    : null
            }
        </div>;
    }
}