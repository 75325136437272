import React, { Component } from "react";
import $ from "jquery";

export class SellToWebModalConfirmationComponent extends Component {
    constructor(props) {
        super(props);

        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        $(this.modalContent).draggable({
            handle: ".card-header"
        });

        window.addEventListener("popstate", this.onPopState);
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onDocumentKeyDown(event) {
        switch (event.keyCode) {
            case 27:
                if (this.props.onCancelClick) {
                    this.props.onCancelClick(event);
                }
                break;

            default:
                break;
        }
    }

    onPopState(event) {
        $(".modal").modal("hide");
        $(".modal-backdrop").remove();
        $(".in").remove();
    }

    render() {
        let className = "modal-dialog " + this.props.className;
        return <div className={"modal fade show " + (this.props.showConfirmation ? " d-block" : "")} tabIndex="-1" role="dialog" data-backdrop="static" aria-hidden="true">
            <div className={className} role="document">
                <div ref={refElement => this.modalContent = refElement} className="modal-content">
                    <div className="card">
                        <div className="card-header app-pd-12">
                            {this.props.headerText}
                        </div>
                        <div className="card-block app-pd-12">
                            {this.props.bodyText}
                            <br />
                            <br />
                            <div className="text-center">
                                <button className="btn btn-primary" onClick={this.props.onOkClick} style={{width:"90px"}}>
                                    OK <i className="fas fa-check admin-icon" />
                                </button>
                                <button className="btn btn-primary admin-icon" onClick={this.props.onCancelClick}>
                                    Cancel <span className="fas fa-times admin-icon" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
