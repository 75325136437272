import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebModalDialogHeaderButtonComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogHeaderButtonComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminToggleComponent } from "../common/selltowebcheckbox/SellToWebAdminToggleComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";

export class TenantSiteOfferCalculationEditComponent extends Component {
    lowValueConditionData = [
        { key: "Excellent", value: "Excellent" },
        { key: "Very Good", value: "Very Good" },
        { key: "Good", value: "Good" },
        { key: "Fair", value: "Fair" }
    ];

    highValueConditionData = [
        { key: "Excellent", value: "Excellent" },
        { key: "Very Good", value: "Very Good" },
        { key: "Good", value: "Good" },
        { key: "Fair", value: "Fair" }
    ]

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            saveDisabled: true,
            enableYMMEdit: false,
            makes: null,
            selectedMake: null,
            years: CommonService.getPreviousYearsByDate(25),
            selectedYear: null,
            models: null,
            selectedModel: null,
            selectedLowValueCondition: null,
            selectedHighValueCondition: null,
            excellentCondition: 0,
            veryGoodCondition: 0,
            goodCondition: 0,
            fairCondition: 0,
            accidentDeductionPct: 0,
            missingKeyDeduction: 0,
            generalAdjustmentPct: 0,
            maxMileageAdjustmentPct: 0,
            noOfferOnMajorIssues: false,
            enableOfferCalculation: true
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onSaveAsClick = this.onSaveAsClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDisableSave = this.onDisableSave.bind(this);
        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onModelClick = this.onModelClick.bind(this);
        this.onLowValueConditionClick = this.onLowValueConditionClick.bind(this);
        this.onHighValueConditionClick = this.onHighValueConditionClick.bind(this);
    }

    componentDidMount() {
        try {
            this.getVehicleMakes();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "componentDidMount");
        }
    }


    componentDidUpdate(prevProps, prevState) {
        try {
            if (!prevProps.showDialog && this.props.showDialog) {
                if (this.props.isAddingRecord) {
                    this.setState({
                        selectedYear: null,
                        selectedMake: null,
                        models: null,
                        selectedModel: null
                    });
                }
                else if (this.props.rowData) {
                    if (!this.props.rowData.year && !this.props.rowData.make && !this.props.rowData.model) {
                        this.setState({
                            selectedYear: null,
                            selectedMake: null,
                            models: null,
                            selectedModel: null
                        }, () => {
                            this.fillForm(this.props.rowData);
                        });
                    }
                    else if ((this.props.rowData.year && !this.state.selectedYear) || (this.props.rowData.make && !this.state.selectedMake)) {
                        let selectedYear = this.props.rowData.year ? CommonService.formatYear(this.props.rowData.year.toString()) : null;
                        let selectedMake = (this.state.makes && this.props.rowData.make)
                            ? this.state.makes.filter(make => make.makeName.toString() === this.props.rowData.make.toString())[0]
                            : null;

                        this.setState({
                            selectedYear: selectedYear,
                            selectedMake: selectedMake,
                            models: null,
                            selectedModel: null
                        }, () => {
                            this.fillForm(this.props.rowData);
                            if (this.props.rowData.make) {
                                this.getVehicleModels();
                            }
                        });

                        if (!prevState.models && this.state.models && this.props.rowData.model) {
                            let selectedModel = this.state.models.filter(model => model.modelName.toString() === this.props.rowData.model.toString())[0];
                            this.setState({
                                selectedModel: selectedModel
                            });
                        }
                    }
                }
            }
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "componentDidUpdate");
        }
    }

    clearForm() {
        this.setState({
            selectedMake: null,
            selectedYear: null,
            models: null,
            selectedModel: null,
            selectedLowValueCondition: null,
            selectedHighValueCondition: null,
            excellentCondition: 0,
            veryGoodCondition: 0,
            goodCondition: 0,
            fairCondition: 0,
            accidentDeductionPct: 0,
            missingKeyDeduction: 0,
            generalAdjustmentPct: 0,
            maxMileageAdjustmentPct: 0,
            missingEquipmentDeduction: 0,
            noOfferOnMajorIssues: false,
            enableOfferCalculation: true,
            saveDisabled: true,
            enableYMMEdit: false,
            isLoadingData: false
        }, this.onDisableSave);
    }

    fillForm(rowData) {
        let selectedLowValueCondition = this.lowValueConditionData.filter(item => item.value === rowData.lowValueCondition)[0];
        let selectedHighValueCondition = this.highValueConditionData.filter(item => item.value === rowData.highValueCondition)[0];
        this.setState({
            selectedLowValueCondition: selectedLowValueCondition,
            selectedHighValueCondition: selectedHighValueCondition,
            excellentCondition: CommonService.formatNumber_2(rowData.excellentCondition),
            veryGoodCondition: CommonService.formatNumber_2(rowData.veryGoodCondition),
            goodCondition: CommonService.formatNumber_2(rowData.goodCondition),
            fairCondition: CommonService.formatNumber_2(rowData.fairCondition),
            accidentDeductionPct: CommonService.formatNumber_1(rowData.accidentDeductionPct * 100),
            missingKeyDeduction: CommonService.formatNumber_1(rowData.missingKeyDeduction * 100),
            generalAdjustmentPct: CommonService.formatNumber_1(rowData.generalAdjustmentPct * 100),
            maxMileageAdjustmentPct: CommonService.formatNumber_1(rowData.maxMileageAdjustmentPct * 100),
            noOfferOnMajorIssues: rowData.noOfferOnMajorIssues,
            enableOfferCalculation: rowData.enableOfferCalculation,
            isLoadingData: false
        }, this.onDisableSave);
    }

    getVehicleMakes() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : ""
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationEditComponent/getVehicleMakes", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleMakes", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        makes: parsedResponse.items,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationEditComponent", "getVehicleMakes");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationEditComponent", "getVehicleMakes");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "getVehicleMakes");
        }
    }

    getVehicleModels() {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : "",
                makeId: this.state.selectedMake.makeId,
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationEditComponent/getVehicleModels", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetVehicleModels", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let selectedModel = null;
                    if (this.props.rowData && this.props.rowData.model && !this.state.models && !this.state.selectedModel) {
                        selectedModel = parsedResponse.items.filter(model => model.modelName.toString() === this.props.rowData.model.toString())[0];
                    }

                    this.setState({
                        models: parsedResponse.items,
                        selectedModel: selectedModel,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetVehicleModels", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationEditComponent", "getVehicleModels");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationEditComponent", "getVehicleModels");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "getVehicleModels");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onTextChange", id);
        }
    }

    onCheckboxChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onCheckboxChange", id);
        }
    }

    onSaveClick() {
        try {
            this.setState({
                isLoadingData: true,
                saveDisabled: true
            });

            let inputData = {
                siteGuid: this.props.rowData ? this.props.rowData.siteGuid : this.props.selectedSite.guid,
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear.toString()) : null,
                make: this.state.selectedMake ? this.state.selectedMake.makeName : null,
                model: this.state.selectedModel ? this.state.selectedModel.modelName : null,
                trim: null,
                lowValueCondition: this.state.selectedLowValueCondition.value,
                highValueCondition: this.state.selectedHighValueCondition.value,
                excellentCondition: this.state.excellentCondition ? parseFloat(this.state.excellentCondition.replace(/,/g, '')) : 0,
                veryGoodCondition: this.state.veryGoodCondition ? parseFloat(this.state.veryGoodCondition.replace(/,/g, '')) : 0,
                goodCondition: this.state.goodCondition ? parseFloat(this.state.goodCondition.replace(/,/g, '')) : 0,
                fairCondition: this.state.fairCondition ? parseFloat(this.state.fairCondition.replace(/,/g, '')) : 0,
                accidentDeductionPct: this.state.accidentDeductionPct > 0 ? this.state.accidentDeductionPct / 100 : parseFloat((this.state.accidentDeductionPct.toString()).replace('(', '-').replace(')', '')) / 100,
                missingKeyDeduction: this.state.missingKeyDeduction > 0 ? this.state.missingKeyDeduction / 100 : parseFloat((this.state.missingKeyDeduction.toString()).replace('(', '-').replace(')', '')) / 100,
                generalAdjustmentPct: this.state.generalAdjustmentPct > 0 ? this.state.generalAdjustmentPct / 100 : parseFloat((this.state.generalAdjustmentPct.toString()).replace('(', '-').replace(')', '')) / 100,
                maxMileageAdjustmentPct: this.state.maxMileageAdjustmentPct > 0 ? this.state.maxMileageAdjustmentPct / 100 : parseFloat((this.state.maxMileageAdjustmentPct.toString()).replace('(', '-').replace(')', '')) / 100,
                noOfferOnMajorIssues: this.state.noOfferOnMajorIssues,
                enableOfferCalculation: this.state.enableOfferCalculation,
                isDelete: false,
                siteName: this.props.selectedSite.siteName,
                tenantShortName: this.props.selectedTenant.shortName,
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/SaveSiteCalculationVariable", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false
                    }, () => {
                        this.clearForm();
                        this.props.onSaveClick();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationEditComponent", "onSaveClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationEditComponent", "onSaveClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onSaveClick");
        }
    }

    onSaveAsClick() {
        try {
            this.setState({ enableYMMEdit: true });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onSaveAsClick");
        }
    }

    onCancelClick() {
        try {
            this.clearForm();
            this.props.onCancelClick();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onCancelClick");
        }
    }

    onDisableSave() {
        try {
            let disabled = !this.state.selectedLowValueCondition
                || !this.state.selectedHighValueCondition
                || (this.state.excellentCondition === null || this.state.excellentCondition === "" || this.state.excellentCondition === undefined)
                || (this.state.veryGoodCondition === null || this.state.veryGoodCondition === "" || this.state.veryGoodCondition === undefined)
                || (this.state.goodCondition === null || this.state.goodCondition === "" || this.state.goodCondition === undefined)
                || (this.state.fairCondition === null || this.state.fairCondition === "" || this.state.fairCondition === undefined)
                || (this.state.accidentDeductionPct === null || this.state.accidentDeductionPct === "" || this.state.accidentDeductionPct === undefined)
                || (this.state.missingKeyDeduction === null || this.state.missingKeyDeduction === "" || this.state.missingKeyDeduction === undefined)
                || (this.state.generalAdjustmentPct === null || this.state.generalAdjustmentPct === "" || this.state.generalAdjustmentPct === undefined)
                || (this.state.maxMileageAdjustmentPct === null || this.state.maxMileageAdjustmentPct === "" || this.state.maxMileageAdjustmentPct === undefined);

            if (!disabled) {
                disabled = !(
                    (this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && this.state.selectedModel)
                    || (!this.state.selectedYear && this.state.selectedMake && !this.state.selectedModel)
                    || (this.state.selectedYear && !this.state.selectedMake && !this.state.selectedModel)
                    || (!this.state.selectedYear && !this.state.selectedMake && !this.state.selectedModel)
                );
            }

            this.setState({ saveDisabled: disabled });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onDisableSave");
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                if (this.state.selectedMake !== null) {
                    this.getVehicleModels();
                }
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onYearClick", id);
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({
                selectedMake: make,
                models: null,
                selectedModel: null
            }, () => {
                this.onDisableSave();
                this.getVehicleModels();
            });

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onMakeClick", id);
        }
    }

    onModelClick(id, model) {
        try {
            this.setState({
                selectedModel: model
            }, () => {
                this.onDisableSave();
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onModelClick", id);
        }
    }

    onLowValueConditionClick(id, selectedValue) {
        try {
            this.setState({ selectedLowValueCondition: selectedValue });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onLowValueConditionClick", id);
        }
    }

    onHighValueConditionClick(id, selectedValue) {
        try {
            this.setState({ selectedHighValueCondition: selectedValue });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationEditComponent", "onHighValueConditionClick", id);
        }
    }

    render() {
        let modalTitle = "Site Configuration";
        if (this.props.rowData) {
            let extendedTitle = (this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : "")
                + (this.state.selectedMake && this.state.selectedMake.makeName ? " " + this.state.selectedMake.makeName : "")
                + (this.state.selectedModel && this.state.selectedModel.modelName ? " " + this.state.selectedModel.modelName : "");

            if (extendedTitle !== "")
                modalTitle += " - " + extendedTitle;
        }

        return <SellToWebAdminModalDialogComponent
            showDialog={this.props.showDialog}
            className="admin-site-configuration-dialog"
            title={modalTitle}
            onCancelClick={this.onCancelClick}
        >
            <SellToWebModalDialogHeaderButtonComponent>
                <button className={this.props.isAddingRecord ? "d-none" : "btn btn-primary admin-icon "} onClick={this.onSaveAsClick} disabled={this.state.enableYMMEdit}>
                    Save As <i className="fas fa-save admin-icon" />
                </button>
                <button className="btn btn-primary admin-icon " onClick={this.onSaveClick} disabled={this.state.saveDisabled}>
                    Save <i className="fas fa-save admin-icon" />
                </button>
            </SellToWebModalDialogHeaderButtonComponent>
            <div className="admin-body">
                <div className="card">
                    <div className="card-block">
                        <div className="row">
                            <SellToWebAdminDropdownComponent label="Make" data={this.state.makes} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} itemKey="makeName" onItemClick={this.onMakeClick} value={this.state.selectedMake ? this.state.selectedMake.makeName : "Select Make"} colSpan="col-4" controlSpan="col-6" />
                            <SellToWebAdminDropdownComponent label="Year" displayFormat="Year" id="years" data={this.state.years} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} value={this.state.selectedYear} onItemClick={this.onYearClick} colSpan="col-3" controlSpan="col-9" />
                            <SellToWebAdminDropdownComponent label="Model" data={this.state.models} disabled={!this.props.isAddingRecord && !this.state.enableYMMEdit} itemKey="modelName" onItemClick={this.onModelClick} value={this.state.selectedModel ? this.state.selectedModel.modelName : "Select Model"} colSpan="col-4" controlSpan="col-8" />
                            <SellToWebSpinnerComponent showSpinner={this.state.isLoadingData} />
                        </div>
                        <div className="row">
                            <SellToWebAdminDropdownComponent label="Low Value Condition" data={this.lowValueConditionData} disabled={this.state.enableYMMEdit} itemKey="value" onItemClick={this.onLowValueConditionClick} value={this.state.selectedLowValueCondition ? this.state.selectedLowValueCondition.value : "Select Low Value Condition"} colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminDropdownComponent label="High Value Condition" data={this.highValueConditionData} disabled={this.state.enableYMMEdit} itemKey="value" onItemClick={this.onHighValueConditionClick} value={this.state.selectedHighValueCondition ? this.state.selectedHighValueCondition.value : "Select High Value Condition"} colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="excellentCondition" label="Excellent Condition (0-1)" disabled={this.state.enableYMMEdit} value={this.state.excellentCondition} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="veryGoodCondition" label="Very Good Condition (0-1)" disabled={this.state.enableYMMEdit} value={this.state.veryGoodCondition} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="goodCondition" label="Good Condition (0-1)" disabled={this.state.enableYMMEdit} value={this.state.goodCondition} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="fairCondition" label="Fair Condition (0-1)" disabled={this.state.enableYMMEdit} value={this.state.fairCondition} onChange={this.onTextChange} inputType="decimal" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="accidentDeductionPct" label="Accident Deduction" disabled={this.state.enableYMMEdit} value={this.state.accidentDeductionPct} onChange={this.onTextChange} inputType="percent" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="missingKeyDeduction" label="Missing Key Deduction" disabled={this.state.enableYMMEdit} value={this.state.missingKeyDeduction} onChange={this.onTextChange} inputType="percent" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="generalAdjustmentPct" label="General Adjustment" disabled={this.state.enableYMMEdit} value={this.state.generalAdjustmentPct} onChange={this.onTextChange} inputType="percent" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <SellToWebAdminInputComponent id="maxMileageAdjustmentPct" label="Max Mileage Adj Deduction" disabled={this.state.enableYMMEdit} value={this.state.maxMileageAdjustmentPct} onChange={this.onTextChange} inputType="percent" colSpan="col-8" labelSpan="col-7" controlSpan="col-5" />
                            <div className="admin-control col-8">
                                <div className="input-group">
                                    <div className="col-7 admin-label">No Offer on Major Issues</div>
                                    <div className="col-5">
                                        <SellToWebAdminToggleComponent id="noOfferOnMajorIssues" label="Disabled" label2="Enabled" disabled={this.state.enableYMMEdit} value={this.state.noOfferOnMajorIssues} onChange={this.onCheckboxChange} colSpan="col-9" />
                                    </div>
                                </div>
                            </div>
                            <div className="admin-control col-8">
                                <div className="input-group">
                                    <div className="col-7 admin-label">Enable Offer Calculation</div>
                                    <div className="col-5">
                                        <SellToWebAdminToggleComponent id="enableOfferCalculation" label="No" label2="Yes" disabled={this.state.enableYMMEdit} value={this.state.enableOfferCalculation} onChange={this.onCheckboxChange} colSpan="col-9" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SellToWebAdminModalDialogComponent>;
    }
}
