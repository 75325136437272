import React, { Component } from "react";

export class SellToWebColorPickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: this.props.selectedSiteAttribute.value
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the 'initialValue' prop has changed
    if (this.props.selectedSiteAttribute.value !== prevProps.selectedSiteAttribute.value) {
      // Update the state based on the new prop value
      this.setState({
        selectedColor: this.props.selectedSiteAttribute.value,
      });
    }
  }

  handleColorChange = (event) => {
    this.setState({
      selectedColor: event.target.value,
    }, () => {
      this.props.onTextChange(null, event.target.value);
    });
  };

  render() {
    let colSpan = this.props.colSpan ?? "col-auto";
    let labelSpan = this.props.labelSpan ?? "col-form-label";
    let controlSpan = this.props.controlSpan ?? "col-auto";
    if (this.props.className) {
      colSpan += " " + this.props.className;
    }
    if (this.props.labelClassName) {
      labelSpan += " " + this.props.labelClassName;
    }
    if (this.props.controlClassName) {
      controlSpan += " " + this.props.controlClassName;
    }

    return <div className={"admin-control " + colSpan}>
      <div className="input-group">
        {
          this.props.label
            ? <label className={"admin-label " + labelSpan} htmlFor={this.props.id}>
              {this.props.label}:
            </label>
            : null
        }
        <div className={controlSpan}>
          <div className="input-group">
            <label className="input-group-text">{this.state.selectedColor}</label>
            <input type="color" id="colorPicker" name="colorPicker" value={this.state.selectedColor} className="admin-color-picker-input" onChange={this.handleColorChange} />
            <button className="btn btn-primary admin-color-picker-btn">OK</button>
          </div>
        </div>
      </div>
      <br />
      <div className="input-group">
        <label className={"admin-label " + labelSpan}>Preview:</label>
        <div className={controlSpan + " admin-color-picker-preview"} style={{ "backgroundColor": this.state.selectedColor }}>&nbsp;</div>
      </div>
    </div>;

  }
}