import { Component, Fragment } from "react";
import { SellToWebAdminTextAreaComponent } from "../common/selltowebtextarea/SellToWebAdminTextAreaComponent";

export class TenantSiteHTMLComponent extends Component {
    render() {
        let value = this.props.selectedSiteAttribute?.value;
        let previewElement = <span className="col-8" dangerouslySetInnerHTML={{ __html: value }} />;

        if (value && this.props.selectedSiteAttribute?.area === "Site" && this.props.selectedSiteAttribute?.name === "Acquire Cars CSS") {
            value = this.formatCSS(value);
            value = "&lt;style&gt;\n" + value + "&lt;/style&gt;";
            previewElement = <pre>
                <code className="col-8" dangerouslySetInnerHTML={{ __html: value}} />
            </pre>
        }

        return <Fragment>
            <SellToWebAdminTextAreaComponent id={this.props.selectedSiteAttribute?.name} label="Value" value={this.props.selectedSiteAttribute?.value} rows="10" onChange={this.props.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-8" />
            <div className="admin-control col-12">
                <div className="input-group">
                    <label className="admin-label col-2">Preview:</label>
                    {previewElement}
                </div>
            </div>
        </Fragment>;
    }

    formatCSS = (css) => {
        // Split CSS code into individual rules
        const rules = css.split('}');
      
        // Initialize formatted CSS
        let formattedCss = '';
      
        // Iterate through each rule
        rules.forEach((rule) => {
          // Trim leading and trailing whitespace
          const trimmedRule = rule.trim();
      
          // Check if the trimmedRule is not an empty string
          if (trimmedRule !== '') {
            // Split each rule into its selector and properties
            const parts = trimmedRule.split('{');
      
            // Ensure parts array has both selector and properties
            if (parts.length >= 2 && parts[0] !== undefined && parts[1] !== undefined) {
              const selector = `<b>${parts[0].trim()}</b>`; // Bolden the selector
              const properties = parts.slice(1).join('{');
      
              // Format properties
              const formattedProperties = properties
                .split(';')
                .map((prop) => {
                  const [name, value] = prop.split(':');
                  if (name && value) {
                    return `${name.trim()}: ${value.trim()};`;
                  }
                  return '';
                })
                .filter(Boolean)
                .join('\n  ');
      
              // Add formatted rule to the result
              formattedCss += `${selector} {\n  ${formattedProperties}\n}\n\n`;
            }
          }
        });
      
        return formattedCss;
      };
}