import React, { Component } from "react";
export class SellToWebModalDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.onOkClick = this.onOkClick.bind(this);
    }

    onOkClick() {
        this.props.onOkClick();
    }

    render() {
        return <div className={"modal fade show " + (this.props.showDialog ? "d-block" : "")}>
            <div className={"modal-dialog " + (this.props.className ?? "")}>
                <div className="modal-content" style={{backgroundColor:"#F5FAFE",border:"1px solid #9ED4FF",borderRadius:"0px"}}>
                    <div className="modal-header" style={{border:"none",backgroundColor:"rgba(0, 130, 236, 0.04)"}}>
                        <h4 className="fw-bold modal-title" style={{fontSize:"16px"}}>{this.props.title}</h4>
                        {
                            !this.props.showFooter
                                ? <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.onOkClick}></button>
                                : null
                        }
                    </div>
                    <div className="modal-body" style={{fontSize:"15px"}}>
                        {this.props.children}
                    </div>
                    {
                        this.props.showFooter
                            ? <div className="modal-footer" style={{borderTop:"0px",paddingTop:"0px"}}>
                                <button type="button" className="ac-site-button" data-dismiss="modal" onClick={this.onOkClick} style={{borderRadius:"20px",width:"260px",margin:"0px auto"}}>OK</button>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>;
    }
}