import React, { Component } from "react";

export class SellToWebAdminTextAreaComponent extends Component {
    constructor(props) {
        super(props);

        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        let controlSpan = this.props.controlSpan ?? "col-auto";
        let placeHolder = this.props.placeholder ?? this.props.label;
        if (this.props.className) {
            colSpan += " " + this.props.className;
        }
        if (this.props.labelClassName) {
            labelSpan += " " + this.props.labelClassName;
        }
        if (this.props.controlClassName) {
            controlSpan += " " + this.props.controlClassName;
        }

        return <div className={"admin-control " + colSpan}>
            <div className="input-group">
                {
                    this.props.label
                        ? <label className={"admin-label " + labelSpan} htmlFor={this.props.id}>
                            {this.props.label}:
                            {
                                this.props.isRequired
                                    ? <span className="text-danger">*</span>
                                    : null
                            }
                        </label>
                        : null
                }
                <div className={controlSpan}>
                    <textarea type="text" id={this.props.id} disabled={this.props.disabled} readOnly={this.props.readOnly} rows={this.props.rows} cols={this.props.cols} className={"form-control " + (this.props.inputClassName ?? "")} placeholder={placeHolder} value={this.props.value ?? ""} onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} onBlur={(event) => this.onBlur(event)} />
                </div>
            </div>
        </div>;
    }

    onTextChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.value, this.props.additionalData);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.additionalData);
        }
    }

    onBlur(event) {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.id, event.target.value, this.props.additionalData);
        }
    }
}