import React, { Component, Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { GlobalHomeRouteComponent } from "../globalhome/GlobalHomeRouteComponent";
import { GlobalLogRouteComponent } from "../globallog/GlobalLogRouteComponent";
import { GlobalSettingsRouteComponent } from "../globalsettings/GlobalSettingsRouteComponent";
import { GlobalTenantRouteComponent } from "../globaltenant/GlobalTenantRouteComponent";
import { GlobalUserRouteComponent } from "../globaluser/GlobalUserRouteComponent";
import { AdminPageNotFoundRouteComponent } from "../pagenotfound/AdminPageNotFoundRouteComponent";
import { TenantOffersRouteComponent } from "../tenantoffers/TenantOffersRouteComponent"
import { TenantInvoiceRouteComponent } from "../tenantinvoice/TenantInvoiceRouteComponent";
import { TenantSettingsRouteComponent } from "../tenantsettings/TenantSettingsRouteComponent"
import { TenantSiteRouteComponent } from "../tenantsite/TenantSiteRouteComponent"

import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminSideBarComponent } from "../common/selltowebsidebar/SellToWebAdminSideBarComponent";
import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

import "../../admin.css";

export class AdminRouteComponent extends Component {
    static TenantCookie = "TenantSiteTenantCookie";
    static SiteCookie = "TenantSiteSiteCookie";

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            isAuthenticated: false,
            canExitImpersonation: false,
            pageTitle: "Acquire Cars",
            identityName: "",
            logon: "",
            tenantName: "",
            sideBarState: "Expanded",
            menuItems: [],
            globalMenuItems: [],
            isGlobalAdmin: false,
            isTenantAdmin: false
        }

        this.onToggleMenuState = this.onToggleMenuState.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
    }

    componentWillMount() {
        document.body.className = "admin-site";
    }

    componentWillUnmount() {
        CommonService.clientAzureStorageLog("AdminRouteComponent", "componentWillUnmount", null, null, null, null, this.props.applicationInsights);
        document.body.className = "";
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("AdminRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getAdminData();
        }
        catch (error) {
            console.logError(error, "AdminRouteComponent", "componentDidMount");
        }
    }

    getAdminData() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "AdminRouteComponent/getAdminData" });
            fetch("/Admin/GetAdminData", {
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        isAuthenticated: parsedResponse.isAuthenticated,
                        canExitImpersonation: parsedResponse.canExitImpersonation,
                        identityName: parsedResponse.identityName,
                        logon: parsedResponse.logon,
                        tenantName: parsedResponse.tenantName,
                        isGlobalAdmin: parsedResponse.isGlobalAdmin,
                        isTenantAdmin: parsedResponse.isTenantAdmin,
                        menuItems: parsedResponse.menuItems,
                        globalMenuItems: parsedResponse.globalMenuItems
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Admin/GetAdminData", ...parsedError } });
                                console.logError(parsedError, "AdminRouteComponent", "getAdminData");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "AdminRouteComponent", "getAdminData");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "AdminRouteComponent", "getAdminData");
        }
    }

    onPageChanged(pageState) {
        try {
            this.setState({
                pageTitle: pageState.pageTitle
            });
            this.props.onPageLoad({ pageTitle: pageState.pageTitle });

            this.props.onHideAlert();
        }
        catch (error) {
            console.logError(error, "AdminRouteComponent", "onPageChanged");
        }
    }

    onToggleMenuState() {
        try {
            this.setState({
                sideBarState: this.state.sideBarState === "Expanded" ? "Collapsed" : "Expanded"
            });
        }
        catch (error) {
            console.logError(error, "AdminRouteComponent", "onToggleMenuState");
        }
    }

    render() {
        return <Fragment>
            {
                this.state.isLoadingData
                    ? <SellToWebSpinnerComponent showSpinner={this.state.isLoadingData} />
                    : !this.state.isAuthenticated
                        ? <Routes>
                            <Route path="*" element={<GlobalHomeRouteComponent />} />
                        </Routes>
                        : <div className="d-flex flex-row admin-site">
                            <SellToWebAdminSideBarComponent {...this.props} sideBarState={this.state.sideBarState}
                                isAuthenticated={this.state.isAuthenticated} isGlobalAdmin={this.state.isGlobalAdmin} isTenantAdmin={this.state.isTenantAdmin}
                                menuItems={this.state.menuItems} globalMenuItems={this.state.globalMenuItems}
                                onToggleMenuState={this.onToggleMenuState} />
                            <div className="admin-flex-item admin-main">
                                <div className="d-flex flex-column admin-fill-content">
                                    <SellToWebAdminHeaderComponent isAuthenticated={this.state.isAuthenticated} canExitImpersonation={this.state.canExitImpersonation}
                                        pageTitle={this.state.pageTitle} name={this.state.identityName} logon={this.state.logon} tenantName={this.state.tenantName} />
                                    <div className="admin-flex-item admin-fill-content">
                                        <Routes>
                                            <Route path="Global/Tenant" element={<GlobalTenantRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Global/Log" element={<GlobalLogRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Global/Settings/*" element={<GlobalSettingsRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Global/User" element={<GlobalUserRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Tenant/Offers" element={<TenantOffersRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} isGlobalAdmin={this.state.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Tenant/Invoice" element={<TenantInvoiceRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} isGlobalAdmin={this.state.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Tenant/Settings" element={<TenantSettingsRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} isGlobalAdmin={this.state.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="Tenant/*" element={<TenantSiteRouteComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.onPageChanged} isGlobalAdmin={this.state.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                                            <Route path="*" element={<AdminPageNotFoundRouteComponent />} />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                            <SellToWebAdminFooterComponent />
                        </div >
            }
        </Fragment>;
    }
}
