import React, { Component } from "react";

export class SellToWebAdminHeaderComponent extends Component {
    render() {
        let nameParts = this.props.name?.split(' ');
        let shortName = "";
        if (nameParts && nameParts[0].length > 0) {
            shortName = nameParts[0][0];
        }

        if (nameParts && nameParts.length > 1 && nameParts[1].length > 0) {
            shortName += " " + nameParts[1][0];
        }

        return <div className="admin-header">
            &nbsp;
            {
                this.props.showLogo
                    ? <a href="/">
                        <div className="d-inline">
                            <img src="images/group.svg" alt="Group" />
                            <h4 className="d-inline app-font-family-bold app-fs-21 admin-header-text">ACQUIRE CARS</h4>
                        </div>
                    </a>
                    : <div className="app-fs-18 app-fw-700 float-start">
                        {this.props.pageTitle}
                    </div>
            }
            <div className="app-fs-14 float-end">
                <div className="form-inline">
                    {
                        this.props.isAuthenticated
                            ? <div className={"form-group dropdown admin-dropdown admin-icon " + (this.props.canExitImpersonation ? "admin-data-flag-red" : "")}>
                                <div className="btn-link dropdown-toggle app-fs-12 app-fw-700" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                    {(this.props.canExitImpersonation ? "Impersonating: " : "") + this.props.name} &nbsp;
                                </div>
                                <div className="dropdown-menu" x-placement="bottom-end">
                                    <div className="dropdown-header admin-header-dropdown">
                                        <table className="text-nowrap app-fw-700">
                                            <tbody>
                                                <tr>
                                                    <td className="admin-header-profile">{shortName}</td>
                                                    <td>{this.props.logon}<br />{this.props.tenantName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        (this.props.canExitImpersonation)
                                            ? <a className="dropdown-item admin-dropdown-item" href="/Account/ExitImpersonation">Exit Impersonation</a>
                                            : null
                                    }
                                    <a className="dropdown-item admin-dropdown-item admin-header-dropdown-item app-fs-13" href="/MicrosoftIdentity/Account/SignOut">Sign Out</a>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    }

}