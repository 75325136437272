import { Component, Fragment } from "react";

import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";

export class TenantSiteImageComponent extends Component {
    render() {
        return <Fragment>
            <SellToWebAdminInputComponent id={this.props.selectedSiteAttribute?.name} label="Value" value={this.props.selectedSiteAttribute?.value} onChange={this.props.onTextChange} colSpan="col-12" labelSpan="col-2" controlSpan="col-8" />
            <div className="admin-control col-12">
                <div className="input-group">
                    <label className={"admin-label col-2"}>Preview:</label>
                    <img width="col-8" src={this.props.selectedSiteAttribute?.value} alt="Not available" />
                </div>
            </div>
        </Fragment>;
    }
}