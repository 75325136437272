import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";

import { TenantSiteOfferCalculationComponent } from "./TenantSiteOfferCalculationComponent";
import { TenantSiteAttributesComponent } from "./TenantSiteAttributesComponent";
import { TenantSiteDetailComponent } from "./TenantSiteDetailComponent";

export class TenantSiteRouteComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Site";

    render() {
        return <SellToWebAdminPageComponent>
            <nav className="nav nav-pills">
                <Link to="/Tenant/Site" className={"nav-link " + ((window.location.pathname.toLocaleLowerCase().includes("site") && !window.location.pathname.toLocaleLowerCase().includes("attribute")) ? "active" : "")}>Site Detail</Link>
                <Link to="/Tenant/SiteAttribute" className={"nav-link " + (window.location.pathname.toLocaleLowerCase().includes("siteattribute") ? "active" : "")}>Site Attributes</Link>
                <Link to="/Tenant/OfferCalculation" className={"nav-link " + (window.location.pathname.toLocaleLowerCase().includes("offercalculation") ? "active" : "")}>Offer Calculation</Link>
            </nav>
            <br />
            <div className={"admin-flex-item " + (window.location.pathname.toLocaleLowerCase().includes("siteattribute") ? "admin-fill-content" : "")}>
                <Routes>
                    <Route index element={<TenantSiteDetailComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                    <Route path="Site/:tenantGuid?/:siteGuid?" element={<TenantSiteDetailComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                    <Route path="SiteAttribute" element={<TenantSiteAttributesComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                    <Route path="OfferCalculation" element={<TenantSiteOfferCalculationComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} logon={this.state.logon} />} />
                </Routes>
            </div>
        </SellToWebAdminPageComponent>;
    }
}