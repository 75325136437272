import React, { Component } from "react";

export class SellToWebAdminFooterComponent extends Component {
    render() {
        return <div className="fixed-bottom admin-footer">
            <div className="d-inline-block app-fw-700 admin-footer-text">
                © 2024 - Acquire Cars
            </div>
        </div>;
    }
}