import { Component } from "react";
import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";

import { GlobalLogQueryComponent } from "./GlobalLogQueryComponent";

export class GlobalLogRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableTitle: "",
            tableData: [],
            paintTable: false,
            isLoadingData: false
        };

        this.onGetLogsClick = this.onGetLogsClick.bind(this);
        this.onGetErrorsClick = this.onGetErrorsClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Log";

    onGetLogsClick(startDate, endDate, sessionId, filter) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate),
                sessionId: sessionId,
                filter: filter
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalLogRouteComponent/onGetLogsClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalLog/List", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        if (responseItem.type === "error") {
                            responseItem.type_cellClassName += " admin-global-log-type-error"
                        } else if (responseItem.type === "warn") {
                            responseItem.type_cellClassName += " admin-global-log-type-warn"
                        }
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalLog/List", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalLogRouteComponent", "onGetLogsClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalLogRouteComponent", "onGetLogsClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalLogRouteComponent", "onGetLogsClick");
        }
    }

    onGetErrorsClick(startDate, endDate) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                startDate: CommonService.formatServerDateTime(startDate),
                endDate: CommonService.formatServerDateTime(endDate)
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalLogRouteComponent/onGetErrorsClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalLog/ListErrors", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.forEach((responseItem, index) => {
                        responseItem.type_cellClassName += " admin-global-log-type-error"
                    });

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalLog/ListErrors", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalLogRouteComponent", "onGetErrorsClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalLogRouteComponent", "onGetErrorsClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalLogRouteComponent", "onGetErrorsClick");
        }
    }

    render() {
        return <SellToWebAdminPageComponent>
            <div className="admin-flex-item admin-body">
                <div className="d-flex flex-column admin-fill-content">
                    <GlobalLogQueryComponent isLoadingData={this.state.isLoadingData} onGetLogsClick={this.onGetLogsClick} onGetErrorsClick={this.onGetErrorsClick} onShowAlert={this.props.onShowAlert} applicationInsights={this.props.applicationInsights} />
                    <br />
                    <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader
                        copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] }}>
                        <SellToWebTableRowComponent className="text-nowrap">
                            <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <SellToWebTableColumnComponent headerText="Timestamp" dataColumn="timestamp" sortColumn="timestamp" filterColumn="timestamp" dataType="dateTime_1" />
                            <SellToWebTableColumnComponent headerText="Session ID" dataColumn="sessionID" sortColumn="sessionID" filterColumn="sessionID" />
                            <SellToWebTableColumnComponent headerText="Type" dataColumn="type" sortColumn="type" filterColumn="type" />
                            <SellToWebTableColumnComponent headerText="Component" dataColumn="component" sortColumn="component" filterColumn="component" />
                            <SellToWebTableColumnComponent headerText="Part 2" dataColumn="part2" sortColumn="part2" filterColumn="part2" />
                            <SellToWebTableColumnComponent headerText="Part 3" dataColumn="part3" sortColumn="part3" filterColumn="part3" />
                            <SellToWebTableColumnComponent headerText="Part 4" dataColumn="part4" sortColumn="part4" filterColumn="part4" />
                            <SellToWebTableColumnComponent headerText="part 5" dataColumn="part5" sortColumn="part5" filterColumn="part5" />
                            <SellToWebTableColumnComponent headerText="Part 6" dataColumn="part6" sortColumn="part6" filterColumn="part6" />
                        </SellToWebTableRowComponent>
                    </SellToWebTableComponent>
                </div>
            </div>
        </SellToWebAdminPageComponent>;
    }
}