import React, { Component, Fragment } from "react";
import { CommonService } from "../../js_modules/CommonService";
import { SellToWebModalDialogComponent } from "../selltowebmodaldialog/SellToWebModalDialogComponent";

export class SellToWebRightPanelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInfoBox: false,
            showDialog: false
        };

        this.onClick = this.onClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onTermsClick = this.onTermsClick.bind(this);
        this.onDismissModalClick = this.onDismissModalClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClickOutside);
    }

    onClick(event) {
        event.stopPropagation();
        this.setState(prevState => ({
            showInfoBox: !prevState.showInfoBox
        }));
    }

    onTermsClick(event) {
        this.setState({ showDialog: true });
    }

    handleClickOutside(event) {
        this.setState({ showInfoBox: false });
    }

    imageBaseUrl = "https://cdn.acquirecars.com/image/EVOX/";

    onMissingImage(event) {
        try {
            CommonService.clientAzureStorageLog("SellToWebRightPanelComponent", "CDN image not found", this.props.imageUrl, this.props.vin, null, null, this.props.applicationInsights);
            if (this.props.onMissingImage) {
                this.props.onMissingImage();
            }
        }
        catch (error) {
            console.logError(error, "SellToWebRightPanelComponent", "onMissingImage");
        }
    }

    onDismissModalClick() {
        try {
            this.setState({ showDialog: false });
        }
        catch (error) {
            console.logError(error, "SellToWebRightPanelComponent", "onDismissModalClick");
        }
    }

    render() {
        let vehicleDetail = <p style={{ fontSize: "15px", fontWeight: "500" }}>
            {this.props.makePlusModel}
            <br />
            <span className="fw-normal">{this.props.trim}</span>
            {
                this.props.differentColorShown
                    ? <p className="consumer-right-panel-diff-color" style={{ fontSize: "10px", fontWeight: "500" }}>DIFFERENT COLOR SHOWN</p>
                    : null
            }
        </p>;
        return <div className="consumer-right-panel">
            <SellToWebModalDialogComponent
                showDialog={this.state.showDialog} showFooter
                title="Terms & Conditions"
                onOkClick={this.onDismissModalClick}
                className="consumer-right-panel-terms-condition">
                <h4 className="consumer-right-panel-terms-condition-title">Our lawyers made us write this...</h4>
                <p>
                    {
                        this.props.siteAttributes
                            ? <span dangerouslySetInnerHTML={{ __html: CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Terms & Conditions Html").Value }} />
                            : null
                    }
                </p>
            </SellToWebModalDialogComponent>
            {
                this.props.showInspection
                    ? <div className="consumer-right-panel-header-inspection">
                        <img className="consumer-right-panel-car-img consumer-right-panel-car-inspection-img" src={this.props.imageUrl} alt="EVOX" onError={(event) => this.onMissingImage(event)} />
                        <div className="consumer-right-panel-car-evox-info-icon">
                            <img className="consumer-right-panel-car-evox-icon" src="images/evox-info.svg" alt="Info" onClick={this.onClick} />
                        </div>
                    </div>
                    : vehicleDetail
            }
            <div className="consumer-right-panel-car">
                {
                    !this.props.showInspection && this.props.makePlusModel
                        ? this.props.imageUrl
                            ? <Fragment>
                                <img className="consumer-right-panel-car-img" height="100%" width="100%" src={this.props.imageUrl} alt="EVOX" onError={(event) => this.onMissingImage(event)} />
                                <div className="consumer-right-panel-car-evox-info-icon">
                                    <img src="images/evox-info.svg" alt="Info" onClick={this.onClick} />
                                </div>
                            </Fragment>
                            : this.props.colorName || this.props.showNoImageAvailable
                                ? <div> No Image Available.</div>
                                : null
                        : null
                }
                {
                    this.state.showInfoBox
                        ? <div className={"d-flex align-items-center consumer-right-panel-car-evox-info-box " + (this.props.showInspection ? "consumer-right-panel-car-evox-info-box-confirmation" : "")}>
                            <img src="images/photo-camera.svg" alt="camera" style={{ marginRight: "16px" }} />
                            <div>
                                <b style={{ fontSize: "12px" }}>Images provided by EVOX</b>
                                <p style={{ fontSize: "12px", marginBottom: "0px" }}>Image may not be a perfect match for your vehicle’s model year, trim, body style, or color.</p>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className={"consumer-right-panel-info" + (this.props.showInspection ? " consumer-right-panel-inspection" : "")}>
                {
                    this.props.showInspection
                        ? vehicleDetail
                        : null
                }
                <hr className="consumer-right-panel-info-underline" />
                <div className="fw-normal" style={{ fontSize: "13px" }}>
                    <div className="row">
                        <div className="col-4">
                            <p>VIN</p>
                        </div>
                        <div className="col-8">
                            <p className="text-end" style={{ fontWeight: "500" }}>{this.props.vin}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p>Engine</p>
                        </div>
                        <div className="col-8">
                            <p className="text-end" style={{ fontWeight: "500" }}>{this.props.engine}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p>Transmission</p>
                        </div>
                        <div className="col-8">
                            <p className="text-end" style={{ fontWeight: "500" }}>{this.props.transmission}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p>Drivetrain</p>
                        </div>
                        <div className="col-8">
                            <p className="text-end" style={{ fontWeight: "500" }}>{this.props.driveTrain}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p>Paint Color</p>
                        </div>
                        <div className="col-8">
                            <div className="text-end" style={{ fontWeight: "500" }}>
                                <div style={{ color: this.props.colorBox, backgroundColor: this.props.colorBox, width: "12px", paddingTop: "1px", height: "13px", display: "inline-block", lineHeight: "14px", marginRight: "10px", fontSize: "12px" }}>&nbsp;</div>
                                {
                                    this.props.colorName
                                        ? this.props.colorName
                                        : "-"
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p>Mileage</p>
                        </div>
                        <div className="col-8">
                            <p className="text-end" style={{ fontWeight: "500" }}>
                                {
                                    this.props.odometer
                                        ? CommonService.formatMoney(this.props.odometer) + " Miles"
                                        : "-"
                                }
                            </p>
                        </div>
                    </div>
                    {
                        this.props.minimumCondition
                            ? <div className="row">
                                <div className="col-4">
                                    <p>Condition</p>
                                </div>
                                <div className="col-8">
                                    <p className="text-end" style={{ fontWeight: "500" }}>{this.props.minimumCondition}</p>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                this.props.showInspection
                    ?
                    <div className="d-flex flex-nowrap consumer-right-panel-info-flex">
                        <div className="consumer-right-panel-info-flex-item">
                            <h3 className="fw-bold" style={{ fontSize: "20px" }}>{CommonService.getSiteAttributeValue(this.props.siteAttributes, "Offer Confirmation", "Default", "Offer Label").Value}</h3>
                            <p style={{ fontSize: "13px" }}>Expires On <span>{this.props.expirationDate}</span></p>

                        </div>
                        <div className="consumer-right-panel-info-flex-item">
                            <p className="text-end fw-bold ac-site-offer-value" style={{ fontSize: "18px", color: "#0271e6" }}>${CommonService.formatMoney(this.props.offerPrice)}</p>
                            <p className="text-end text-decoration-underline consumer-right-panel-terms-conditions" role="button" onClick={this.onTermsClick} style={{ fontSize: "13px" }}>*Terms & Conditions</p>
                        </div>
                    </div>
                    : null
            }
        </div>;
    }
}