import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";
import $ from "jquery";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";
import { SellToWebModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogComponent"

export class HomeRouteComponent extends Component {
    constructor(props) {
        super(props);

        let randomNumber = Math.floor(Math.random() * this.imageURL.length);
        this.state = {
            vinOrLicensePlate: "",
            licenseState: "CA",
            redirectTo: "",
            showSpinner: false,
            showDialog: false,
            currentImageIndex: randomNumber,
            usePlateToVin: false
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onDropdownSelect = this.onDropdownSelect.bind(this);
        this.onDismissModalClick = this.onDismissModalClick.bind(this);
        this.onGetVinClick = this.onGetVinClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    pageTitle = "Home"
    imageURL = [
        "https://cdn.acquirecars.com/image/EVOX/373549.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/413252.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/412726.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/421445.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/424681.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/429270.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/430375.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/430790.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/430792.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/437031.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/439164.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/439149.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/439630.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/448248.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/448259.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/455402.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/450635.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/450953.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/451255.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/458595.front.default.png",
        "https://cdn.acquirecars.com/image/EVOX/453868.front.default.png"
    ]

    componentWillUnmount() {
        document.body.className = "app";
    }

    componentDidMount() {

        // this.props.applicationInsights.trackPageViewPerformance({ name: "Home - trackPageViewPerformance", url: "/" });
        // this.props.applicationInsights.trackTrace({ message: "Home - trackTrace" });
        // this.props.applicationInsights.trackMetric({ name: "Home - trackMetric", count: 1 });
        // this.props.applicationInsights.trackEvent({ name: "Home - trackEvent" });
        // this.props.applicationInsights.trackException({ exception: new Error("Home - trackException") });
        // this.props.applicationInsights.flush();

        const referrerUrl = document.referrer;;
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/", pageType: "Consumer", isLoggedIn: "No", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
            CommonService.clientAzureStorageLog("HomeRouteComponent", "componentDidMount", referrerUrl ?? "", null, null, null, this.props.applicationInsights);
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            document.body.className = "app-home";
            document.addEventListener("click", (event) => {
                var clickover = $(event.target);
                var opened = $(".navbar-collapse").hasClass("show");
                if (opened === true && !clickover.hasClass("navbar-toggler")) {
                    $("button.navbar-toggler").click();
                }
            });
            let vinOrLicensePlate = CommonService.getLocalStorage("HomeVINorLP");
            let licenseState = CommonService.getLocalStorage("HomeLicenseState");
            this.setState({ vinOrLicensePlate: vinOrLicensePlate ?? "", licenseState: licenseState ?? this.state.licenseState });
            this.getSiteDetails();
        }
        catch (error) {
            console.logError(error, "HomeRouteComponent", "componentDidMount", referrerUrl ?? "");
        }
    }

    getSiteDetails() {
        try {
            this.props.applicationInsights.trackTrace({ message: "HomeRouteComponent/getSiteDetails", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/Home/GetSiteDetails", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        usePlateToVin: parsedResponse[0].usePlateToVin
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Home/GetSiteDetails", ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "HomeRouteComponent", "getSiteDetails", this.state.vinOrLicensePlate);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "HomeRouteComponent", "getSiteDetails", this.state.vinOrLicensePlate);
                            });
                    }
                });
        } catch (error) {
            console.logError(error, "HomeRouteComponent", "getSiteDetails");
        }
    }

    onTextChange(event) {
        try {
            this.setState({ [event.target.id]: event.target.value });
        }
        catch (error) {
            console.logError(error, "HomeRouteComponent", "onTextChange", event.target.id);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onGetVinClick();
        }
    }

    onDropdownSelect(event) {
        try {
            this.setState({ [event.target.id]: event.target.value });
        }
        catch (error) {
            console.logError(error, "HomeRouteComponent", "onDropdownSelect", event.target.id);
        }
    }

    onDismissModalClick() {
        try {
            this.setState({ showDialog: false });
        }
        catch (error) {
            console.logError(error, "HomeRouteComponent", "onDismissModalClick");
        }
    }

    onGetVinClick() {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                vin: this.state.vinOrLicensePlate?.length === 17 ? this.state.vinOrLicensePlate : "",
                licensePlate: this.state.usePlateToVin && this.state.vinOrLicensePlate?.length !== 17 ? this.state.vinOrLicensePlate : "",
                licenseState: this.state.usePlateToVin && this.state.vinOrLicensePlate?.length !== 17 ? this.state.licenseState : "",
            }
            this.props.applicationInsights.trackTrace({ message: "HomeRouteComponent/onGetVinClick", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/Home/GetVIN", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errorMessage) {
                        this.setState({
                            showDialog: true,
                            showSpinner: false
                        });
                    }
                    else if (parsedResponse.warnings) {
                        this.setState({
                            showDialog: true,
                            showSpinner: false
                        });
                    }
                    else if (parsedResponse.items[0].modelName === undefined) {
                        CommonService.clientAzureStorageLog("HomeRouteComponent", "onGetVinClick", "fetch GetVIN " + inputData.vin, "Redirect to unique-vehicle", parsedResponse.errorMessage, null, this.props.applicationInsights);
                            this.setState({
                            redirectTo: "/unique-vehicle",
                            showSpinner: false
                        });
                    }
                    else {
                        let offerSession = {};
                        offerSession.vehicleDetail = {};
                        offerSession.vehicleDetail.vin = parsedResponse.vin;
                        offerSession.vehicleDetail.licensePlate = parsedResponse.licensePlate;
                        offerSession.vehicleDetail.licensePlateState = parsedResponse.licenseState;
                        CommonService.setSessionStorage("offerSession", offerSession);
                        CommonService.setSessionStorage("homeData", parsedResponse);
                        CommonService.setLocalStorage("HomeVINorLP", this.state.vinOrLicensePlate);
                        CommonService.setLocalStorage("HomeLicenseState", this.state.licenseState);
                        this.setState({
                            redirectTo: "/vehicle-details",
                            showSpinner: false
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({ showSpinner: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Home/GetVIN", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "HomeRouteComponent", "onGetVinClick", this.state.vinOrLicensePlate);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "HomeRouteComponent", "onGetVinClick", this.state.vinOrLicensePlate);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "HomeRouteComponent", "onGetVinClick");
        }
    }

    render() {
        let placeholder = "Enter Plate or VIN";
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }
        if (!this.state.usePlateToVin) {
            placeholder = "Enter VIN"
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Home", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Home", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Home", "Default", "Body Html")?.Value ?? "";
        return <div className="ac-site ac-home">
            <SellToWebModalDialogComponent
                showDialog={this.state.showDialog} showFooter
                title="Oops, please try again!"
                onOkClick={this.onDismissModalClick}
                className="consumer-home-try-again-dialog">
                {!this.state.usePlateToVin
                    ? <Fragment>
                        <p>Please check that you entered all 17 digits of your VIN correctly.</p>
                    </Fragment>
                    : <Fragment>
                        <div style={{ fontWeight: "500" }}>If you entered a Plate:</div>
                        <p>We couldn’t find the VIN associated with that plate. Please try entering your VIN instead.</p>
                        <div style={{ fontWeight: "500" }}>If you entered a VIN:</div>
                        <p>Please check that you entered all 17 digits of your VIN correctly.</p>
                    </Fragment>
                }

            </SellToWebModalDialogComponent>
            <header className="ac-site-header ac-home-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <section className="ac-home-form">
                <div style={{ "maxWidth": "420px", "margin": "0px auto", "padding": "25px 0px" }}>
                    <div className="d-flex consumer-home-form-input">
                        <input type="text" className="ac-home-form-vin" id="vinOrLicensePlate" value={this.state.vinOrLicensePlate} onChange={this.onTextChange} onKeyDown={this.handleKeyDown} placeholder={placeholder} maxLength="17" aria-label={placeholder} />
                        <select hidden={!this.state.usePlateToVin} className="ac-home-form-state ac-site-label" id="licenseState" defaultValue={"CA"} onChange={this.onDropdownSelect}>
                            <option value="AL">AL</option>
                            <option value="AK">AK</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MO">MO</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="NC">NC</option>
                            <option value="NE">NE</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="ND">ND</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VT">VT</option>
                            <option value="VA">VA</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>
                        </select>
                    </div>
                    <SellToWebButtonComponent className="consumer-home-form-button" onClick={this.onGetVinClick} showSpinner={this.state.showSpinner} value="Let's Go!" />
                </div>
                <div className="consumer-home-form-img">
                    <img src={this.imageURL[this.state.currentImageIndex]} alt="..." />
                </div>
            </section>
            <section className="ac-site-body ac-home-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
            <footer className="ac-site-footer ac-home-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}