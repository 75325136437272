import React, { Component } from "react";

export class SellToWebSpinnerComponent extends Component {
    render() {
        return ((this.props.showSpinner)
            ? <div className={"admin-spinner-grid " + (this.props.cssName ? this.props.cssName : "")}>
                <span className="admin-spinner admin-spinner1" />
                <span className="admin-spinner admin-spinner2" />
                <span className="admin-spinner admin-spinner3" />
                <span className="admin-spinner admin-spinner4" />
                <span className="admin-spinner admin-spinner5" />
                <span className="admin-spinner admin-spinner6" />
                <span className="admin-spinner admin-spinner7" />
                <span className="admin-spinner admin-spinner8" />
                <span className="admin-spinner admin-spinner9" />
            </div>
            : null
        );
    }
}