import React, { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebModalConfirmationComponent } from "../common/selltowebmodalconfirmation/SellToWebModalConfirmationComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableColumnCheckboxV2Component } from "../common/selltowebtable/SellToWebTableColumnCheckboxV2Component";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";

import { TenantSiteOfferCalculationEditComponent } from "./TenantSiteOfferCalculationEditComponent";
import { TenantSiteOfferCalculationQueryComponent } from "./TenantSiteOfferCalculationQueryComponent";

export class TenantSiteOfferCalculationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            tenants: null,
            tableData: [],
            tableTitle: "",
            paintTable: true,
            selectedRow: null,
            deleteRowData: null,
            addingNewRecord: false,
            showEditDialog: false,
            selectedSite: null,
            selectedTenant: null
        };

        this.onGetClick = this.onGetClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionDeleteClick = this.onActionDeleteClick.bind(this);
        this.onCancelEditClick = this.onCancelEditClick.bind(this);
        this.onAddNewCalculationSetupClick = this.onAddNewCalculationSetupClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onConfirmDeleteClick = this.onConfirmDeleteClick.bind(this);
        this.onCancelDeleteClick = this.onCancelDeleteClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Offer Calculation";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/OfferCalculation", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantSiteOfferCalculationComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantSite/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteOfferCalculationComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "getTenants");
        }
    }

    onGetClick(selectedTenant, selectedSite) {
        if (selectedTenant && selectedSite) {
            this.setState({
                isLoadingData: true,
                selectedTenant: selectedTenant,
                selectedSite: selectedSite
            });
        }

        let inputData = {
            siteGuid: selectedSite ? selectedSite.guid : this.state.selectedSite.guid
        };
        this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
        fetch("/TenantSite/GetCalculationVariables", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(inputData),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                let defaultGeneralAdjustment;
                if (!(parsedResponse.table[0].make && parsedResponse.table[0].model && parsedResponse.table[0].year)) {
                    defaultGeneralAdjustment = parsedResponse.table[0].generalAdjustmentPct;
                }
                parsedResponse.table.forEach((rowData, index) => {
                    rowData.rowClassName = !rowData.enableOfferCalculation ? "app-sell-trade-offer-calculation-disabled" : "";
                    rowData.generalAdjustmentPctcellClassName = rowData.generalAdjustmentPct !== defaultGeneralAdjustment ? "app-sell-trade-offer-calculation-general-adjustment-yellow" : "";
                    rowData.noOfferMajorIssueLabel = "Disabled";
                    rowData.noOfferMajorIssueLabel2 = "Enabled";
                    rowData.EnableOfferLabel = "No";
                    rowData.EnableOfferLabel2 = "Yes";
                });

                this.setState({
                    tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                    tableTitle: this.state.selectedTenant.name + " - " + this.state.selectedSite.siteName,
                    paintTable: true,
                    isLoadingData: false
                }, this.setState({ paintTable: false }, this.onDisableSave));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetCalculationVariables", ...inputData, ...parsedError, logon: this.props.logon } });
                            console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onGetClick");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onGetClick");
                        });
                }
            });
    }

    onSaveClick() {
        this.setState({
            showEditDialog: false,
            selectedRow: null,
            addingNewRecord: false
        }, this.onGetClick(null, null));
    }

    onCancelEditClick() {
        this.setState({
            showEditDialog: false,
            selectedRow: null,
            addingNewRecord: false
        });
    }

    onActionColumnCallback(rowData) {
        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                <i className="fas fa-edit" />
            </button>
            <button type="button" className="btn btn-link" onClick={(event) => this.onActionDeleteClick(event, rowData)} hidden={rowData.year == null && rowData.make == null && rowData.model == null}>
                <i className="fas fa-times text-danger" />
            </button>
        </Fragment>;
    }

    onActionEditClick(event, rowData) {
        try {
            this.setState({
                selectedRow: rowData,
                showEditDialog: true,
                addingNewRecord: false
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onActionEditClick");
        }
    }

    onActionDeleteClick(event, rowData) {
        try {
            this.setState({
                deleteRowData: rowData
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onActionDeleteClick");
        }
    }

    onConfirmDeleteClick() {
        try {
            if (this.state.deleteRowData) {
                let rowData = this.state.deleteRowData;
                let inputData = {
                    siteGuid: rowData.siteGuid,
                    year: this.state.deleteRowData.year ? CommonService.formatYear(this.state.deleteRowData.year.toString()) : null,
                    make: this.state.deleteRowData.make,
                    model: this.state.deleteRowData.model,
                    isDelete: true,
                    siteName: this.state.selectedSite.siteName,
                    tenantShortName: this.state.selectedTenant.shortName,
                };

                this.setState({
                    isLoadingData: true,
                    deleteRowData: null
                });

                this.props.applicationInsights.trackTrace({ message: "TenantSiteOfferCalculationComponent/onConfirmDeleteClick", properties: { ...inputData, logon: this.props.logon } });
                fetch("/TenantSite/SaveSiteCalculationVariable", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({
                            isLoadingData: false
                        }, () => {
                            this.props.onShowAlert("success", { message: "Record deleted successfully!" }, 4000);
                            this.onGetClick(null, null);
                        });
                    })
                    .catch(notOKResponse => {
                        this.setState({
                            isLoadingData: false
                        });
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/SaveSiteCalculationVariable", ...inputData, ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "TenantSiteOfferCalculationComponent", "onConfirmDeleteClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "TenantSiteOfferCalculationComponent", "onConfirmDeleteClick");
                                });
                        }
                    });
            }

        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onConfirmDeleteClick");
        }
    }

    onCancelDeleteClick() {
        try {
            this.setState({
                deleteRowData: null
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onCancelDeleteClick");
        }
    }

    onAddNewCalculationSetupClick() {
        try {
            this.setState({
                selectedRow: null,
                showEditDialog: true,
                addingNewRecord: true
            });
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onAddNewCalculationSetupClick");
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("TenantSiteOfferCalculationComponent", "onCopyTableCallBack", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "TenantSiteOfferCalculationComponent", "onCopyTableCallBack");
        }
    }

    render() {
        return <div className="d-flex flex-column admin-fill-content admin-body">
            <SellToWebModalConfirmationComponent
                headerText={"Delete Calculation Setup: " + (this.state.deleteRowData
                    ? (this.state.deleteRowData.year ? this.state.deleteRowData.year : "") + " " +
                    (this.state.deleteRowData.make ? this.state.deleteRowData.make : "") + " " +
                    (this.state.deleteRowData.model ? this.state.deleteRowData.model : "")
                    : "")}
                bodyText="Are you sure?"
                showConfirmation={this.state.deleteRowData !== null}
                onOkClick={this.onConfirmDeleteClick}
                onCancelClick={this.onCancelDeleteClick}
                className="admin-modal-confirmation-dialog"
            />
            <TenantSiteOfferCalculationEditComponent
                showDialog={this.state.showEditDialog}
                isAddingRecord={this.state.addingNewRecord}
                rowData={this.state.selectedRow}
                onSaveClick={this.onSaveClick}
                onCancelClick={this.onCancelEditClick}
                onShowAlert={this.props.onShowAlert}
                siteGuid={this.state.siteGuid}
                selectedTenant={this.state.selectedTenant}
                selectedSite={this.state.selectedSite}
                applicationInsights={this.props.applicationInsights}
                logon={this.state.logon}
            />
            <TenantSiteOfferCalculationQueryComponent
                tenants={this.state.tenants}
                onAddClick={this.onAddNewCalculationSetupClick}
                onGetClick={this.onGetClick}
                onTenantClick={this.onTenantClick}
                isLoadingData={this.state.isLoadingData}
                isGlobalAdmin={this.props.isGlobalAdmin}
                applicationInsights={this.props.applicationInsights}
                logon={this.state.logon}
            />
            <br />
            <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], onCopyTableCallBack: this.onCopyTableCallBack }} tablePageSize={CommonService.tablePageSize}
            >
                <SellToWebTableRowComponent>
                    <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                    <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} dataColumn="showSpinner" showSpinner />
                    <SellToWebTableColumnComponent headerText="Make" dataColumn="make" sortColumn="make" filterColumn="make" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Year" dataColumn="year" sortColumn="year" filterColumn="year" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Model" dataColumn="model" sortColumn="model" filterColumn="model" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Low Value Condition" dataColumn="lowValueCondition" sortColumn="lowValueCondition" filterColumn="lowValueCondition" className="text-center" />
                    <SellToWebTableColumnComponent headerText="High Value Condition" dataColumn="highValueCondition" sortColumn="highValueCondition" filterColumn="highValueCondition" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Excellent Condition Factor (0-1)" dataColumn="excellentCondition" sortColumn="excellentCondition" filterColumn="excellentCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Very Good Condition Factor (0-1)" dataColumn="veryGoodCondition" sortColumn="veryGoodCondition" filterColumn="veryGoodCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Good Condition Factor (0-1)" dataColumn="goodCondition" sortColumn="goodCondition" filterColumn="goodCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Fair Condition Factor (0-1)" dataColumn="fairCondition" sortColumn="fairCondition" filterColumn="fairCondition" dataType="number_2" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Accident Deduction" dataColumn="accidentDeductionPct" sortColumn="accidentDeductionPct" filterColumn="accidentDeductionPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Missing Key Deduction" dataColumn="missingKeyDeduction" sortColumn="missingKeyDeduction" filterColumn="missingKeyDeduction" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="General Adjustment" dataColumn="generalAdjustmentPct" sortColumn="generalAdjustmentPct" filterColumn="generalAdjustmentPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnComponent headerText="Max Mileage Adj Deduction" dataColumn="maxMileageAdjustmentPct" sortColumn="maxMileageAdjustmentPct" filterColumn="maxMileageAdjustmentPct" dataType="percent_1" className="text-center" />
                    <SellToWebTableColumnCheckboxV2Component headerText="No Offer on Major Issues" dataColumn="noOfferOnMajorIssues" sortColumn="noOfferOnMajorIssues" labelColumn="noOfferMajorIssueLabel" label2Column="noOfferMajorIssueLabel2" dataType="bool" headerClassName="admin-no-offer-major-issue-column" />
                    <SellToWebTableColumnCheckboxV2Component headerText="Enable Offer Calculation" dataColumn="enableOfferCalculation" sortColumn="enableOfferCalculation" labelColumn="EnableOfferLabel" label2Column="EnableOfferLabel2" dataType="bool" headerClassName="admin-enable-offer-column" />
                    <SellToWebTableColumnComponent headerText="Updated Date" dataColumn="updatedDate" sortColumn="updatedDate" filterColumn="updatedDate" dataType="dateTime" className="text-center" />
                </SellToWebTableRowComponent>
            </SellToWebTableComponent>
        </div>;
    }
}