import React, { Component } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent"
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminCalendarRangeComponent } from "../common/selltowebcalendar/SellToWebAdminCalendarRangeComponent";

export class GlobalLogQueryComponent extends Component {

    dateRange = [
        { key: "Today", value: "Today", startDate: moment(), endDate: moment() },
        { key: "Yesterday", value: "Yesterday", startDate: moment().subtract(1, "days"), endDate: moment().subtract(1, "days") },
        { key: "2 Days Back", value: "2 Days Back", startDate: moment().subtract(2, "days"), endDate: moment().subtract(2, "days") },
        { key: "This Week", value: "This Week", startDate: moment().startOf("isoWeek"), endDate: moment() },
        { key: "Last Week", value: "Last Week", startDate: moment().subtract(1, "week").startOf("isoWeek"), endDate: moment().subtract(1, "week").endOf("isoWeek") },
        { key: "MTD", value: "MTD", startDate: moment().startOf("month"), endDate: moment() },
        { key: "Custom", value: "Custom", startDate: null, endDate: null }
    ];

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        let startDate = moment();
        let endDate = moment();

        this.state = {
            logDate: moment(),
            sessionId: "",
            showSpinner: false,
            startDate: startDate,
            endDate: endDate,
            selectedDateRange: this.dateRange[0],
            filter: ""
        }

        this.onDateChange = this.onDateChange.bind(this);
        this.onLookupClick = this.onLookupClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("GlobalLogQueryComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.onGetLogsClick();
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "componentDidMount");
        }
    }

    onDateChange(selectedDateRange, startDate, endDate) {
        try {
            this.setState({
                selectedDateRange: selectedDateRange,
                startDate: startDate,
                endDate: endDate
            });
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "onDateChange");
        }
    }

    onLookupClick(id, value) {
        try {
            this.setState({ [id]: value }, this.onGetLogsClick);
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "onLookupClick", id);
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "onTextChange", id);
        }
    }

    onGetLogsClick() {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetLogsClick(this.state.startDate, this.state.endDate, this.state.sessionId, this.state.filter);
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "onGetLogsClick");
        }
    }

    onGetErrorsClick(){
        try {
            this.setState({ showSpinner: true });
            this.props.onGetErrorsClick(this.state.startDate, this.state.endDate);
        }
        catch (error) {
            console.logError(error, "GlobalLogQueryComponent", "onGetErrorsClick");
        }
    }
    
    render() {
        let minDate = moment().subtract(2, "years").startOf("year").format("MM/DD/YYYY"),
        maxDate = moment().format("MM/DD/YYYY");

        return <div className="card">
            <div className="card-block">
                <div className="row">
                    <SellToWebAdminCalendarRangeComponent
                        label="Log Date Range" dateRange={this.dateRange} selectedDateRange={this.state.selectedDateRange} onDateChange={this.onDateChange}
                        startId="startDate" startValue={this.state.startDate} startMin={minDate} startMax={maxDate}
                        endId="endDate" endValue={this.state.endDate} endMin={minDate} endMax={maxDate} />

                    <SellToWebAdminInputComponent id="sessionId" label="Session Id" value={this.state.sessionId} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                    <SellToWebAdminInputComponent id="filter" label="Filter" value={this.state.filter} onChange={this.onTextChange} onLookupClick={this.onLookupClick} />
                    <div className="col-auto admin-query-btn">
                        <button className="btn btn-primary" disabled={this.state.showSpinner} onClick={(event) => this.onGetLogsClick(event)}>
                            Get Logs <i className="far fa-redo-alt admin-icon" />
                        </button>
                        <button className="btn btn-primary admin-icon" disabled={this.state.showSpinner} onClick={(event) => this.onGetErrorsClick(event)}>
                            Get Errors <i className="far fa-redo-alt admin-icon" />
                        </button>
                    </div>
                    <div className="col-auto admin-query-cube-grid">
                        <SellToWebSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}