import { Component } from "react";

export class SellToWebInputFileComponent extends Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }

        return <div className={"admin-control " + colSpan}>
            <input type="file" className="d-none" id={this.props.id} onChange={(event) => this.onFileChange(event)} accept={this.props.accept} disabled={this.props.disabled} />
            <label className="btn btn-primary" htmlFor={this.props.id} disabled={this.props.disabled}>Upload Artifact</label>
        </div>;
    }

    onFileChange(event) {
        if (this.props.onFileChange) {
            this.props.onFileChange(event);
        }
    }
}
