import React, { Component, Fragment } from "react";
import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

export class PageNotFoundRouteComponent extends Component {
  render() {
    return <Fragment>
      <SellToWebAdminHeaderComponent showLogo={true} />
      <div className="text-center">
        <p>Page Not Found</p>
      </div>
      <SellToWebAdminFooterComponent />
    </Fragment>;
  }
}
