import React, { Component, Fragment } from "react";

import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

export class SignInRouteComponent extends Component {
    render() {
        return <Fragment>
            <SellToWebAdminHeaderComponent showLogo={true}/>
            <div className="app-signin">
                <p>Your profile is currently not associated with a dealer account.  Please contact support at support@acquirecars.com.</p>
            </div>
            <SellToWebAdminFooterComponent />
        </Fragment>;
    }
}