import React, { Component } from "react";

export class SellToWebButtonComponent extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    render() {
        return <button type="button" onClick={(event) => this.onClick(event)}
            style={{ "position": "relative", "borderRadius": "25px", "border": "none", "padding": "10px 50px" }}
            className={"ac-site-button " + this.props.className ?? ""}
        >
            {this.props.value}
            <span className="far fa-angle-right" style={{ "position": "absolute", "top": "15px", "right": "20px" }} />
            {
                this.props.showSpinner
                    ? <div className="ac-site-spinner" style={{ "position": "absolute", "top": "7px", "right": "8px" }} />
                    : null
            }
        </button>;
    }
}