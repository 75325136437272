import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

export class HealthCheckRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            healthCheckResponse: ""
        };
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("HealthCheckRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.healthCheck();
        }
        catch (error) {
            console.logError(error, "HealthCheckRouteComponent", "componentDidMount");
        }
    }

    healthCheck() {
        try {
            this.props.applicationInsights.trackTrace({ message: "HealthCheckRouteComponent/healthCheck" });
            fetch("/HealthCheck/HealthCheckApi", {
                method: "GET",
                headers: {
                    "ContentType": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        healthCheckResponse: JSON.stringify(parsedResponse)
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/HealthCheck/HealthCheckApi", ...parsedError } });
                                console.logError(parsedError, "HealthCheckRouteComponent", "healthCheck");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "HealthCheckRouteComponent", "healthCheck");
                            });
                    }
                });

        }
        catch (error) {
            console.logError(error, "HealthCheckRouteComponent", "healthCheck");
        }
    }

    render() {
        return <div className="admin-fill-content">
            <SellToWebAdminHeaderComponent pageTitle="Health Check" showLogo />
            <SellToWebAdminPageComponent className="admin-body">
                <div className="card">
                    <div className="card-block">
                        {this.state.healthCheckResponse}
                    </div>
                </div>
            </SellToWebAdminPageComponent>
            <SellToWebAdminFooterComponent />
        </div>;
    }
}