import React, { Component } from "react";

import { AppRootComponent } from "./components/app/AppRootComponent";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return <AppRootComponent {...this.props} />;
    }
}
