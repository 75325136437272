import { Component } from "react";

import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";

export class GlobalTenantCreateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTenant: null,
            shortName: "",
            name: ""
        }

        this.onTextChange = this.onTextChange.bind(this);
        this.onTenantItemClick = this.onTenantItemClick.bind(this);
        this.onOkClick = this.onOkClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    onTextChange(id, value) {
        try {
            this.setState({
                [id]: value
            });
        }
        catch (error) {
            console.logError(error, "GlobalTenantCreateComponent", "onTextChange", id, value);
        }
    }

    onTenantItemClick(id, selectedTenant) {
        try {
            this.setState({
                selectedTenant: selectedTenant,
            });
        }
        catch (error) {
            console.logError(error, "GlobalTenantCreateComponent", "onTenantItemClick");
        }
    }

    onOkClick() {
        try {
            this.props.onOkClick(this.state.selectedTenant.guid, this.state.name, this.state.shortName);
        } catch (error) {
            console.logError(error, "GlobalTenantCreateComponent", "onOkClick");
        }
    }

    onCancelClick() {
        try {
            this.setState({
                selectedTenant: null,
                name: "",
                shortName: ""
            }, this.props.onCancelCreateTenantClick);
        } catch (error) {
            console.logError(error, "GlobalTenantCreateComponent", "onCancelClick");
        }
    }

    render() {
        return <div className="card">
            <SellToWebAdminModalDialogComponent
                title="Create Tenant"
                className="admin-global-tenant-create-dialog"
                showDialog={this.props.showEditDialog}
                onCancelClick={this.onCancelClick}
            >
                <SellToWebAdminDropdownComponent colSpan="col-12" labelSpan="col-3" controlSpan="col-9" id="fromTenant" additionalData="" label="Copy From"
                    disabled="" data={this.props.tenants} itemKey="name" value={this.state.selectedTenant ? this.state.selectedTenant.name : "Select Tenant"}
                    inputClassName="" onItemClick={this.onTenantItemClick} />
                <SellToWebAdminInputComponent colSpan="col-12" labelSpan="col-3" controlSpan="col-9" id="name" label="Name" value={this.state.name} placeholder="Name" onChange={this.onTextChange} />
                <SellToWebAdminInputComponent colSpan="col-12" labelSpan="col-3" controlSpan="col-9" id="shortName" maxLength="20" label="Short Name" value={this.state.shortName} placeholder="Short Name" helperText="(Lowercase, single word, 20 chars max)" onChange={this.onTextChange} />
                <br />
                <div className="text-center">
                    <button disabled={!(this.state.name && this.state.shortName)} className="btn btn-primary" onClick={(event) => this.onOkClick(event)}>
                        OK<i className="fa fa-check admin-icon"></i>
                    </button>
                    <button className="btn btn-primary admin-icon" onClick={(event) => this.onCancelClick(event)}>
                        Cancel<i className="fas fa-times admin-icon"></i>
                    </button>
                </div>
            </SellToWebAdminModalDialogComponent>
        </div>
    }
}