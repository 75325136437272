import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

export class GlobalUserQueryComponent extends Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (state.showSpinner) {
                newState.showSpinner = props.isLoadingData;
            }
        }
        catch (error) {
            console.devError(error);
        }

        return newState;
    }

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false
        }

        this.onGetUsersClick = this.onGetUsersClick.bind(this);
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("GlobalUserQueryComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.onGetUsersClick(null);
        }
        catch (error) {
            console.logError(error, "GlobalUserQueryComponent", "componentDidMount");
        }
    }

    onGetUsersClick(event) {
        try {
            this.setState({ showSpinner: true });
            this.props.onGetUsersClick();
        }
        catch (error) {
            console.logError(error, "GlobalUserQueryComponent", "onGetUsersClick");
        }
    }

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    <div className="col-auto admin-query-btn">
                        <button className="btn btn-primary" disabled={this.state.showSpinner} onClick={(event) => this.onGetUsersClick(event)}>
                            Get Users <i className="far fa-redo-alt admin-icon" />
                        </button>
                    </div>
                    <div className="col-auto admin-query-cube-grid">
                        <SellToWebSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}