import React, {Component} from "react";
import moment from "moment";
import $ from "jquery";

import { CommonService } from "../../js_modules/CommonService";

export class SellToWebAdminCalendarComponent extends Component {
    constructor(props) {
        super(props);

        this.onDateClick = this.onDateClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        $(this.dateRefElement).datepicker({
            changeMonth: true,
            changeYear: true,
            numberOfMonths: this.props.numberOfMonths,
            showButtonPanel: true,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate,
            yearRange: moment().year() - 100 + ":" + moment().year(),
            beforeShowDay: (date) => {
                if (this.props.showDays) {
                    return [this.props.showDays.includes(date.getDay())];
                }
                if (this.props.showDate) {
                    return [this.props.showDate.includes(date.getDate())];
                }
                if (this.props.disabledDates) {
                    let formatteDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
                    return [!this.props.disabledDates.includes(formatteDate)];
                }

                return [true];
            }
        }).on("change", (event) => this.onDateChange(event));
        if (this.props.value) {
            $(this.dateRefElement).datepicker("setDate", moment(this.props.value).toDate());
        }

        if (this.props.disabled) {
            $(this.dateRefElement).datepicker("disable");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.minDate !== prevProps.minDate
                || this.props.maxDate !== prevProps.maxDate
            ) {
                $(this.dateRefElement).datepicker("change",
                    {
                        minDate: this.props.minDate,
                        maxDate: this.props.maxDate
                    }
                );
            }

            if (this.props.value !== prevProps.value) {
                if (this.props.value && CommonService.isDateValid(this.props.value)) {
                    $(this.dateRefElement).datepicker("setDate", moment(CommonService.formatDateToISOString(this.props.value)).toDate());
                }
                else {
                    $(this.dateRefElement).datepicker("setDate", "");
                }
            }

            if (this.props.disabled !== prevProps.disabled) {
                if (this.props.disabled) {
                    $(this.dateRefElement).datepicker("disable");
                }
                else {
                    $(this.dateRefElement).datepicker("enable");
                }
            }
        }
        catch (error) {
            console.logError(error, "SellToWebAdminCalendarComponent", "componentDidUpdate");
        }
    }

    onDateClick(event) {
        $(this.dateRefElement).datepicker("show");
    }

    onDateChange(event) {
        let result = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
        if (result && this.props.onDateChange) {
            $(this.dateRefElement).datepicker("setDate", result.toDate());
        } else {
            $(this.dateRefElement).datepicker("setDate", "");
        }

        this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
    }

    onKeyDown(event) {
        let currentDate = CommonService.isDateValid($(this.dateRefElement).datepicker().val());
        if (!currentDate) {
            currentDate = moment();
        }

        switch (event.key) {
            case "t":
            case "T":
                currentDate = moment();

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "d":
            case "D":
            case "+":
                currentDate = currentDate.add(1, "day");

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "a":
            case "A":
            case "-":
                currentDate = currentDate.subtract(1, "day");

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "m":
            case "M":
                if (currentDate.date() === 1) {
                    currentDate = currentDate.subtract(1, "months");
                } else {
                    currentDate = currentDate.startOf("month");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "h":
            case "H":
                if (currentDate.date() === currentDate.endOf("month").date()) {
                    currentDate = currentDate.add(1, "months");
                } else {
                    currentDate = currentDate.endOf("month");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "y":
            case "Y":
                if (currentDate.date() === 1 && currentDate.month() === 0) {
                    currentDate = currentDate.subtract(1, "years");
                } else {
                    currentDate = currentDate.startOf("year");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "r":
            case "R":
                if (currentDate.date() === currentDate.endOf("month").date() && currentDate.month() === 11) {
                    currentDate = currentDate.add(1, "years");
                } else {
                    currentDate = currentDate.endOf("year");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "w":
            case "W":
                if (currentDate.day() === 0) {
                    currentDate = currentDate.subtract(1, "weeks");
                } else {
                    currentDate = currentDate.startOf("week");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            case "k":
            case "K":
                if (currentDate.day() === 6) {
                    currentDate = currentDate.add(1, "weeks");
                } else {
                    currentDate = currentDate.endOf("week");
                }

                $(this.dateRefElement).datepicker("setDate", currentDate.toDate());
                this.props.onDateChange(this.props.id, $(this.dateRefElement).datepicker().val(), this.props.additionalData);
                break;

            default:
                if (this.props.onKeyDown) {
                    this.props.onKeyDown(event);
                }
        }
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        let controlSpan = this.props.controlSpan ?? "col-auto ";
        controlSpan += this.props.hideTextbox ? "" : " admin-calendar ";
        let inputClassName = "form-control";
        if (this.props.className) {
            colSpan += " " + this.props.className;
        }
        if (this.props.controlClassName) {
            controlSpan += " " + this.props.controlClassName;
        }
        if (this.props.hideTextbox) {
            inputClassName += " d-none";
        }
        
        return <div className={"admin-control " + colSpan}>
            <div className="input-group">
                {
                    this.props.label
                        ? <label className={"admin-label " + labelSpan} htmlFor={this.props.id}>
                            {this.props.label}:
                            {
                                this.props.isRequired
                                    ? <span className="text-danger">*</span>
                                    : null
                            }
                        </label>
                        : null
                }
                <div className={"input-group " + controlSpan} hidden={this.props.isHidden}>
                    <input className={inputClassName} placeholder="mm/dd/yyyy" ref={refElement => this.dateRefElement = refElement} id={this.props.id} disabled={this.props.disabled} onKeyDown={(event) => this.onKeyDown(event)} />
                    <button className={"btn btn-secondary " + (this.props.buttonClassName ? this.props.buttonClassName : "")} type="button" disabled={this.props.disabled} onClick={(event) => this.onDateClick(event)}>
                        <i className="far fa-calendar-alt" />
                    </button>
                </div>
            </div>
        </div>;
    }
}