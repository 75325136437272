import React, { Component } from "react";

export class SellToWebAdminCheckboxComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, event.target.checked, this.props.additionalData);
        }
    }

    render() {
        let colSpan = this.props.colSpan ?? "col-auto";
        let labelSpan = this.props.labelSpan ?? "col-form-label";
        if (this.props.labelClassName) {
            labelSpan += " " + this.props.labelClassName;
        }

        return <div className={"admin-control " + colSpan}>
            {
                this.props.label
                    ? <label className={"admin-label " + labelSpan} htmlFor={this.props.id}>{this.props.label}:&nbsp;</label>
                    : null
            }
            <input type="checkbox" className={this.props.controlClassName ?? ""} id={this.props.id} disabled={this.props.disabled} checked={this.props.value ? this.props.value : false} onChange={(event) => this.onChange(event)} />
            {this.props.children}
        </div>;
    }
}