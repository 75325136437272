import React, { Component, Fragment } from "react";

import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

export class GlobalHomeRouteComponent extends Component {
    render() {
        let signInUrl = "/MicrosoftIdentity/Account/SignIn?redirectUri=" + encodeURIComponent("/Account/SignIn");
        let signUpUrl = "/Account/ChallengeSignUp";
        return <Fragment>
            <SellToWebAdminHeaderComponent showLogo={true}/>
            <hr style={{ background: "#EAEAEA",margin:"0px" }} />
            <div className="app-global-home">
                <h1 className="app-font-family-bold app-fs-50 app-fw-700 app-global-home-title">Coming Soon!</h1>
                <p className="app-fs-18 app-fw-500 app-global-home-sub-title">Completely <b>Automated Vehicle Offer System</b></p>
                <a type="button" className="btn btn-primary app-fs-18" href={signInUrl}>Sign In</a>
                <a type="button" className="btn btn-primary admin-icon app-fs-18" href={signUpUrl}>Sign Up</a>
                <br />
                <img className="app-global-home-promo" src="images/sell2acquire-promo.png" alt="sell to acquire promo" />
            </div>
            <SellToWebAdminFooterComponent />
        </Fragment>;
    }
}