import React, { Component } from "react";

export class SellToWebProgressbarComponent extends Component {
    render() {
        let progressElement = null;
        if (this.props.progressIndex) {
            progressElement = [];
            for (let index = 0; index < this.props.progressIndex; index++) {
                progressElement.push(<div key={"progress" + index} className="progress-bar ac-site-progress-bar"
                    style={{ "position": "relative", "display": "inline-block", "width": "16.66%", "marginRight": "3px" }} role="progressbar" />);
            }
        } else {
            progressElement = [];
            progressElement.push(<div div key="progressfull" className="progress-bar ac-site-progress-bar"
                style={{ "position": "relative", "display": "inline-block", "width": "100%" }} role="progressbar" />);
        }

        return <section className="progress consumer-progress">
            {progressElement}
        </section>;
    }
}