import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

export class GlobalTenantSiteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSites: false
        };

        this.onToggleSiteView = this.onToggleSiteView.bind(this);
    }

    onToggleSiteView(event) {
        let showSites = this.state.showSites ? false : true;
        this.setState({ showSites: showSites })
        if (this.props.onSiteToggle) {
            this.props.onSiteToggle(this.props.rowData);
        }
    }

    render() {
        let rows = [];
        if (this.props.rowData.sites) {
            this.props.rowData.sites.forEach((site, index) => {
                rows.push(<tr key={"row" + index}>
                    <td>{site.name}</td>
                    <td>Short Name: <Link target="_blank" to={"/tenant/site/" + site.tenantGuid + "/" + site.guid}>{site.siteName}</Link></td>
                    <td>Site Domain: {site.siteDomain}</td>
                    <td>Is Production: {CommonService.formatBoolString(site.isProduction)}</td>
                    <td>Status: {site.status}</td>
                    <td>Last Updated: {CommonService.formatDateTimeZone(site.updatedDate)}</td>
                </tr>);
            });
        }

        return <Fragment>
            <button type="button" className="btn btn-link" onClick={(event) => this.onToggleSiteView(event)}>
                Sites
                {
                    this.state.showSites
                        ? <span className="far fa-sort-up admin-icon" />
                        : <span className="far fa-sort-down admin-icon" />
                }
            </button>
            {
                this.state.showSites
                    ? <div className="admin-global-site">
                        <table className="admin-tenant-site-table">
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                    : null
            }
        </Fragment>;
    }
}