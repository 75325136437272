import { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableHeaderComponent } from "../common/selltowebtable/SellToWebTableHeaderComponent";
import { GlobalUserQueryComponent } from "./GlobalUserQueryComponent";
import { GlobalUserEditComponent } from "./GlobalUserEditComponent";

export class GlobalUserRouteComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: false,
            tableTitle: "",
            tableData: [],
            paintTable: false,
            selectedRow: null,
            showDialog: false
        };

        this.onGetUsersClick = this.onGetUsersClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onActionEditClick = this.onActionEditClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onCancelEditClick = this.onCancelEditClick.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Users";

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("GlobalUserRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
        } catch (error) {
            console.logError(error, "GlobalUserRouteComponent", "componentDidMount");
        }
    }

    getTenants() {
        this.setState({ isLoadingData: true });

        fetch("/GlobalUser/GetAllTenants", {
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response.json() } else { throw response; } })
            .then(parsedResponse => {
                this.setState({
                    tenants: parsedResponse.table,
                    isLoadingData: false
                }, this.setState({ paintTable: false }));
            })
            .catch(notOKResponse => {
                this.setState({
                    isLoadingData: false
                });
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            console.logError(parsedError, "GlobalUserRouteComponent", "getTenants");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "GlobalUserRouteComponent", "getTenants");
                        });
                }
            });
    }

    onActionColumnCallback(rowData) {
        if (rowData.canImpersonate && rowData.logon) {
            return <Fragment>
                <a href={"/Account/Impersonate?Id=" + rowData.guid}>
                    <span className="fas fa-user-plus" />
                    <span>Impersonate</span>
                </a>
                <button type="button" className="btn btn-link" onClick={(event) => this.onActionEditClick(event, rowData)}>
                    <i className="fas fa-edit admin-icon" />
                </button>
            </Fragment>;
        }

        return null;
    }

    onActionEditClick(event, rowData) {
        try {
            this.setState({
                selectedRow: rowData,
                showDialog: true
            });
        }
        catch (error) {
            console.logError(error, "GlobalUserRouteComponent", "onActionEditClick");
        }
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard." }, 4000);
            CommonService.clientAzureStorageLog("GlobalUserRouteComponent", "onCopyTableCallBack", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "GlobalUserRouteComponent", "onCopyTableCallBack");
        }
    }

    onGetUsersClick() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "GlobalUserRouteComponent/onGetUsersClick", properties: { logon: this.props.logon } });
            fetch("/GlobalUser/GetUsers", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;

                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        selectedRow: tableData.find(usr => usr.userPrincipalName === this.state.selectedRow?.userPrincipalName) ?? null,
                        tableTitle: "Users",
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableTitle: "",
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalUser/GetUsers", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalUserRouteComponent", "onGetUsersClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalUserRouteComponent", "onGetUsersClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalUserRouteComponent", "onGetUsersClick");
        }
    }

    onCancelEditClick() {
        this.setState({
            showDialog: false,
            selectedRow: null
        }, () => {
            this.onGetUsersClick();
        });
    }

    render() {
        return <SellToWebAdminPageComponent>
            <div className="admin-flex-item admin-body">
                <GlobalUserEditComponent
                    showDialog={this.state.showDialog}
                    selectedUser={this.state.selectedRow}
                    onCancelClick={this.onCancelEditClick}
                    onShowAlert={this.props.onShowAlert}
                    onGetUsersClick={this.onGetUsersClick}
                    tenants={this.state.tenants}
                    applicationInsights={this.props.applicationInsights} 
                    logon={this.state.logon}
                />
                <div className="d-flex flex-column admin-fill-content">
                    <GlobalUserQueryComponent isLoadingData={this.state.isLoadingData} onGetUsersClick={this.onGetUsersClick} applicationInsights={this.props.applicationInsights} />
                    <br />
                    <SellToWebTableComponent cardClassName="admin-flex-item" headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader
                        copyTableConfig={{ "columns": [0, 2, 3, 4, 5, 6, 7, 8, 9, 10], onCopyTableCallBack: this.onCopyTableCallBack }}>
                        <SellToWebTableHeaderComponent position="PreHeader">
                            <tr className="admin-table-header">
                                <td colSpan="2" />
                                <td className="admin-global-user-active-directory text-center">Active Directory</td>
                                <td colSpan="8" className="admin-global-user-acquire-cars text-center">Acquire Cars</td>
                            </tr>
                        </SellToWebTableHeaderComponent>
                        <SellToWebTableRowComponent>
                            <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                            <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                            <SellToWebTableColumnComponent headerText="User Principal Name" dataColumn="userPrincipalName" sortColumn="userPrincipalName" filterColumn="userPrincipalName" className="admin-global-user-active-directory" />
                            <SellToWebTableColumnComponent headerText="Logon" dataColumn="logon" sortColumn="logon" filterColumn="logon" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Last Logon Date" dataColumn="lastLogonDate" sortColumn="lastLogonDate" filterColumn="lastLogonDate" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="First" dataColumn="givenName" sortColumn="givenName" filterColumn="givenName" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Last" dataColumn="surname" sortColumn="surname" filterColumn="surname" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Status" dataColumn="status" sortColumn="status" filterColumn="status" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Tenant Name" dataColumn="tenantName" sortColumn="tenantName" filterColumn="tenantName" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Tenant Status" dataColumn="tenantStatus" sortColumn="tenantStatus" filterColumn="tenantStatus" className="admin-global-user-acquire-cars" />
                            <SellToWebTableColumnComponent headerText="Roles" dataColumn="roles" sortColumn="roles" filterColumn="roles" className="admin-global-user-acquire-cars" />
                        </SellToWebTableRowComponent>
                    </SellToWebTableComponent>
                </div>
            </div>
        </SellToWebAdminPageComponent>;
    }
}