import React, { Component } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class UniqueVehicleRouteComponent extends Component {
    constructor(props) {
        super(props)
        this.onActionClick = this.onActionClick.bind(this);
    }

    pageTitle = "Unique Vehicle";

    componentDidMount() {
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/unique-vehicle", pageType: "Consumer", isLoggedIn: "No", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
        }
        catch (error) {
            console.logError(error, "UniqueVehicleRouteComponent", "componentDidMount", this.state.vinOrLicensePlate);
        }
    }

    onActionClick() {
        try {
            CommonService.clientAzureStorageLog("UniqueVehicleRouteComponent", "onActionClick", null, null, null, null, this.props.applicationInsights);
            let Url = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Unique Vehicle", "Default", "Action Url").Value;
            window.open(Url, '_blank');
        }
        catch (error) {
            console.logError(error, "UniqueVehicleRouteComponent", "onActionClick");
        }
    }

    render() {
        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Unique Vehicle", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Unique Vehicle", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Unique Vehicle", "Default", "Body Html")?.Value ?? "";
        return <div className="ac-site ac-uniquevehicle">
            <header className="ac-site-header ac-uniquevehicle-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={null} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-uniquevehicle-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <div style={{ "textAlign": "center" }}>
                        <SellToWebButtonComponent onClick={this.onActionClick} value={CommonService.getSiteAttributeValue(this.props.siteAttributes, "Unique Vehicle", "Default", "Action Text")?.Value ?? ""} />
                    </div>
                </section>
            </section>
            <footer className="ac-site-footer ac-uniquevehicle-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}