import React, { Component, Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import moment from "moment";
import preval from "preval.macro"

import { CommonService } from "../js_modules/CommonService";

import { AdminRouteComponent } from "../admin/AdminRouteComponent"
import { GlobalHomeRouteComponent } from "../globalhome/GlobalHomeRouteComponent";
import { PageNotFoundRouteComponent } from "../pagenotfound/PageNotFoundRouteComponent";
import { SignInRouteComponent } from "../signin/SignInRouteComponent"
import { SignOutRouteComponent } from "../signout/SignOutRouteComponent"

import { HomeRouteComponent } from "../home/HomeRouteComponent";
import { OfferErrorRouteComponent } from "../offererror/OfferErrorRouteComponent";
import { UniqueVehicleRouteComponent } from "../uniquevehicle/UniqueVehicleRouteComponent";
import { VehicleDetailsRouteComponent } from "../vehicledetails/VehicleDetailsRouteComponent";
import { VehicleEquipmentRouteComponent } from "../vehicleequipment/VehicleEquipmentRouteComponent";
import { VehicleConditionRouteComponent } from "../vehiclecondition/VehicleConditionRouteComponent";
import { YourDetailsRouteComponent } from "../yourdetails/YourDetailsRouteComponent";
import { VehicleOfferRouteComponent } from "../vehicleoffer/VehicleOfferRouteComponent";
import { VehicleHistoryRouteComponent } from "../vehiclehistory/VehicleHistoryRouteComponent";
import { VehicleConfirmationRouteComponent } from "../vehicleconfirmation/VehicleConfirmationRouteComponent";

import { SellToWebAdminAlertComponent } from "../common/selltowebalert/SellToWebAdminAlertComponent";
import { HealthCheckRouteComponent } from "../healthcheck/HealthCheckRouteComponent";

import "../../consumer.css";

const buildDate = preval`
module.exports = (new Date()).toUTCString();
`;

export class AppRootComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instanceId: "",
            sessionId: "",
            siteAttributes: null,
            applicationInsights: null,
            alertType: "",
            message: "",
            txnId: "",
            siteFound: null,
            siteName: "",
            tenantName: ""
        }

        console.logError = function (err, component, part3, part4, part5) {
            let message = "";
            if (err.message) {
                message = err.message;
            }
            else if (err.stack) {
                message = err.stack.split("\n")[0];
            }

            CommonService.clientAzureStorageError(component, message, part3, part4, part5, JSON.stringify(err));
            console.error(err);
        };

        this.onPageLoad = this.onPageLoad.bind(this);
        this.onShowAlert = this.onShowAlert.bind(this);
        this.onHideAlert = this.onHideAlert.bind(this);
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("AppRootComponent", "componentDidMount");
            this.getSiteInfo();
        }
        catch (error) {
            console.logError(error, "AppRootComponent", "componentDidMount");
        }
    }

    isLocalStorageAvailable() {
        try {
            localStorage.setItem("test", "test");
            localStorage.removeItem("test");
            return true;
        } catch (error) {
            return false;
        }
    }

    getSiteInfo() {
        try {
            fetch("/App/GetSiteInfo", {
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let userId = CommonService.getCookie("UserId");
                    let userIdDate = moment.utc(CommonService.getCookie("UserIdDate"));
                    let userCreatedDiffInDays = userIdDate.diff(userIdDate, "days");
                    let isLocalStorageAvailable = this.isLocalStorageAvailable();
                    if (!isLocalStorageAvailable || !navigator.cookieEnabled) {
                        CommonService.logError("AppRootComponent", "browserCapabilities", "cookies:" + navigator.cookieEnabled, "localStorage:" + isLocalStorageAvailable);
                    }

                    CommonService.clientAzureStorageLog("AppRootComponent", "browserCapabilities", "cookies:" + navigator.cookieEnabled, "localStorage:" + isLocalStorageAvailable, "User:" + userId + "(" + userIdDate.format("YYYY-MM-DD") + ", " + userCreatedDiffInDays + " days)");

                    let metaTag = document.createElement("meta");
                    metaTag.name = "SessionId";
                    metaTag.content = parsedResponse.sessionId;
                    document.head.appendChild(metaTag);

                    metaTag = document.createElement("meta");
                    metaTag.name = "InstanceId";
                    metaTag.content = parsedResponse.instanceId;
                    document.head.appendChild(metaTag);

                    metaTag = document.createElement("meta");
                    metaTag.name = "BuildDate";
                    metaTag.content = CommonService.formatDateTimeZone(buildDate)
                    document.head.appendChild(metaTag);

                    metaTag = document.createElement("meta");
                    metaTag.name = "UserId";
                    metaTag.content = userId;
                    document.head.appendChild(metaTag);

                    let applicationInsights = new ApplicationInsights({
                        config: {
                            connectionString: parsedResponse.applicationInsightConnectionString,
                            enableAutoRouteTracking: false
                        }
                    });
                    applicationInsights.loadAppInsights();

                    // Dynamically add the style tag after component mounts
                    if (parsedResponse.siteAttributes) {
                        let internalStyle = parsedResponse.siteAttributes["Site~Default~Acquire Cars CSS"]?.Value ?? "";
                        let styleElement = document.createElement("style");
                        styleElement.setAttribute("id", "AcquireCarsCss")
                        styleElement.innerHTML = internalStyle;
                        document.head.appendChild(styleElement);

                        // Dynamically add the script tag after component mounts
                        let headTopScript = parsedResponse.siteAttributes["Site~Default~Head Top JS"]?.Value ?? "";
                        if (headTopScript) {
                            let headTopElements = this.convertAttributeToElement(headTopScript);
                            headTopElements.forEach(element => {
                                document.head.insertBefore(element, document.head.firstChild);
                            });
                        }

                        let bodyTopScript = parsedResponse.siteAttributes["Site~Default~Body Top JS"]?.Value ?? "";
                        if (bodyTopScript) {
                            let bodyTopElements = this.convertAttributeToElement(bodyTopScript);
                            bodyTopElements.forEach(element => {
                                document.body.insertBefore(element, document.body.firstChild);
                            });
                        }

                        let audioEyeScript = parsedResponse.siteAttributes["Site~Default~AudioEye JS"]?.Value ?? "";
                        if (audioEyeScript) {
                            let audioEyeElements = this.convertAttributeToElement(audioEyeScript);
                            audioEyeElements.forEach(element => {
                                document.body.lastChild.parentNode.insertBefore(element, null);
                            });
                        }


                        let bodyBottomScript = parsedResponse.siteAttributes["Site~Default~Body Bottom JS"]?.Value ?? "";
                        if (bodyBottomScript) {
                            let bodyBottomElements = this.convertAttributeToElement(bodyBottomScript);
                            bodyBottomElements.forEach(element => {
                                document.body.lastChild.parentNode.insertBefore(element, null);
                            });
                        }
                    }

                    this.setState({
                        applicationInsights: applicationInsights,
                        siteAttributes: parsedResponse.siteAttributes,
                        siteFound: true,
                        siteName: parsedResponse.siteName,
                        tenantName: parsedResponse.tenantName
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ siteFound: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.logError(parsedError, "AppRootComponent", "getSiteInfo");
                                this.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "AppRootComponent", "getSiteInfo");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "AppRootComponent", "getSiteInfo");
        }
    }

    convertAttributeToElement(jsScript) {
        let scriptRegex = new RegExp(/<(?<scriptType>script|noscript?)(?<scriptAttributes>[\s\S]*?)>(?<scriptCode>[\s\S]*?)<\/(script|noscript)>/g);
        let groupedMatch = null;
        let scriptElements = [];
        while (null != (groupedMatch = scriptRegex.exec(jsScript))) {
            let scriptElement = document.createElement(groupedMatch.groups["scriptType"]);
            if (groupedMatch.groups["scriptAttributes"]) {
                groupedMatch.groups["scriptAttributes"].split(' ').forEach((scriptAttribute) => {
                    if (!scriptAttribute)
                        return;

                    if (scriptAttribute.indexOf("=") === -1) {
                        scriptElement.setAttribute(scriptAttribute, true);
                    } else {
                        let attributeKey = scriptAttribute.substring(0, scriptAttribute.indexOf("="));
                        let attributeValue = scriptAttribute.substring(scriptAttribute.indexOf("=") + 1);
                        scriptElement.setAttribute(attributeKey, attributeValue.replaceAll('"', ''));
                    }
                });
            }

            if (groupedMatch.groups["scriptCode"]) {
                scriptElement.textContent = groupedMatch.groups["scriptCode"];
            }

            scriptElements.push(scriptElement);
        }

        return scriptElements;
    }


    onPageLoad(pageState) {
        try {
            document.title = pageState.pageTitle
        }
        catch (error) {
            console.logError(error, "AppRootComponent", "onPageLoad");
        }
    }

    onShowAlert(alertType, alertObj, alertTimeout) {
        let message = "";
        let txnId = "";
        if (alertObj.responseJSON !== undefined || alertObj.message !== undefined || alertObj.title !== undefined) {
            alertObj = alertObj.responseJSON ? alertObj.responseJSON : alertObj;
            message = alertObj.message ? alertObj.message : alertObj.title ? alertObj.title : alertObj.toString();
            txnId = alertObj.txnId ? alertObj.txnId : "";
        }
        else if (alertObj.status && alertObj.status === "403") {
            message = "Unauthorized request. Please contact your System Administrator.";
        }

        this.setState({
            alertType: alertType,
            message: message,
            txnId: txnId
        });

        if (alertTimeout > 0) {
            this.autoHideTimeout = setTimeout(this.onHideAlert, alertTimeout);
        } else {
            if (this.autoHideTimeout) {
                clearTimeout(this.autoHideTimeout);
                this.autoHideTimeout = null;
            }
        }
    }

    onHideAlert() {
        this.onShowAlert("", "", "", 0);
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
            this.autoHideTimeout = null;
        }
    }

    render() {
        return <Fragment>
            <SellToWebAdminAlertComponent alertType={this.state.alertType} message={this.state.message} txnId={this.state.txnId} onHideAlert={this.onHideAlert} />
            <Routes>
                {
                    window.isConsumer
                        ? this.state.siteFound && this.state.applicationInsights
                            ? <Fragment>
                                <Route index element={<HomeRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="vehicle-details" element={<VehicleDetailsRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="vehicle-equipment" element={<VehicleEquipmentRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="vehicle-history" element={<VehicleHistoryRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="vehicle-condition" element={<VehicleConditionRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="your-details" element={<YourDetailsRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="vehicle-offer" element={<VehicleOfferRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="confirmation" element={<VehicleConfirmationRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="offer" element={<VehicleOfferRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="unique-vehicle" element={<UniqueVehicleRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="offer-error" element={<OfferErrorRouteComponent siteAttributes={this.state.siteAttributes} applicationInsights={this.state.applicationInsights} tenantName={this.state.tenantName} siteName={this.state.siteName} onPageLoad={this.onPageLoad} />} />
                                <Route path="*" element={<PageNotFoundRouteComponent onPageLoad={this.onPageLoad} />} />
                            </Fragment>
                            : this.state.siteFound != null
                                ? <Route path="*" element={<PageNotFoundRouteComponent onPageLoad={this.onPageLoad} />} />
                                : null
                        : this.state.applicationInsights
                            ? <Fragment>
                                <Route index element={<GlobalHomeRouteComponent onPageLoad={this.onPageLoad} />} />
                                <Route path="SignIn" element={<SignInRouteComponent onPageLoad={this.onPageLoad} />} />
                                <Route path="SignOut" element={<SignOutRouteComponent onPageLoad={this.onPageLoad} />} />
                                <Route path="HealthCheck" element={<HealthCheckRouteComponent onShowAlert={this.onShowAlert} onHideAlert={this.onHideAlert} onPageLoad={this.onPageLoad} applicationInsights={this.state.applicationInsights} />} />
                                <Route path="*" element={<AdminRouteComponent onShowAlert={this.onShowAlert} onHideAlert={this.onHideAlert} applicationInsights={this.state.applicationInsights} onPageLoad={this.onPageLoad} />} />
                            </Fragment>
                            : null
                }
            </Routes>
        </Fragment>;
    }
}
