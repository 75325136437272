import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import $ from "jquery";
import "jquery-sparkline";

import { CommonService } from "../../js_modules/CommonService";
import { SellToWebAdminEditBoxComponent } from "../selltowebeditbox/SellToWebAdminEditBoxComponent";
import { SellToWebAdminEditDropdownComponent } from "../selltowebeditdropdown/SellToWebAdminEditDropdownComponent";
import { SellToWebAdminCheckboxComponent } from "../selltowebcheckbox/SellToWebAdminCheckboxComponent";
import { SellToWebAdminToggleComponent } from "../selltowebcheckbox/SellToWebAdminToggleComponent";
import { SellToWebAdminDropdownComponent } from "../selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../selltowebinput/SellToWebAdminInputComponent";
import { SellToWebSpinnerComponent } from "../selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebTableColumnCheckboxComponent } from "./SellToWebTableColumnCheckboxComponent";
import { SellToWebTableColumnCheckboxV2Component } from "./SellToWebTableColumnCheckboxV2Component";
import { SellToWebTableColumnDrCrComponent } from "./SellToWebTableColumnDrCrComponent";
import { SellToWebTableColumnDropdownComponent } from "./SellToWebTableColumnDropdownComponent";
import { SellToWebTableColumnInputComponent } from "./SellToWebTableColumnInputComponent";
import { SellToWebTableColumnSparklineComponent } from "./SellToWebTableColumnSparklineComponent";
import { SellToWebTableCustomComponent } from "./SellToWebTableCustomComponent"
import { SellToWebTableFilterComponent } from "./SellToWebTableFilterComponent";
import { SellToWebTableFooterComponent } from "./SellToWebTableFooterComponent";
import { SellToWebTableHeaderComponent } from "./SellToWebTableHeaderComponent";
import { SellToWebTableRowComponent } from "./SellToWebTableRowComponent";

export class SellToWebTableComponent extends Component {
    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            if (props.tableData !== state.prevTableData) {
                let tableData = props.tableData;
                if (state.filterText !== "") {
                    tableData = CommonService.filterJsonData(tableData, state.filterColumn, state.filterText, state.filterDataType);
                }
                if (state.sortColumn) {
                    tableData = CommonService.sortJsonData(tableData, state.sortColumn, state.sortOrder);
                }

                newState.tableData = tableData;
                newState.prevTableData = props.tableData;
                if (state.maxRows > tableData.length) {
                    newState.maxRows = props.tablePageSize;
                }
            }
        }
        catch (error) {
            console.logError(error, "SellToWebTableComponent", "getDerivedStateFromProps");
        }

        return newState;
    }

    constructor(props) {
        super(props);
        this.state = {
            tableData: props.tableData,
            prevTableData: props.tableData,
            showFilter: false,
            showCancelFilter: false,
            filterText: "",
            filterColumn: null,
            filterDataType: null,
            filterTop: null,
            filterLeft: null,
            sortColumn: props.defaultSortColumn,
            sortOrder: props.defaultSortOrder,
            maxRows: props.tablePageSize,
            showEdit: false,
            showEditDropdown: false,
            editRowData: null,
            editColumnComponent: null,
            editHeaderText: "",
            editTop: null,
            editLeft: null,
            editText: "",
            showFloatingHeader: "d-none",
            floatingHeaderTop: "0px",
            floatingHeaderWidth: "0px",
            showFloatingColumn: "d-none",
            floatingColumnTop: "0px",
            floatingColumnLeft: "0px"
        };

        this.onSortClick = this.onSortClick.bind(this);
        this.onShowFilterClick = this.onShowFilterClick.bind(this);
        this.onHideFilterClick = this.onHideFilterClick.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onCancelFilterClick = this.onCancelFilterClick.bind(this);
        this.onShowEditClick = this.onShowEditClick.bind(this);
        this.onHideEditClick = this.onHideEditClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onCellClick = this.onCellClick.bind(this);
        this.onDdpItemClick = this.onDdpItemClick.bind(this);
        this.onDdpSaveClick = this.onDdpSaveClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.onDocumentClick.bind(this));
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;

        if (nextProps.paintTable === undefined) {
            shouldUpdate = true;
        }
        else {
            // Force repaint on filter
            if (this.state.showFilter !== nextState.showFilter
                || this.state.showCancelFilter !== nextState.showCancelFilter
                || nextState.forceRepaint) {
                shouldUpdate = true;
            }
            // Force repaint on sort
            else if (this.state.sortColumn !== nextState.sortColumn
                || this.state.sortOrder !== nextState.sortOrder) {
                shouldUpdate = true;
            }
            // Force repaint on infinite scroll
            else if (this.state.maxRows !== nextState.maxRows) {
                shouldUpdate = true;
            }
            // Force repaint on edit box show
            else if (this.state.showEdit !== nextState.showEdit) {
                shouldUpdate = true;
            }
            else if (this.state.showEditDropdown !== nextState.showEditDropdown) {
                shouldUpdate = true;
            }
            else if (this.state.floatingHeaderTop !== nextState.floatingHeaderTop
                || this.state.floatingHeaderWidth !== nextState.floatingHeaderWidth
                || this.state.showFloatingHeader !== nextState.showFloatingHeader) {
                shouldUpdate = true;
            }
            else if (this.state.showFloatingColumn !== nextState.showFloatingColumn
                || this.state.floatingColumnTop !== nextState.floatingColumnTop
                || this.state.floatingColumnLeft !== nextState.floatingColumnLeft) {
                shouldUpdate = true;
            }
            // Repaint if required
            else if (nextProps.paintTable !== undefined) {
                shouldUpdate = nextProps.paintTable;
            }
        }

        return shouldUpdate;
    }

    componentDidUpdate(prevProps, prevState) {
        let rowComponents = null;
        if (Array.isArray(this.props.children)) {
            rowComponents = this.props.children.filter(item => item.type === SellToWebTableRowComponent);
        }
        else {
            rowComponents = [this.props.children];
        }

        rowComponents.forEach((rowComponent) => {
            let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
            columnComponents.forEach((columnComponent, columnIndex) => {
                if (columnComponent) {
                    if (columnComponent.type === SellToWebTableColumnSparklineComponent) {
                        if (this.state.tableData) {
                            this.state.tableData.forEach((rowData, rowIndex) => {
                                let values = [];
                                if (columnComponent.props.valueFields) {
                                    columnComponent.props.valueFields.forEach((valueField, index) => {
                                        values.push(rowData[valueField]);
                                    });
                                }

                                let sparkLineId = "sparkline_" + rowIndex + "_" + columnIndex;
                                $(this[sparkLineId]).sparkline(values, columnComponent.props.options);
                            });
                        }
                    }
                }
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.onDocumentClick);
    }

    footerValues;

    renderColumns(rowComponent, rowData, rowIndex) {
        let tableColumns = [];
        let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
        let columnSpan = 0;
        columnComponents.forEach((columnComponent, columnIndex) => {
            if (columnSpan > 1) {
                columnSpan--;
                return;
            }

            if (rowData[columnComponent.props.dataColumn + "_colSpan"]) {
                columnSpan = parseInt(rowData[columnComponent.props.dataColumn + "_colSpan"]);
            }

            let className = "";
            let cellStyle = this.getCellStyle(rowData, columnComponent.props.backgroundColor, columnComponent.props.backgroundColorPct, columnComponent.props.backgroundSize, columnComponent.props.color);
            if (rowData[columnComponent.props.dataColumn + "_hideCell"]) {
                tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan} onClick={(event) => this.onCellClick(event, columnComponent, rowData)} />);
            }
            else {
                if (columnComponent.type === SellToWebTableColumnSparklineComponent) {
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <div ref={refElement => this["sparkline_" + rowIndex + "_" + columnIndex] = refElement} />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <SellToWebSpinnerComponent className="float-end" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === SellToWebTableColumnCheckboxComponent) {
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <SellToWebAdminCheckboxComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            disabled={rowData[columnComponent.props.disabledColumn]} value={rowData[columnComponent.props.dataColumn]}
                            onChange={columnComponent.props.onChange}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <SellToWebSpinnerComponent className="float-end" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === SellToWebTableColumnCheckboxV2Component) {
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <SellToWebAdminToggleComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            disabled={rowData[columnComponent.props.disabledColumn]} value={rowData[columnComponent.props.dataColumn]} label={rowData[columnComponent.props.labelColumn]} label2={rowData[columnComponent.props.label2Column]}
                            onChange={columnComponent.props.onChange}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <SellToWebSpinnerComponent className="float-end" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === SellToWebTableColumnDropdownComponent) {
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    let selectedText = rowData[columnComponent.props.dataColumn] ? rowData[columnComponent.props.dataColumn] : columnComponent.props.placeholder;
                    tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <SellToWebAdminDropdownComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData}
                            disabled={rowData[columnComponent.props.disabledColumn]} data={columnComponent.props.data} itemKey={columnComponent.props.itemKey} value={selectedText ? selectedText : "Select Value"}
                            inputClassName={rowData[columnComponent.props.dataColumn + "_inputClassName"]}
                            onItemClick={columnComponent.props.onItemClick} onKeyDown={columnComponent.props.onKeyDown}
                        />
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <SellToWebSpinnerComponent className="float-end" showSpinner />
                                : null
                        }
                    </td>);
                }
                else if (columnComponent.type === SellToWebTableColumnInputComponent) {
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    tableColumns.push(<td key={"column" + columnIndex} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan}>
                        <SellToWebAdminInputComponent id={columnComponent.props.dataColumn + "-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData} enterToTab
                            disabled={rowData[columnComponent.props.dataColumn + "_disabled"]} readOnly={rowData[columnComponent.props.dataColumn + "_readOnly"]} placeholder={columnComponent.props.placeholder} value={rowData[columnComponent.props.dataColumn] ? rowData[columnComponent.props.dataColumn] : ""}
                            inputType={columnComponent.props.inputType} controlClassName={columnComponent.props.controlClassName}
                            onChange={columnComponent.props.onChange} onKeyDown={columnComponent.props.onKeyDown} onClearClick={columnComponent.props.onClearClick} onLookupClick={columnComponent.props.onLookupClick}
                        />
                    </td>);
                }
                else {
                    let data = "";

                    // Get the cell data
                    if (columnComponent.props.dataText) {
                        data = <span dangerouslySetInnerHTML={{ __html: columnComponent.props.dataText }} />;
                    }
                    else if (columnComponent.type === SellToWebTableColumnDrCrComponent) {
                        let amount = rowData[columnComponent.props.dataColumn];
                        if (amount > 0) {
                            data = "DR";
                        }
                        else if (amount < 0) {
                            data = "CR";
                        }
                    }
                    else {
                        data = columnComponent.props.dataColumnCallback
                            ? columnComponent.props.dataColumnCallback(rowData)
                            : this.getData(rowData, columnComponent.props.dataColumn);
                    }

                    // Apply dataType formatting
                    if (columnComponent.props.isDrCr) {
                        data = Math.abs(data);
                    }

                    if (columnComponent.props.dataType === "accounting_amount" || columnComponent.props.dataType === "accounting_amount_simple") {
                        data = Math.abs(data);
                    }

                    if (columnComponent.props.dataType && !columnComponent.props.dataColumnCallback) {
                        data = this.formatData(columnComponent.props.dataType, data);
                    }

                    if (columnComponent.props.ofDataColumn) {
                        data = data + " (" + this.formatData("percent", rowData[columnComponent.props.ofDataColumn]) + ")";
                    }

                    let tooltipTitle = data;
                    if (columnComponent.props.maxLength && data && data.length > columnComponent.props.maxLength) {
                        data = data.substring(0, columnComponent.props.maxLength - 3);
                    }

                    // Apply cell formatting
                    className = this.getColumnClassName(columnComponent, rowData[columnComponent.props.dataColumn + "_cellClassName"], rowIndex);
                    let negetiveAmountClassName = "";
                    let amount = rowData[columnComponent.props.dataColumn];
                    if ((columnComponent.props.dataType === "formatted_number_2" || columnComponent.props.dataType === "money") && amount < 0) {
                        className += (columnComponent.props.dataType === "formatted_number_2" ? " admin-color-red" : "")
                        negetiveAmountClassName = " admin-color-red";
                    }
                    let tdRef = "column-" + rowIndex + "-" + columnIndex;
                    tableColumns.push(<td key={"column" + columnIndex} ref={refElement => this[tdRef] = refElement} className={className} style={cellStyle} rowSpan={columnComponent.props.rowSpan} colSpan={columnSpan > 0 ? columnSpan : columnComponent.props.colSpan} onClick={(event) => this.onCellClick(event, columnComponent, rowData)}>
                        {
                            columnComponent.props.isEditable
                                ? <Fragment>
                                    <button type="button" className="btn btn-link" onClick={(event) => this.onShowEditClick(event, tdRef, rowData, columnComponent)} disabled={rowData[columnComponent.props.disabledColumn]}>
                                        <span className="fas fa-edit" />
                                    </button>
                                    &nbsp;&nbsp;
                                </Fragment>
                                : null
                        }
                        {
                            columnComponent.props.dataType === "anchor"
                                ? <a href={rowData[columnComponent.props.href]} target={columnComponent.props.target}>{data}</a>
                                : columnComponent.props.onCellClick
                                    ? <button type="button" className={"btn btn-link text-start" + negetiveAmountClassName} onClick={(event) => columnComponent.props.onCellClick(event, rowData, columnComponent.props.cellData)}>{data}</button>
                                    : <Fragment>
                                        {
                                            columnComponent.props.showHeader
                                                ? <span className="app-fw-700" dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText + ": " }} />
                                                : null
                                        }
                                        {data}
                                        {
                                            columnComponent.props.maxLength && data && tooltipTitle.length > columnComponent.props.maxLength
                                                ? <span data-bs-toggle="tooltip" title={tooltipTitle}>...</span>
                                                : null
                                        }
                                    </Fragment>
                        }
                        {
                            columnComponent.props.showSpinner && rowData[columnComponent.props.dataColumn + "_showSpinner"]
                                ? <SellToWebSpinnerComponent className="float-end" showSpinner />
                                : null
                        }
                    </td>);
                }
            }
        });

        return tableColumns;
    }

    getData(rowData, dataColumn) {
        let data;
        if (dataColumn) {
            let dataColumns = dataColumn.split(".");
            if (dataColumns.length > 1 && rowData[dataColumns[0]]) {
                data = rowData;
                dataColumns.forEach((columnName) => {
                    data = data[columnName];
                });
            }
            else {
                data = rowData[dataColumn];
            }

            if (Array.isArray(data)) {
                data = data.join(", ");
            }
        }

        return data;
    }

    formatData(dataType, data) {
        if (data === undefined || data === null) {
            return "";
        }

        switch (dataType) {
            case "number":
                data = Math.round(data);
                break;

            case "number_1":
                data = (Math.ceil(data * 10) / 10).toFixed(1);
                break;

            case "number_2":
                data = (Math.ceil(data * 100) / 100).toFixed(2);
                break;

            case "number_3":
                data = (Math.ceil(data * 1000) / 1000).toFixed(3);
                break;

            case "date":
                data = CommonService.formatDate(moment(data));
                break;

            case "date_mmddyy":
                data = CommonService.formatDate_mmddyy(moment(data, "MMDDYY", true));
                break;

            case "date_mmddyyyy":
                data = CommonService.formatDate_mmddyyyy(moment(data, "YYYY-MM-DD"));
                break;

            case "dateTime":
                data = CommonService.formatDateTime(moment(data));
                break;

            case "dateTime_1":
                data = CommonService.formatDateTime_1(moment(data));
                break;

            case "dateTime_2":
                data = CommonService.formatDateTime_2(moment(data));
                break;

            case "shortMonth":
                data = CommonService.formatMonth(moment().date(1).month(data - 1).year(2017));
                break;

            case "time":
                data = CommonService.formatTime(moment(data));
                break;

            case "bool":
                data = CommonService.formatBoolString(data);
                break;

            case "money":
                data = CommonService.formatCurrency(data);
                break;

            case "signed_money":
                data = CommonService.formatSignedCurrency(data);
                break;

            case "money_2":
                data = CommonService.formatCurrency_2(data);
                break;

            case "money_2_simple":
                data = CommonService.formatCurrency_2_simple(data);
                break;

            case "accounting_amount":
                data = CommonService.formatAccountingAmount(data);
                break;

            case "accounting_amount_simple":
                data = CommonService.formatAccountingAmount_simple(data);
                break;

            case "percent":
                data = CommonService.formatPercentage(data);
                break;

            case "signed_percent":
                data = CommonService.formatSignedPercentage(data);
                break;

            case "percent_1":
                data = CommonService.formatPercentage_1(data);
                break;

            case "percent_2":
                data = CommonService.formatPercentage_2(data);
                break;

            case "formatted_number":
                data = CommonService.formatNumber(data);
                break;

            case "formatted_number_2":
                data = CommonService.formatNumber_2(data);
                break;

            case "html":
                data = <span dangerouslySetInnerHTML={{ __html: data }} />
                break;

            case "phone":
                data = CommonService.formatPhoneNumber(data, false);
                break;
            default:
                break;
        }

        return data;
    }

    getColumnClassName(columnComponent, cellClassName, rowIndex) {
        let className = columnComponent.props.className ?? "";
        className += cellClassName ? " " + cellClassName : "";
        if (!columnComponent.props.isEditable && (columnComponent.props.dataType === "number" || columnComponent.props.dataType === "number_1" || columnComponent.props.dataType === "number_2" || columnComponent.props.dataType === "number_3"
            || columnComponent.props.dataType === "formatted_number" || columnComponent.props.dataType === "formatted_number_2"
            || columnComponent.props.dataType === "money" || columnComponent.props.dataType === "signed_money" || columnComponent.props.dataType === "money_2" || columnComponent.props.dataType === "money_2_simple"
            || columnComponent.props.dataType === "accounting_amount" || columnComponent.props.dataType === "accounting_amount_simple"
            || columnComponent.props.dataType === "percent" || columnComponent.props.dataType === "signed_percent" || columnComponent.props.dataType === "percent_1" || columnComponent.props.dataType === "percent_2")) {

            className += " text-end";
        }

        if (columnComponent.props.columnBand1 && rowIndex % 2 === 0) {
            className += " " + columnComponent.props.columnBand1;
        }

        if (columnComponent.props.columnBand2 && rowIndex % 2 !== 0) {
            className += " " + columnComponent.props.columnBand2;
        }

        if (!this.props.showFooter && rowIndex === this.state.tableData.length - 1) {
            className += columnComponent.props.footerClassName === undefined ? "" : " " + columnComponent.props.footerClassName;
        }

        return className;
    }

    getCellStyle(rowData, backgroundColor, backgroundColorPct, backgroundSize, color) {
        let cellStyle = {};
        if (rowData && backgroundColorPct) {
            cellStyle.background = "linear-gradient(to right, " + rowData[backgroundColor] + " " + rowData[backgroundColorPct] + "%, transparent " + rowData[backgroundColorPct] + "%)";
        }

        else if (rowData && backgroundColor && backgroundSize) {
            cellStyle.backgroundImage = "linear-gradient(to right, " + rowData[backgroundColor] + ", " + rowData[backgroundColor] + ")";
            cellStyle.backgroundRepeat = "no-repeat";
            cellStyle.backgroundSize = rowData[backgroundSize] + "% 100%";
        }

        else if (rowData && backgroundColor) {
            cellStyle.backgroundColor = rowData[backgroundColor];
        }

        if (rowData && color) {
            cellStyle.color = rowData[color];
        }

        return cellStyle;
    }

    getAllColumnComponents(rowComponent) {
        if (!Array.isArray(rowComponent)) {
            rowComponent = [rowComponent];
        }

        return rowComponent;
    }

    getVisibleColumnComponents(rowComponent) {
        rowComponent = this.getAllColumnComponents(rowComponent);
        rowComponent = rowComponent.filter(columnComponent => columnComponent && !columnComponent.props.isHidden);
        return rowComponent;
    }

    prepareHeader(rowComponent) {
        let headerRow = [];
        let headerColSpan = 0;
        let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
        columnComponents.forEach((columnComponent, index) => {
            if (headerColSpan > 1) {
                headerColSpan--;
                return;
            }

            if (columnComponent) {
                if (columnComponent.props.headerColSpan) {
                    headerColSpan = parseInt(columnComponent.props.headerColSpan);
                }

                if (columnComponent.type === SellToWebTableColumnCheckboxComponent) {
                    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerClassName ?? "")} colSpan={columnComponent.props.headerColSpan}>
                        <SellToWebAdminCheckboxComponent id={columnComponent.props.dataColumn + "_selectAll"} disabled={columnComponent.props.disableSelectAll} value={columnComponent.props.selectAll} onChange={columnComponent.props.onChangeAll} />
                    </td>);
                }
                //else if (columnComponent.type === SellToWebTableColumnCheckboxV2Component) {
                //    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerClassName ?? "")} colSpan={columnComponent.props.headerColSpan}>
                //        <SellToFormCheckboxV2Component id={columnComponent.props.dataColumn + "_selectAll"} disabled={columnComponent.props.disableSelectAll} value={columnComponent.props.selectAll} onChange={columnComponent.props.onChangeAll} />
                //    </td>);
                //}
                else {
                    headerRow.push(<td key={"header" + index} ref={refElement => this["header-cell" + index] = refElement} className={"text-center " + (columnComponent.props.headerClassName ?? "")} colSpan={columnComponent.props.headerColSpan}>
                        {
                            columnComponent.props.sortColumn
                                ? Array.isArray(columnComponent.props.sortColumn)
                                    ? <div className="d-inline dropdown admin-dropdown">
                                        <a href={(event) => false} role="button" className="app-fw-700" id="sortList" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} style={{ marginRight: "5px" }} />
                                            {
                                                columnComponent.props.sortColumn.filter(sortCol => sortCol.column === this.state.sortColumn).length === 1
                                                    ? <Fragment>
                                                        {columnComponent.props.sortColumn.filter(sortCol => sortCol.column === this.state.sortColumn)[0].displayName}
                                                        {
                                                            this.state.sortOrder === "Asc"
                                                                ? <span className="far fa-sort-up admin-table-sort" />
                                                                : <span className="far fa-sort-down admin-table-sort" />
                                                        }
                                                    </Fragment>
                                                    : null
                                            }
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="sortList">
                                            {
                                                columnComponent.props.sortColumn.map((sortProp, index) => (
                                                    <button key={"dropdown-item admin-dropdown-item-" + index} className="btn btn-link dropdown-item admin-dropdown-item" onClick={(event) => this.onSortClick(event, columnComponent, sortProp)}>{sortProp.displayName}
                                                        {
                                                            this.state.sortColumn === sortProp.column
                                                                ? this.state.sortOrder === "Asc"
                                                                    ? <span className="far fa-sort-up admin-table-sort" />
                                                                    : <span className="far fa-sort-down admin-table-sort" />
                                                                : null
                                                        }
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : <a href={(event) => false} role="button" className="app-fw-700" onClick={(event) => this.onSortClick(event, columnComponent)}>
                                        <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                                        {
                                            this.state.sortColumn === columnComponent.props.sortColumn
                                                ? this.state.sortOrder === "Asc"
                                                    ? <span className="far fa-sort-up admin-table-sort" />
                                                    : <span className="far fa-sort-down admin-table-sort" />
                                                : null
                                        }
                                    </a>
                                : <span dangerouslySetInnerHTML={{ __html: columnComponent.props.headerText }} />
                        }
                        {
                            columnComponent.props.filterColumn
                                ? this.state.filterColumn === columnComponent.props.filterColumn
                                    ? <button type="button" className="btn btn-link app-fw-700" onClick={(event) => this.onCancelFilterClick(event)}>
                                        <span style={{ textDecoration: "line-through" }} className="fas fa-filter admin-table-sort" />
                                    </button>
                                    : <button type="button" className="btn btn-link app-fw-700" onClick={(event) => this.onShowFilterClick(event, "header-cell" + index, columnComponent.props.filterColumn, columnComponent.props.dataType)}>
                                        <span className="far fa-filter admin-table-sort" />
                                    </button>
                                : null
                        }
                        {
                            columnComponent.props.headerCallback
                                ? columnComponent.props.headerCallback(columnComponent)
                                : null
                        }
                        {
                            columnComponent.props.columnHelp
                                ? <Fragment>
                                    <br />
                                    <div className="tooltip admin-tooltip" data-display="static">
                                        <span className="far fa-info-circle admin-table-column-help" data-display="static" />
                                        <div className="content" data-display="static">
                                            {columnComponent.props.columnHelp}
                                        </div>
                                    </div>
                                </Fragment>
                                : null
                        }
                    </td>);
                }
            }
        });

        return <tr id="sellToWebRwHeader" key="row-header" className="admin-table-header app-fw-700">
            {headerRow}
        </tr>;
    }

    prepareFooter(rowComponent) {
        let footerRow = [];
        this.footerValues = [];
        if (this.props.showFooter) {
            let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
            columnComponents.forEach((columnComponent, columnIndex) => {
                let footerValue = null;
                if (columnComponent.props.footerText) {
                    footerValue = columnComponent.props.footerText;
                } else if (this.props.footerRow && columnComponent.props.dataColumn) {
                    footerValue = this.getData(this.props.footerRow, columnComponent.props.dataColumn);
                    footerValue = this.formatData(columnComponent.props.dataType, footerValue);
                }
                else if (columnComponent.props.footerFunction) {
                    switch (columnComponent.props.footerFunction) {
                        case "sum":
                            footerValue = 0;
                            this.state.tableData.forEach((rowData) => {
                                let value = parseFloat(this.getData(rowData, columnComponent.props.dataColumn));
                                if (!isNaN(value)) {
                                    footerValue += value;
                                }
                            });
                            break;

                        case "average":
                            footerValue = 0;
                            if (this.state.tableData.length > 0) {
                                this.state.tableData.forEach((rowData) => {
                                    let value = parseFloat(this.getData(rowData, columnComponent.props.dataColumn));
                                    if (!isNaN(value)) {
                                        footerValue += value;
                                    }
                                });

                                footerValue = footerValue / this.state.tableData.length;
                            }
                            break;

                        default:
                            break;
                    }

                    if (columnComponent.props.isDrCr) {
                        footerValue = Math.abs(footerValue);
                    }

                    if (columnComponent.type === SellToWebTableColumnDrCrComponent) {
                        if (!columnComponent.props.isDrCrHidden) {
                            if (footerValue > 0) {
                                footerValue = "DR";
                            }
                            else if (footerValue < 0) {
                                footerValue = "CR";
                            }
                        }
                        else {
                            footerValue = "";
                        }
                    }
                    else {
                        footerValue = this.formatData(columnComponent.props.dataType, footerValue);
                    }
                }

                this.footerValues.push(footerValue);
                let className = columnComponent.props.footerClassName ?? "";
                let cellStyle = this.getCellStyle(this.props.footerRow, columnComponent.props.backgroundColor, columnComponent.props.backgroundColorPct, columnComponent.props.backgroundSize, columnComponent.props.color);
                footerRow.push(<td key={"footer" + columnIndex} className={className} style={cellStyle}>
                    {
                        columnComponent.props.onFooterCellClick
                            ? <button type="button" className="btn btn-link app-fw-700" onClick={(event) => columnComponent.props.onFooterCellClick(event, this.props.footerRow)}>{footerValue}</button>
                            : footerValue
                    }
                </td>);
            });
            //}
        }

        return this.props.showFooter
            ? <tr key="row-footer" className="admin-row-bg-head-foot app-fw-700">
                {footerRow}
            </tr>
            : null;
    }

    onSortClick(event, columnComponent, sortProps) {
        let columnSortOrder = "Asc";
        if (columnComponent.props.firstSortOrder)
            columnSortOrder = columnComponent.props.firstSortOrder;

        let sortColumn = columnComponent.props.sortColumn;
        if (sortProps) {
            sortColumn = sortProps.column;
            if (sortProps.firstSortOrder) {
                columnSortOrder = sortProps.firstSortOrder;
            }
        }

        if (this.state.sortColumn && this.state.sortColumn === sortColumn) {
            columnSortOrder = this.state.sortOrder;
            if (columnSortOrder === "Desc") {
                columnSortOrder = "Asc";
            } else {
                columnSortOrder = "Desc";
            }
        }

        // When infinite scroll is enabled. Pick the tableData to sort ons
        let tableData = this.props.tableData; // Full data set
        if (this.state.filterText !== "") {
            tableData = this.state.tableData; // Filtered data
        }

        let sortedTableData = CommonService.sortJsonData(tableData, sortColumn, columnSortOrder, columnComponent.props.dataType);
        this.setState({
            tableData: sortedTableData,
            sortColumn: sortColumn,
            sortOrder: columnSortOrder
        });

        if (this.props.onSortChange) {
            this.props.onSortChange(sortColumn, columnSortOrder, columnComponent.props.dataType);
        }
    }

    onShowFilterClick(event, headerCellRef, fltrColumn, dtType) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        let sellToTbl = ReactDOM.findDOMNode(this.sellToTable);
        let headerCell = ReactDOM.findDOMNode(this[headerCellRef]);
        this.setState({
            showFilter: true,
            showCancelFilter: true,
            filterColumn: fltrColumn,
            filterDataType: dtType,
            filterTop: sellToTbl.offsetTop,
            filterLeft: sellToTbl.offsetLeft + headerCell.offsetLeft + headerCell.offsetWidth - 15
        });
    }

    onHideFilterClick(event) {
        if (this.state.showFilter) {
            let showCancelFilter = this.state.showCancelFilter;
            let filterColumn = this.state.filterColumn;
            if (this.state.filterText === "") {
                showCancelFilter = false;
                filterColumn = "";
            }

            this.setState({
                showFilter: false,
                showCancelFilter: showCancelFilter,
                filterColumn: filterColumn
            });
        }
    }

    onFilterClick(event, filterText, filterOption) {
        if (filterText === "") {
            this.setState({
                tableData: this.props.tableData,
                filterText: filterText,
                showFilter: false,
                showCancelFilter: false,
                filterColumn: ""
            });
        }
        else {
            let filteredTableData = CommonService.filterJsonData(this.props.tableData, this.state.filterColumn, filterText, this.state.filterDataType, filterOption);
            this.setState({
                tableData: [],
                forceRepaint: true
            }, () => {
                this.setState({
                    tableData: filteredTableData,
                    filterText: filterText,
                    showFilter: false,
                    forceRepaint: false
                });
            });
        }
    }

    onCancelFilterClick(event) {
        this.setState({
            tableData: this.props.tableData,
            filterColumn: null,
            filterText: "",
            showFilter: false,
            showCancelFilter: false
        });
    }

    onDocumentClick(event) {
        let targetClassName = "";
        if (event.target) {
            targetClassName = event.target.getAttribute("class");
        }
        if (targetClassName && targetClassName.indexOf("dropdown-toggle") === -1 && targetClassName.indexOf("dropdown-item") === -1) {
            this.onHideFilterClick(event);
            this.onHideEditClick(event);
        }
    }

    onTableScroll(event) {
        let sellToTbl = ReactDOM.findDOMNode(this.sellToTable);
        if (sellToTbl) {
            let sellToPanelRect = event.target.getBoundingClientRect();
            let sellToRect = sellToTbl.getBoundingClientRect();
            if (this.props.tablePageSize && sellToTbl?.childNodes?.length > 1) {
                let lastRow = sellToTbl.childNodes[sellToTbl.childNodes.length - 1].getBoundingClientRect();
                if (lastRow.height === 0) {
                    lastRow = sellToTbl.childNodes[sellToTbl.childNodes.length - 2].getBoundingClientRect();
                }

                if (this.props.tablePageSize && (lastRow.bottom < sellToPanelRect.bottom)) {
                    if (this.state.maxRows && this.state.tableData && this.state.maxRows < this.state.tableData.length) {
                        let maxRows = this.state.maxRows + this.props.tablePageSize;
                        if (maxRows > this.state.tableData.length) {
                            maxRows = this.state.tableData.length;
                        }

                        this.setState({ maxRows: maxRows });
                    }
                }
            }

            if (this.props.stickyHeader) {
                let firstRow = sellToTbl.childNodes[0].getBoundingClientRect();
                if (firstRow.top < sellToPanelRect.top) {
                    let sellToFloatingRwHeader = $(this.sellToFloatingRwHeader).find("#sellToWebRwHeader")[0];
                    $(sellToTbl).find("#sellToWebRwHeader")[0].childNodes.forEach((headerCell, cellIndex) => {
                        $(sellToFloatingRwHeader.childNodes[cellIndex]).width($(headerCell).width());
                    });
                    this.setState({
                        floatingHeaderWidth: (sellToRect.width + 20) + "px",
                        floatingHeaderTop: Math.abs(sellToPanelRect.top - firstRow.top + 10) + "px",
                        showFloatingHeader: "d-block"
                    });
                }
                else {
                    this.setState({
                        showFloatingHeader: "d-none"
                    });
                }
            }

            if (this.props.freezeColumn) {
                if (sellToRect.left < 0) {
                    let sellToFloatingTBodies = $(this.sellToFloatingTable).find("tbody");
                    let sellToTBodies = $(sellToTbl).find("tbody");
                    sellToTBodies.each(function (index) {
                        $(sellToFloatingTBodies[index]).height($(this).height());
                    });
                    this.setState({
                        showFloatingColumn: "d-block",
                        floatingColumnLeft: Math.abs(sellToRect.left) + "px",
                        floatingColumnTop: $(sellToTbl).find("tbody")[0].offsetTop + "px"
                    });
                }
                else {
                    this.setState({
                        showFloatingColumn: "d-none"
                    });
                }
            }
        }
    }

    onShowEditClick(event, rowCellRef, rowData, columnComponent) {
        if (event.stopPropagation) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        else if (window.event) {
            window.event.cancelBubble = true;
        }

        let data = this.getData(rowData, columnComponent.props.dataColumn);
        let editHeaderText = columnComponent.props.headerText;
        if (columnComponent.props.editHeaderColumn) {
            editHeaderText = this.getData(rowData, columnComponent.props.editHeaderColumn);
        }

        let sellToTbl = ReactDOM.findDOMNode(this.sellToTable);
        let rowCell = ReactDOM.findDOMNode(this[rowCellRef]);
        let tBodyTop = $($(rowCell).parent()[0]).parent()[0].offsetTop;
        let tRowTop = $(rowCell).parent()[0].offsetTop;
        let editLeft = rowCell.offsetLeft + event.target.offsetWidth + 8;
        let editTop = tBodyTop + tRowTop;
        let editWidth = 100;
        if (columnComponent.props.dataType === "comment" || columnComponent.props.dataType === "html") {
            editWidth = 500;
        }

        if (editLeft + editWidth > sellToTbl.offsetWidth) {
            editLeft = sellToTbl.offsetWidth - editWidth;
        }

        let showEdit = true, showEditDropdown = false;

        if (columnComponent.props.data) {
            showEdit = false;
            showEditDropdown = true;
        }

        this.setState({
            showEdit: showEdit,
            showEditDropdown: showEditDropdown,
            editRowData: rowData,
            editColumnComponent: columnComponent,
            editHeaderText: editHeaderText,
            editTop: editTop,
            editLeft: editLeft,
            editText: data
        });
    }

    onHideEditClick(event) {
        if (this.state.showEdit || this.state.showEditDropdown) {
            this.setState({
                showEdit: false,
                showEditDropdown: false,
                editRowData: null,
                editColumnComponent: null,
                editHeaderText: "",
                editTop: null,
                editLeft: null,
                editText: ""
            });
        }
    }

    onEditClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onChange) {
            this.state.editColumnComponent.props.onChange(event, editText, this.state.editRowData);
        }

        this.onHideEditClick(event);
    }

    onCellClick(event, columnComponent, rowData) {
        if (event.detail === 3 && columnComponent.props.filterColumn) {
            let filteredTableData = CommonService.filterJsonData(this.props.tableData, columnComponent.props.filterColumn, rowData[columnComponent.props.filterColumn], columnComponent.props.filterColumn);
            this.setState({
                tableData: [],
                forceRepaint: true
            }, () => {
                this.setState({
                    showCancelFilter: true,
                    filterColumn: columnComponent.props.filterColumn,
                    filterDataType: columnComponent.props.dataType,
                    tableData: filteredTableData,
                    filterText: rowData[columnComponent.props.filterColumn],
                    showFilter: false,
                    forceRepaint: false
                })
            });
        }
    }

    onDdpItemClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onItemClick) {
            this.setState({
                editText: editText
            }, () => {
                this.state.editColumnComponent.props.onItemClick(event, editText, this.state.editRowData);
            })
        }
    }

    onDdpSaveClick(event, editText) {
        if (this.state.editColumnComponent && this.state.editColumnComponent.props && this.state.editColumnComponent.props.onSaveClick) {
            this.state.editColumnComponent.props.onSaveClick(event, editText, this.state.editRowData);
        }

        this.onHideEditClick(event);
    }

    onCopyClick(event) {
        try {
            let copyText = "";
            let rowComponents = null;
            let headerRowComponent = null;
            if (Array.isArray(this.props.children)) {
                rowComponents = this.props.children.filter(item => item.type === SellToWebTableRowComponent);
                headerRowComponent = this.props.children.filter(item => item.type === SellToWebTableRowComponent && item.props.hasHeader);
                if (headerRowComponent.length === 0) {
                    headerRowComponent = rowComponents[0];
                } else {
                    headerRowComponent = headerRowComponent[0];
                }
            }
            else {
                rowComponents = [this.props.children];
                headerRowComponent = rowComponents[0];
            }

            let columnComponents = this.getAllColumnComponents(headerRowComponent.props.children);

            // Header
            this.props.copyTableConfig.columns.forEach((columnIndex) => {
                copyText += columnComponents[columnIndex].props.headerText + "\t";
            });
            copyText = copyText.trim("\t");
            copyText += "\r\n";

            // Body
            this.state.tableData.forEach((rowData) => {
                this.props.copyTableConfig.columns.forEach((columnIndex) => {
                    if (columnComponents[columnIndex].props.dataText) {
                        copyText += columnComponents[columnIndex].props.dataText + "\t";
                    }
                    else if (columnComponents[columnIndex].type === SellToWebTableColumnDrCrComponent) {
                        let amount = rowData[columnComponents[columnIndex].props.dataColumn];
                        if (amount > 0) {
                            copyText += "DR\t";
                        }
                        else {
                            copyText += "CR\t";
                        }
                    }
                    else {
                        let data = this.getData(rowData, columnComponents[columnIndex].props.dataColumn);

                        // Apply dataType formatting
                        if (columnComponents[columnIndex].props.isDrCr) {
                            data = Math.abs(data);
                        }

                        if (columnComponents[columnIndex].props.dataType && !columnComponents[columnIndex].props.dataColumnCallback) {
                            let dataType = columnComponents[columnIndex].props.dataType;
                            switch (dataType) {
                                case "accounting_amount":
                                    dataType = "money_2";
                                    break;

                                case "accounting_amount_simple":
                                    dataType = "money_2_simple";
                                    break;

                                default:
                                    break;
                            }

                            data = this.formatData(dataType, data);
                        }

                        if (columnComponents[columnIndex].props.ofDataColumn) {
                            data = data + " (" + this.formatData("percent", rowData[columnComponents[columnIndex].props.ofDataColumn]) + ")";
                        }

                        copyText += (data ? data : "") + "\t";
                    }
                });

                copyText = copyText.replace("<br/>", "").replace("\n", "");
                copyText = copyText.trim("\t");
                copyText += "\r\n";
            });

            // Footer
            if (this.props.showFooter) {
                this.props.copyTableConfig.columns.forEach((columnIndex) => {
                    copyText += (this.footerValues[columnIndex] ? this.footerValues[columnIndex] : "") + "\t";
                });
            }

            copyText = copyText.trim("\t");
            navigator.clipboard.writeText(copyText)
                .then(() => {
                    if (this.props.copyTableConfig.onCopyTableCallBack) {
                        this.props.copyTableConfig.onCopyTableCallBack(event);
                    }
                });
        }
        catch (error) {
            console.logError(error, "SellToWebTableComponent", "onCopyClick");
        }
    }

    render() {
        let preHeaderComponents = null, postHeaderComponents = null;
        let preFooterComponents = null, postFooterComponents = null;
        let rowComponents = null;
        let customComponent = null;
        let headerRowComponent = null;
        if (Array.isArray(this.props.children)) {
            preHeaderComponents = this.props.children.filter(item => item.type === SellToWebTableHeaderComponent && item.props.position === "PreHeader");
            postHeaderComponents = this.props.children.filter(item => item.type === SellToWebTableHeaderComponent && item.props.position === "PostHeader");
            rowComponents = this.props.children.filter(item => item.type === SellToWebTableRowComponent);
            customComponent = this.props.children.filter(item => item.type === SellToWebTableCustomComponent)[0];
            headerRowComponent = this.props.children.filter(item => item.type === SellToWebTableRowComponent && item.props.hasHeader);
            if (headerRowComponent.length === 0) {
                headerRowComponent = rowComponents[0];
            } else {
                headerRowComponent = headerRowComponent[0];
            }
            preFooterComponents = this.props.children.filter(item => item.type === SellToWebTableFooterComponent && item.props.position === "PreFooter");
            postFooterComponents = this.props.children.filter(item => item.type === SellToWebTableFooterComponent && item.props.position === "PostFooter");
        }
        else {
            rowComponents = [this.props.children];
            headerRowComponent = rowComponents[0];
        }

        let header = this.prepareHeader(headerRowComponent);
        let footer = this.prepareFooter(headerRowComponent);
        let rowsTBodies = [];
        let floatingColumnRowsTBodies = [];
        if (this.state.tableData) {
            let maxRows = this.state.tableData.length;
            if (this.state.maxRows) {
                maxRows = this.state.maxRows;
            }

            for (let rowIndex = 0; rowIndex < maxRows && rowIndex < this.state.tableData.length; rowIndex++) {
                let rowData = this.state.tableData[rowIndex];
                let rows = [];
                let floatingColumnRows = [];
                rowComponents.forEach((rowComponent, rowComponentIndex) => {
                    let rowClassName = "";
                    if (rowComponent.props.className) {
                        rowClassName += " " + rowComponent.props.className;
                    }
                    if (rowData.rowClassName) {
                        rowClassName += " " + rowData.rowClassName;
                    }
                    if (rowData.lastRowClassName && rowComponentIndex === rowComponents.length - 1) {
                        rowClassName += " " + rowData.lastRowClassName;
                    }

                    let tableColumns = this.renderColumns(rowComponent, rowData, rowIndex);
                    rows.push(<tr key={"row-" + rowIndex + "-" + rowComponentIndex} className={rowClassName}>
                        {tableColumns}
                    </tr>);

                    if (this.props.freezeColumn) {
                        let freezeColumns = [];
                        let columnComponents = this.getVisibleColumnComponents(rowComponent.props.children);
                        let index = 0, count = 0;
                        while (count < this.props.freezeColumn) {
                            freezeColumns.push(tableColumns[index]);
                            count += parseInt(columnComponents[index].props.colSpan ? columnComponents[index].props.colSpan : 1);
                            index++;
                        }

                        floatingColumnRows.push(<tr key={"row-" + rowIndex + "-" + rowComponentIndex} className={rowClassName}>
                            {freezeColumns}
                        </tr>);
                    }
                    else if (customComponent) {
                        rows.push(<tr key={"row-custom-" + rowIndex}>
                            <td colSpan={rowComponent.props.children.length} className="admin-table-custom-component">
                                {
                                    React.cloneElement(customComponent.props.children, {
                                        rowData: rowData
                                    })
                                }
                            </td>
                        </tr>);

                    }
                });

                rowsTBodies.push(<tbody key={"body-" + rowIndex} className="admin-table-row">{rows}</tbody>);
                floatingColumnRowsTBodies.push(<tbody key={"body-" + rowIndex} className="admin-table-row">{floatingColumnRows}</tbody>);
            }
        }

        let cardClassName = "card ";
        cardClassName += this.props.cardClassName ?? "";

        let cardHeader = <div className="card-header">
            {this.props.headerText}
            {
                this.state.maxRows && this.state.tableData
                    ? <span>
                        &nbsp;&nbsp;&nbsp;&nbsp;{"Showing " + CommonService.formatNumber(Math.min(this.state.maxRows, this.state.tableData.length)) + "/" + CommonService.formatNumber(this.state.tableData.length) + " rows"}
                    </span>
                    : null
            }
            &nbsp;&nbsp;&nbsp;&nbsp;<span className="app-fs-14" dangerouslySetInnerHTML={{ __html: this.props.headerText2 }} />
            {
                this.props.copyTableConfig
                    ? <button type="button" className="btn btn-primary admin-copy-button" onClick={(event) => this.onCopyClick(event)}>
                        Copy<span className="far fa-copy admin-icon" />
                    </button>
                    : null
            }
            {
                this.props.legendText
                    ? <span className="admin-table-legend">
                        {this.props.legendText}
                    </span>
                    : null
            }
            {
                this.props.onCloseClick
                    ? <button type="button" className="btn btn-link float-end" onClick={(event) => this.props.onCloseClick(event)}>
                        <span className="fas fa-window-close admin-color-red" />
                    </button>
                    : null
            }
            {
                this.state.showCancelFilter
                    ? <button type="button" className="btn btn-link float-end" onClick={(event) => this.onCancelFilterClick(event)}>
                        <span style={{ textDecoration: "line-through" }} className="fas fa-filter" />Cancel filter
                    </button>
                    : null
            }
        </div>;

        return <div className={cardClassName}>
            <div className="d-flex flex-column admin-fill-content">
                {
                    this.props.headerText || this.props.onCloseClick || this.state.showCancelFilter || this.props.copyTableConfig
                        ? cardHeader
                        : null
                }
                <div className={"card-block position-relative admin-flex-item" + (this.props.cardBlockClassName ?? "")} onScroll={(event) => this.onTableScroll(event)}>
                    <SellToWebSpinnerComponent showSpinner={this.props.showSpinner} />
                    <SellToWebAdminEditBoxComponent
                        showEdit={this.state.showEdit}
                        top={this.state.editTop}
                        left={this.state.editLeft}
                        dataType={this.state.editColumnComponent ? this.state.editColumnComponent.props.dataType : ""}
                        headerText={this.state.editHeaderText}
                        text={this.state.editText}
                        icon="fas fa-edit"
                        placeholder=""
                        onHideClick={this.onHideEditClick}
                        onClick={this.onEditClick}
                    />
                    <SellToWebAdminEditDropdownComponent
                        id="editDropdown"
                        showEditDropdown={this.state.showEditDropdown}
                        top={this.state.editTop}
                        left={this.state.editLeft}
                        data={this.state.editColumnComponent ? this.state.editColumnComponent.props.data : null}
                        headerText={this.state.editHeaderText}
                        value={this.state.editText}
                        icon="fas fa-edit"
                        placeholder=""
                        onHideClick={this.onHideEditClick}
                        onItemClick={this.onDdpItemClick}
                        onSaveClick={this.onDdpSaveClick}
                    />
                    <SellToWebTableFilterComponent showFilter={this.state.showFilter} filterColumn={this.state.filterColumn} dataType={this.state.filterDataType}
                        filterText={this.state.filterText}
                        top={this.state.filterTop}
                        left={this.state.filterLeft}
                        onFilterClick={this.onFilterClick}
                        onHideFilterClick={this.onHideFilterClick}
                    />
                    <div ref={refElement => this.sellToFloatingRwHeader = refElement} className={"position-absolute " + this.state.showFloatingHeader} style={{ "zIndex": "1000", "margin": "-10px", "padding": "10px 10px 0px 10px", "width": this.state.floatingHeaderWidth, "top": this.state.floatingHeaderTop }}>
                        <table className={"admin-table " + (this.props.className ?? "")}>
                            <thead>
                                {
                                    preHeaderComponents
                                        ? preHeaderComponents
                                        : null
                                }
                                {header}
                                {
                                    postHeaderComponents
                                        ? postHeaderComponents
                                        : null
                                }
                            </thead>
                        </table>
                    </div>
                    <div className={"position-absolute " + this.state.showFloatingColumn} style={{ "zIndex": "999", "top": this.state.floatingColumnTop, "left": this.state.floatingColumnLeft }}>
                        <table className={"admin-table " + (this.props.className ?? "")} ref={refElement => this.sellToFloatingTable = refElement}>
                            {floatingColumnRowsTBodies}
                        </table>
                    </div>
                    <table className={"admin-table " + (this.props.className ?? "")} ref={refElement => this.sellToTable = refElement}>
                        <thead>
                            {
                                preHeaderComponents
                                    ? preHeaderComponents
                                    : null
                            }
                            {header}
                            {
                                postHeaderComponents
                                    ? postHeaderComponents
                                    : null
                            }
                        </thead>
                        {rowsTBodies}
                        <tfoot>
                            {
                                preFooterComponents
                                    ? preFooterComponents
                                    : null
                            }
                            {footer}
                            {
                                postFooterComponents
                                    ? postFooterComponents
                                    : null
                            }
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>;
    }
}