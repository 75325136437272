import React, { Component } from "react";

export class GlobalTenantQueryComponent extends Component {

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    <div className="col-auto">
                        <button className="btn btn-primary" onClick={this.props.onCreateTenantClick}>
                            Create Tenant
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
}
