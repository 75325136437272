import { Component, Fragment } from "react";
import { CommonService } from "../js_modules/CommonService";
import $ from "jquery";

import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebAdminCheckboxComponent } from "../common/selltowebcheckbox/SellToWebAdminCheckboxComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebModalConfirmationComponent } from "../common/selltowebmodalconfirmation/SellToWebModalConfirmationComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebInputFileComponent } from "../common/selltowebinputfilecomponent/SellToWebInputFileComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

import { TenantSiteDetailQueryComponent } from "./TenantSiteDetailQueryComponent";

export class TenantSiteDetailComponent extends Component {
    status = [
        { key: "Active", value: "Active" },
        { key: "Inactive", value: "Inactive" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            siteName: null,
            siteDomain: null,
            isProduction: false,
            crmUrl: null,
            crmDeliveryEmail: null,
            crmDeliveryFormat: null,
            status: null,
            deleteSite: false,
            tenantGuid: null,
            defaultZipCode: null,
            offerExpirationDays: null,
            deleteArtifact: null,
            siteArtifacts: [],
            paintTable: false,
            tenantShortName: null,
            logSiteName: null,
            useAutoCheck: false,
            usePlateToVin: false,
            customDomain1: null,
            customDomain2: null
        };

        this.onGetClick = this.onGetClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onDeleteArtifactClick = this.onDeleteArtifactClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onShowConfirmationClick = this.onShowConfirmationClick.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Site Detail";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Tenant/Site", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("TenantSiteDetailComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.getTenants();
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "componentDidMount");
        }
    }

    getTenants() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "TenantSiteDetailComponent/getTenants", properties: { logon: this.props.logon } });
            fetch("/TenantSite/GetTenants", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false,
                        tenants: parsedResponse
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetTenants", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteDetailComponent", "getTenants");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteDetailComponent", "getTenants");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "getTenants");
        }
    }

    getArtifacts(tenantGuid) {
        let inputData = {
            tenantGuid: tenantGuid,
            tenantShortName: this.state.tenantShortName,
            siteName: this.state.logSiteName
        };

        try {
            this.props.applicationInsights.trackTrace({ message: "TenantSiteDetailComponent/getArtifacts", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetSiteArtifacts", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        siteArtifacts: parsedResponse,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetSiteArtifacts", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(notOKResponse, parsedError);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "getArtifacts");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onTextChange", id);
        }
    }

    onDropdownChange(id, value) {
        try {
            this.setState({ [id]: value.key });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onDropdownChange", id);
        }
    }

    onCheckboxChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onCheckboxChange");
        }
    }

    onGetClick(selectedSite) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                siteGuid: selectedSite.guid
            };
            this.props.applicationInsights.trackTrace({ message: "TenantSiteDetailComponent/onGetClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/GetSiteDetails", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse = parsedResponse[0];
                    this.setState({
                        isLoadingData: false,
                        tenantGuid: parsedResponse.tenantGuid,
                        tenantShortName: parsedResponse.tenantShortName,
                        cardTitle: parsedResponse.tenantName + " - " + parsedResponse.siteName,
                        siteName: parsedResponse.siteName,
                        logSiteName: parsedResponse.siteName,
                        siteDomain: parsedResponse.siteDomain,
                        customDomain1: parsedResponse.customDomain1,
                        customDomain2: parsedResponse.customDomain2,
                        crmUrl: parsedResponse.crmUrl,
                        crmDeliveryEmail: parsedResponse.crmDeliveryEmail,
                        crmDeliveryFormat: parsedResponse.crmDeliveryFormat,
                        defaultZipCode: parsedResponse.defaultZip,
                        offerExpirationDays: parsedResponse.offerExpirationDays,
                        status: parsedResponse.status,
                        isProduction: parsedResponse.isProduction,
                        useAutoCheck: parsedResponse.useAutoCheck,
                        usePlateToVin: parsedResponse.usePlateToVin
                    }, () => {
                        this.getArtifacts(parsedResponse.tenantGuid);
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/GetSiteDetails", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "TenantSiteDetailComponent", "onGetClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "TenantSiteDetailComponent", "onGetClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onGetClick");
        }
    }

    onDeleteArtifactClick(event, artifactToDelete) {
        let inputData = {
            tenantGuid: this.state.tenantGuid,
            tenantShortName: this.state.tenantShortName,
            fileName: artifactToDelete.name
        };
        try {
            this.props.applicationInsights.trackTrace({ message: "TenantSiteDetailComponent/onDeleteArtifactClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/TenantSite/DeleteArtifact", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        deleteArtifact: null
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/DeleteArtifact", ...inputData, ...parsedError, logon: this.props.logon } });
                                this.setState({
                                    deleteArtifact: null
                                });
                                console.logError(notOKResponse, parsedError);
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onDeleteArtifactClick");
        }
    }

    onConfirmationOkClick(event) {
        try {
            this.onDeleteArtifactClick(event, this.state.deleteArtifact);
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onConfirmationOkClick");
        }
    }

    onConfirmationCancelClick(event, rowData) {
        try {
            this.setState({ deleteArtifact: null });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onConfirmationCancelClick");
        }
    }

    onShowConfirmationClick(event, rowData) {
        try {
            this.setState({ deleteArtifact: rowData });
        }
        catch (error) {
            console.logError(error, "TenantSiteDetailComponent", "onShowConfirmationClick");
        }
    }

    onActionColumnCallback(rowData) {
        return rowData.name === undefined
            ? <Fragment>
                <button type="button" className="btn btn-link" onClick={(event) => this.onDeleteArtifactClick(event, rowData)}>
                    <span className="fas fa-window-close text-danger app-fs-18 admin-icon" aria-hidden={true} />
                </button>
            </Fragment>
            : <button type="button" className="btn btn-link" onClick={(event) => this.onShowConfirmationClick(event, rowData)}>
                <span className="fas fa-window-close text-danger app-fs-18 admin-icon" aria-hidden={true} />
            </button>;
    }

    onFileUpload(event) {
        try {
            let fileUpload = $('#siteArtifactFile')[0];

            var formData = new FormData();
            formData.append("FileUpload", fileUpload.files[0]);
            formData.append("TenantGuid", this.state.tenantGuid);
            formData.append("TenantShortName", this.state.tenantShortName);

            this.setState({ showSpinner: true });

            try {
                this.props.applicationInsights.trackTrace({ message: "TenantSiteDetailComponent/onFileUpload", properties: { ...formData, logon: this.props.logon } });
                fetch("/TenantSite/UploadArtifact", {
                    method: "POST",
                    headers: {
                        'enctype': "multipart/form-data"
                    },
                    body: formData,
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.setState({ showSpinner: false });
                        this.getArtifacts(this.state.tenantGuid);
                        this.props.onShowAlert("success", { message: "Artifact uploaded successfully!" }, 4000);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/TenantSite/UploadArtifact", ...formData, ...parsedError, logon: this.props.logon } });
                                    console.logError(notOKResponse, parsedError);
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError);
                                });
                        }
                    })
            } catch (error) {
                console.logError(error, "TenantSiteDetailComponent", "onFileUpload");
            }
        }
        catch (error) {
            console.logError(error);
        }
    }

    render() {
        let saveInputBody = {
            siteName: this.state.siteName, siteDomain: this.state.siteDomain, customDomain1: this.state.customDomain1, customDomain2: this.state.customDomain2, crmUrl: this.state.crmUrl, crmDeliveryEmail: this.state.crmDeliveryEmail,
            crmDeliveryFormat: this.state.crmDeliveryFormat, defaultZipCode: this.state.defaultZipCode, offerExpirationDays: this.state.offerExpirationDays,
            status: this.state.status, isProduction: this.state.isProduction
        }

        return <div className="d-flex flex-column admin-fill-content admin-body">
            <SellToWebModalConfirmationComponent
                headerText={"Delete Artifact: " + (this.state.deleteArtifact !== null ? this.state.deleteArtifact.name : "")}
                bodyText={"Are you sure you want to delete " + (this.state.deleteArtifact !== null ? this.state.deleteArtifact.name : "") + "?"}
                showConfirmation={this.state.deleteArtifact !== null}
                onOkClick={this.onConfirmationOkClick}
                onCancelClick={this.onConfirmationCancelClick}
                className="admin-global-user-edit-modal" />
            <TenantSiteDetailQueryComponent
                tenants={this.state.tenants}
                onGetClick={this.onGetClick}
                isLoadingData={this.state.isLoadingData}
                isGlobalAdmin={this.props.isGlobalAdmin}
                saveInputBody={saveInputBody}
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
                logon={this.state.logon}
            />
            <br />
            <div className="admin-flex-item">
                <div className="d-flex flex-row">
                    <div className="card" style={{ "minWidth": "50%", "marginRight": "10px" }}>
                        <div className="card-header">
                            {this.state.cardTitle}
                        </div>
                        <div className="card-block">
                            <SellToWebAdminInputComponent id="siteName" label="Site Name" value={this.state.siteName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="siteDomain" label="Site Domain" value={this.state.siteDomain} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="customDomain1" label="Custom Domain 1" value={this.state.customDomain1} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="customDomain2" label="Custom Domain 2" value={this.state.customDomain2} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="crmUrl" label="CRM Url" value={this.state.crmUrl} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="crmDeliveryEmail" label="CRM Delivery Email" value={this.state.crmDeliveryEmail} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="crmDeliveryFormat" label="CRM Delivery Format" value={this.state.crmDeliveryFormat} onChange={this.onTextChange} colSpancolSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="defaultZipCode" label="Default zipcode" value={this.state.defaultZipCode} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent id="offerExpirationDays" label="Days to offer expiration" value={this.state.offerExpirationDays} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminDropdownComponent id="status" label="Status" data={this.status} itemKey="value" onItemClick={this.onDropdownChange} value={this.state.status ? this.state.status : "Select Status"} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminInputComponent disabled label="Tenant Image Folder" value={"https://cdn.acquirecars.com/acquirecars/" + this.state.tenantGuid + "/image"} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            <SellToWebAdminCheckboxComponent id="isProduction" label="Is Production" value={this.state.isProduction} controlClassName="align-middle" onChange={this.onCheckboxChange} colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                            {
                                this.props.isGlobalAdmin
                                    ? <Fragment>
                                        <SellToWebAdminCheckboxComponent id="useAutoCheck" label="Use AutoCheck" value={this.state.useAutoCheck} controlClassName="align-middle" onChange={this.onCheckboxChange} disabled colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                                        <SellToWebAdminCheckboxComponent id="usePlateToVin" label="Use Plate to Vin" value={this.state.usePlateToVin} controlClassName="align-middle" onChange={this.onCheckboxChange} disabled colSpan="col-12" labelSpan="col-4" controlSpan="col-8" />
                                    </Fragment>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="card admin-flex-item">
                        <div className="card-header">
                            <SellToWebInputFileComponent id="siteArtifactFile" accept="text" onFileChange={this.onFileUpload} />
                            <SellToWebSpinnerComponent className="admin-icon" showSpinner={this.state.showSpinner || this.props.isLoadingData} />
                        </div>
                        <div className="card admin-global-user-edit-card">
                            <div className="card-block">
                                <SellToWebTableComponent tableData={this.state.siteArtifacts} paintTable={this.state.paintTable}>
                                    <SellToWebTableRowComponent>
                                        <SellToWebTableColumnComponent headerText="Tenant Artifacts" dataColumn="name" />
                                        <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                                    </SellToWebTableRowComponent>
                                </SellToWebTableComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}