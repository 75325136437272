import React, {Component} from "react";
import moment from "moment";
import $ from "jquery";

import { SellToWebModalDialogHeaderButtonComponent } from "./SellToWebModalDialogHeaderButtonComponent";

export class SellToWebAdminModalDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    }

    componentDidMount() {
        $(this.modalContent).draggable({
            handle: ".card-header"
        });

        window.addEventListener("popstate", this.onPopState);
        document.addEventListener("keydown", this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyDown);
    }

    onSaveClick(event) {
        this.props.onSaveClick(event);
    }

    onCancelClick(event) {
        this.props.onCancelClick(event);
    }

    onDocumentKeyDown(event) {
        if (this.props.showDialog) {
            switch (event.keyCode) {
                case 27:
                    if (this.props.childModelClosedTime !== undefined
                        && this.props.childModelClosedTime !== null) {
                        let timeNow = moment();
                        if (timeNow - this.props.childModelClosedTime > 500) {
                            this.props.onCancelClick(event);
                        }
                    } else {
                        this.props.onCancelClick(event);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onPopState(event) {
        $(".modal").modal("hide");
        $(".modal-backdrop").remove();
        $(".in").remove();
    }

    render() {
        let headerButton = null;
        if (Array.isArray(this.props.children)) {
            headerButton = this.props.children.filter(item => item && item.type === SellToWebModalDialogHeaderButtonComponent)[0];
        }

        let className = "modal-dialog " + this.props.className;
        return <div className={"modal fade show " + (this.props.showDialog ? " d-block" : "")} tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true" style={this.props.style}>
            <div className={className} role="document">
                <div ref={refElement => this.modalContent = refElement} className="modal-content">
                    <div className="card">
                        <div className="card-header admin-modal-dialog-header">
                            <div className="d-inline-block align-middle">
                                <span dangerouslySetInnerHTML={{ __html: this.props.title }} />
                            </div>
                            <div className="d-inline-block float-end">
                                {
                                    headerButton
                                        ? headerButton.props.children
                                        : null
                                }
                                {
                                    !this.props.hideCancel
                                        ? <button type="button" className="btn btn-link admin-icon" onClick={(event) => this.onCancelClick(event)}>
                                            <i className="fas fa-window-close text-danger" />
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="card-block">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
