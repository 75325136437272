import { Component } from "react";

export class TenantSettingsRouteComponent extends Component {
    constructor(props) {
        super(props);
        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }
    pageTitle = "Tenant Settings";

    render() {
        return "Coming Soon ...";
    }

}