import React, { Component } from "react";

export class SellToWebCheckboxComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, this.props.additionalData);
        }
    }

    render() {
        return <div style={{ "position": "relative" }}>
            <div className="ac-site-toggle">
                <i id={this.props.id} onClick={(event) => this.onChange(event)}
                    className={"ac-site-checkbox ac-site-label " + (this.props.value ? "fas fa-solid fa-toggle-on slide-right" : "fal fa-toggle-off slide-left")} />
                &nbsp;
            </div>
            <label className={"ac-site-label " + (this.props.value ? "active" : "")}
                style={{ "verticalAlign": "top", "margin": "auto 5px", "fontSize": "14px" }}
                htmlFor={this.props.id} onClick={(event) => this.onChange(event)}>
                {this.props.label}
                {
                    this.props.additionalData.showWarning
                        ? <span style={{ "margin": "5px 0px 0px 10px" }}>
                            <div key={"key-" + this.props.alertId} id={this.props.alertId} className="alert alert-danger" style={{ "display": "inline", "padding": "5px", "fontSize": "10px" }}>
                                {this.props.additionalData.message}
                            </div>
                        </span>
                        : null
                }
            </label>
            {
                this.props.additionalData.showSpinner
                    ? <p className="ac-site-spinner consumer-checkbox-spinner consumer-checkbox-spinner-dual-ring" />
                    : null
            }
        </div>;
    }
}