import React, { Component, Fragment } from "react";

import { SellToWebAdminHeaderComponent } from "../common/selltowebheader/SellToWebAdminHeaderComponent";
import { SellToWebAdminFooterComponent } from "../common/selltowebfooter/SellToWebAdminFooterComponent";

export class AdminPageNotFoundRouteComponent extends Component {
  render() {
    return <Fragment>
      <SellToWebAdminHeaderComponent />
      <div className="text-center">
        <p>Page Not Found</p>
      </div>
      <SellToWebAdminFooterComponent />
    </Fragment>;
  }
}
