import { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebModalConfirmationComponent } from "../common/selltowebmodalconfirmation/SellToWebModalConfirmationComponent";

export class GlobalUserEditComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingData: false,
            tableData: [],
            paintTable: false,
            displayName: null,
            firstName: null,
            lastName: null,
            email: null,
            remoteUpn: null,
            status: null,
            assignedRoles: [],
            unassignedRoles: [],
            assignRole: null,
            selectedTenant: null,
            oldTenantGuid: null,
            deleteAssignedRole: null
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onAddClick = this.onAddClick.bind(this);
        this.onUnassignedRoleChange = this.onUnassignedRoleChange.bind(this);
        this.onAssignRoleClick = this.onAssignRoleClick.bind(this);
        this.onUnassignRoleClick = this.onUnassignRoleClick.bind(this);
        this.onShowConfirmationClick = this.onShowConfirmationClick.bind(this);
        this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this);
        this.onConfirmationCancelClick = this.onConfirmationCancelClick.bind(this);
        this.onTenantClick = this.onTenantClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onAssignedRoleColumnCallback = this.onAssignedRoleColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            if (this.props.showDialog && this.props.selectedUser && (prevProps.selectedUser !== this.props.selectedUser)) {
                CommonService.clientAzureStorageLog("GlobalUserEditComponent", "componentDidUpdate", null, null, null, null, this.props.applicationInsights);
                if (this.props.selectedUser.tenantGuid) {
                    let selectedTenant = this.props.tenants.find(tenant => tenant.guid === this.props.selectedUser.tenantGuid);
                    this.onTenantClick(null, selectedTenant);
                    this.getAssignedRoles();
                    this.getUnassignedRoles();
                }
            }
        } catch (error) {
            console.logError(error, "GlobalUserEditComponent", "componentDidUpdate");
        }
    }

    onTenantClick(event, selectedTenant) {
        try {
            this.setState({ selectedTenant: selectedTenant });
        } catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onTenantClick");
        }
    }

    onAssignRoleClick(event, rowData) {
        try {
            let inputData = {
                tenantUsrId: this.props.selectedUser.tenantUsrId,
                roleId: this.state.assignRole.roleId
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalUserEditComponent/onAssignRoleClick", properties: { ...inputData, logon: this.props.logon } });

            fetch("/GlobalUser/AssignRole", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.getAssignedRoles();
                    this.getUnassignedRoles();
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalUser/AssignRole", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalUserEditComponent", "onAssignRoleClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalUserEditComponent", "onAssignRoleClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onAssignRoleClick");
        }
    }

    onUnassignRoleClick(event, rowData) {
        try {
            if (rowData.roleMemberId === undefined) {
                let asgndRoles = this.state.assignedRoles;
                asgndRoles.pop();
                this.setState({
                    assignedRoles: asgndRoles,
                    assignRole: null,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            } else {
                let inputData = {
                    roleMemberId: rowData.roleMemberId
                };
                this.props.applicationInsights.trackTrace({ message: "GlobalUserEditComponent/onUnassignRoleClick", properties: { ...inputData, logon: this.props.logon } });
                fetch("/GlobalUser/UnassignRole", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                    .then(parsedResponse => {
                        this.getAssignedRoles();
                        this.getUnassignedRoles();
                        this.setState({
                            assignRole: null
                        }, this.onConfirmationCancelClick);
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalUser/UnassignRole", ...parsedError, logon: this.props.logon } });
                                    console.logError(parsedError, "GlobalUserEditComponent", "onUnassignRoleClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "GlobalUserEditComponent", "onUnassignRoleClick");
                                });
                        }
                    });
            }
            if (this.state.deleteAssignedRole !== null) {
                this.setState({ deleteAssignedRole: null });
            }
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onUnassignRoleClick");
        }
    }

    getAssignedRoles() {
        try {
            let inputData = {
                identityGuid: this.props.selectedUser.guid,
                logon: this.props.selectedUser.logon
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalUserEditComponent/getAssignedRoles", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalUser/GetAssignedRoles", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        assignedRoles: parsedResponse.table,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        assignedRoles: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                console.logError(parsedError, "GlobalUserEditComponent", "getAssignedRoles");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalUserEditComponent", "getAssignedRoles");
                            });
                    }
                });
        } catch (error) {
            console.logError(error, "GlobalUserEditComponent", "getAssignedRoles");
        }
    }

    getUnassignedRoles() {
        try {
            let inputData = {
                identityGuid: this.props.selectedUser.guid,
                logon: this.props.selectedUser.logon
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalUserEditComponent/getUnassignedRoles", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalUser/GetUnassignedRoles", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        unassignedRoles: parsedResponse.table,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalUser/GetUnassignedRoles", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalUserEditComponent", "getUnassignedRoles");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalUserEditComponent", "getUnassignedRoles");
                            });
                    }
                });
        } catch (error) {
            console.logError(error, "GlobalUserEditComponent", "getUnassignedRoles");
        }
    }

    onSaveClick() {
        try {
            this.setState({
                isLoadingData: true
            });

            let inputData = {
                newTenantGuid: this.state.selectedTenant.guid,
                userGuid: this.props.selectedUser.guid,
                oldTenantGuid: this.state.selectedTenant.guid !== this.props.selectedUser.tenantGuid ? this.props.selectedUser.tenantGuid : null
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalUserEditComponent/onSaveClick", properties: { ...inputData, logon: this.props.logon } });
            return fetch("/GlobalUser/UpdateUserTenant", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        isLoadingData: false
                    }, () => {
                        this.props.onShowAlert("success", { message: "Tenant updated successfully!" }, 4000);
                        this.props.onGetUsersClick();
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalUser/UpdateUserTenant", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalUserEditComponent", "onSaveClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalUserEditComponent", "onSaveClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onSaveClick");
        }
    }

    onConfirmationOkClick(event) {
        try {
            this.onUnassignRoleClick(event, this.state.deleteAssignedRole);
        }
        catch (error) {
            console.logError(error);
        }
    }

    onConfirmationCancelClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: null });
        }
        catch (error) {
            console.logError(error);
        }
    }

    onShowConfirmationClick(event, rowData) {
        try {
            this.setState({ deleteAssignedRole: rowData });
        }
        catch (error) {
            console.logError(error);
        }
    }

    clearForm() {
        this.setState({
            displayName: null,
            firstName: null,
            lastName: null,
            email: null,
            status: null,
            selectedTenant: null
        });
    }

    onCancelClick() {
        try {
            this.clearForm();
            this.props.onCancelClick();
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onCancelClick");
        }
    }

    onStatusChange(id, selectedValue) {
        try {
            this.setState({ [id]: selectedValue });
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onStatusChange", id);
        }
    }

    onAddClick(event) {
        try {
            let asgndRoles = this.state.assignedRoles;
            if (asgndRoles.length === 0 || asgndRoles[asgndRoles.length - 1].roleId !== undefined) {
                asgndRoles.push({});
                this.setState({
                    assignedRoles: asgndRoles,
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false });
                });
            }
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onAddClick");
        }
    }

    onUnassignedRoleChange(id, asgnRole) {
        try {
            this.setState({
                assignRole: asgnRole,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "GlobalUserEditComponent", "onUnassignedRoleChange");
        }
    }

    onAssignedRoleColumnCallback(rowData) {
        return rowData.roleId === undefined
            ? <SellToWebAdminDropdownComponent id="unassignedRole" data={this.state.unassignedRoles} itemKey="roleName" value={this.state.assignRole ? this.state.assignRole.roleName : "Select Role"} onItemClick={this.onUnassignedRoleChange} />
            : rowData.roleName;
    }

    onActionColumnCallback(rowData) {
        return rowData.roleId === undefined
            ? <Fragment>
                <button type="button" className="btn btn-link" onClick={(event) => this.onAssignRoleClick(event, rowData)}>
                    <span className="fas fa-save app-color-secondary-blue app-fs-18" aria-hidden={true} />
                </button>
                <button type="button" className="btn btn-link" onClick={(event) => this.onUnassignRoleClick(event, rowData)}>
                    <span className="fas fa-window-close text-danger app-fs-18 admin-icon" aria-hidden={true} />
                </button>
            </Fragment>
            : <button type="button" className="btn btn-link" onClick={(event) => this.onShowConfirmationClick(event, rowData)}>
                <span className="fas fa-window-close text-danger app-fs-18 admin-icon" aria-hidden={true} />
            </button>;
    }

    render() {
        return <SellToWebAdminModalDialogComponent
            showDialog={this.props.showDialog}
            className="admin-site-configuration-dialog"
            title="Global User"
            onCancelClick={this.onCancelClick}
        >
            <div className="row">
                <div className="col-6">
                    <div className="card-header">
                        {this.props.selectedUser?.logon}
                    </div>
                    <div className="admin-body admin-global-user-edit-admin-body">
                        <div className="card">
                            <div className="card-header">
                                <button className="btn btn-primary admin-icon " onClick={this.onSaveClick}>
                                    Save <i className="fas fa-save admin-icon" />
                                </button>
                            </div>
                        </div>
                        <div className="card admin-global-user-edit-card">
                            <div className="card-block">
                                <div className="row">
                                    <SellToWebAdminDropdownComponent id="tenant" label="Tenant" data={this.props.tenants} itemKey="name" onItemClick={this.onTenantClick} value={this.state.selectedTenant ? this.state.selectedTenant.name : "Select Tenant"} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" tenantLabel="admin-tenant-label" />
                                    <SellToWebAdminInputComponent id="displayName" label="Display name" disabled={true} value={this.props.selectedUser?.name} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                    <SellToWebAdminInputComponent id="firstName" label="First name" disabled={true} value={this.props.selectedUser?.givenName} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                    <SellToWebAdminInputComponent id="lastName" label="Last name" disabled={true} value={this.props.selectedUser?.surname} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                    <SellToWebAdminInputComponent id="remoteUpn" label="Remote UPN" disabled={true} value={this.props.selectedUser?.logon} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                    <SellToWebAdminInputComponent id="email" label="Email" disabled={true} value={this.props.selectedUser?.logon} onChange={this.onTextChange} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                    <SellToWebAdminDropdownComponent id="status" label="Status" data={["Active", "Inactive"]} onItemClick={this.onStatusChange} disabled={true} value={this.props.selectedUser?.status ? this.props.selectedUser?.status : "Select Status"} colSpan="col-12" labelSpan="col-3" controlSpan="col-9" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SellToWebModalConfirmationComponent
                    headerText={"Remove role: " + (this.state.deleteAssignedRole !== null ? this.state.deleteAssignedRole.roleName : "")}
                    bodyText={"Are you sure?"}
                    showConfirmation={this.state.deleteAssignedRole !== null}
                    onOkClick={this.onConfirmationOkClick}
                    onCancelClick={this.onConfirmationCancelClick}
                    className="admin-global-user-edit-modal" />
                <div className="col-6">
                    <div className="card-header">Roles</div>
                    <div className="admin-body admin-global-user-edit-admin-body">
                        <div className="card">
                            <div className="card-header">
                                <button disabled={!this.props.selectedUser?.tenantGuid} className="btn btn-primary admin-icon " onClick={this.onAddClick}>
                                    Add <i className="fas fa-plus admin-icon" />
                                </button>
                            </div>
                        </div>
                        <div className="card admin-global-user-edit-card">
                            <div className="card-block">
                                <SellToWebTableComponent tableData={this.state.assignedRoles} paintTable={this.state.paintTable} cardBlockClassName="admin-global-user-edit-card-dropdown">
                                    <SellToWebTableRowComponent>
                                        <SellToWebTableColumnComponent headerText="Assigned Role" dataColumnCallback={this.onAssignedRoleColumnCallback} />
                                        <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onActionColumnCallback} />
                                    </SellToWebTableRowComponent>
                                </SellToWebTableComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SellToWebAdminModalDialogComponent>;
    }
}