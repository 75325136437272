import React, { Component } from "react";

export class SellToWebRadioButtonComponent extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event, item) {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, item);
        }
    }

    render() {
        let rdlElements = [];
        this.props.data.forEach((item, index) => {
            rdlElements.push(
                <div key={"radio_" + index}>
                    <div className="ac-site-toggle">
                        <i id={"rdl_" + this.props.name + "_" + index} name={this.props.name}
                            onClick={(event) => this.onChange(event, item)}
                            className={"ac-site-checkbox ac-site-label " + (this.props.value === item[this.props.itemKey] ? "fas fa-solid fa-toggle-on slide-right" : "fal fa-toggle-off slide-left")} />
                        &nbsp;
                    </div>
                    <label className={"ac-site-label " + (this.props.value === item[this.props.itemKey] ? "active" : "")}
                        style={{ "display": "inline-block", "verticalAlign": "top", "margin": "auto 5px", "fontWeight": "500", "fontSize": "16px" }}
                        htmlFor={"rdl_" + this.props.name + "_" + index} onClick={(event) => this.onChange(event, item)}>
                        {item[this.props.itemKey]}
                    </label>
                    {
                        item.description && this.props.value === item[this.props.itemKey]
                            ? <div className="accordion-collapse ac-site-label"
                                style={{ "backgroundColor": "#fcfcfc", "padding": "10px", "margin": "-5px auto 10px 30px", "fontSize": "15px", "fontWeight": "500", "lineHeight": "30px" }}>
                                {item.description}
                            </div>
                            : null
                    }
                </div>
            )
        });

        return <div>
            <div className="accordion-item">
                {rdlElements}
            </div>
        </div>;
    }
}