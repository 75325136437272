import React, { Component } from "react";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogComponent";

export class VehicleConfirmationRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            readOfferResponse: null,
            redirectTo: "",
            showDialog: false,
            showInfoBox: false,
        }
        this.onClick = this.onClick.bind(this);
        this.onTermsClick = this.onTermsClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onDismissModalClick = this.onDismissModalClick.bind(this);
    }

    pageTitle = "Vehicle Confirmation";

    componentDidMount() {
        let offerNumber = "Unknown";
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/confirmation", pageType: "Consumer", isLoggedIn: "No", properties: { offerNumber: offerNumber, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            CommonService.clientAzureStorageLog("VehicleConfirmationRouteComponent", "componentDidMount", offerNumber, null, null, null, this.props.applicationInsights);
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            window.addEventListener("click", this.handleClickOutside);
            const params = new URLSearchParams(window.location.search)
            const newParams = new URLSearchParams();
            for (const [name, value] of params) {
                newParams.append(name.toLowerCase(), value);
            }

            if (newParams.has("offernumber")) {
                offerNumber = newParams.get("offernumber");
                this.readOfferApi(offerNumber);
            }
        }
        catch (error) {
            console.logError(error, "VehicleConfirmationRouteComponent", "componentDidMount", offerNumber);
        }

    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleClickOutside);
    }

    onClick(event) {
        event.stopPropagation();
        this.setState(prevState => ({
            showInfoBox: !prevState.showInfoBox
        }));
    }

    onTermsClick(event) {
        try {
            CommonService.clientAzureStorageLog("VehicleConfirmationRouteComponent", "onTermsClick", null, null, null, null, this.props.applicationInsights);
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.logError(error, "VehicleConfirmationRouteComponent", "onTermsClick");
        }
    }

    handleClickOutside(event) {
        this.setState({ showInfoBox: false });
    }

    onDismissModalClick() {
        try {
            this.setState({ showDialog: false });
        }
        catch (error) {
            console.logError(error, "VehicleConfirmationRouteComponent", "onDismissModalClick");
        }
    }

    onMissingImage(event) {
        try {
            CommonService.clientAzureStorageLog("VehicleConfirmationRouteComponent", "CDN image not found", this.state.readOfferResponse?.vehicleDetail?.imageUrl, null, null, null, this.props.applicationInsights);
            // if (this.props.onMissingImage) {
            //     this.props.onMissingImage();
            // }
        }
        catch (error) {
            console.logError(error, "VehicleConfirmationRouteComponent", "onMissingImage");
        }
    }

    readOfferApi(value) {
        try {
            let inputData = {
                offerNumber: value
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleConfirmationRouteComponent/readOfferApi", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleConfirmation/ReadOffer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errorMessage) {
                        CommonService.clientAzureStorageLog("VehicleConfirmationRouteComponent", "readOfferApi", "fetch ReadOffer " + inputData?.offerNumber, "Redirect to offer-error", parsedResponse.errorMessage, null, this.props.applicationInsights);
                        this.setState({ redirectTo: "/offer-error" });
                    }
                    else {
                        this.setState({ readOfferResponse: parsedResponse });
                    }
                    this.props.applicationInsights.trackEvent({ message: "VehicleConfirmationRouteComponent/readOfferApi", properties: { offerNumber: value, vin: parsedResponse?.vin, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleConfirmation/ReadOffer", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleConfirmationRouteComponent", "readOfferApi");
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleConfirmationRouteComponent", "readOfferApi");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleConfirmationRouteComponent", "readOfferApi");
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let vehicleDetail = this.state.readOfferResponse ? this.state.readOfferResponse.vehicleDetail : {};
        let vehicleCondition = this.state.readOfferResponse ? this.state.readOfferResponse.vehicleCondition : {};
        let offer = this.state.readOfferResponse ? this.state.readOfferResponse.offer : {};
        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Offer Confirmation", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Offer Confirmation", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Offer Confirmation", "Default", "Body Html")?.Value ?? "";
        return <div className="ac-site ac-offerconfirmation">
            <header className="ac-site-header ac-offerconfirmation-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={null} />
            <SellToWebModalDialogComponent
                showDialog={this.state.showDialog} showFooter
                title="Terms & Conditions"
                onOkClick={this.onDismissModalClick}
                className="consumer-right-panel-terms-condition">
                <h4 className="consumer-right-panel-terms-condition-title">Our lawyers made us write this...</h4>
                <p>{this.props.siteAttributes
                    ? <span dangerouslySetInnerHTML={{ __html: CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Terms & Conditions Html").Value }} />
                    : ""
                }
                </p>
            </SellToWebModalDialogComponent>
            <section className="consumer-page">
                <section className="consumer-main">
                    <div className="consumer-vehicleconfirmation-top-panel">
                        <h2 className="text-center" style={{ fontSize: "25px" }}>You're on the road to car-selling happiness!</h2>
                        <div className="consumer-top-confirmation-inspection-center">
                            <div className="consumer-right-panel-header-inspection consumer-vehicleconfirmation-header-inspection">
                                <img className="consumer-right-panel-car-img consumer-right-panel-car-inspection-img" src={vehicleDetail.imageUrl} alt="EVOX" onError={(event) => this.onMissingImage(event)} />
                                <div className="consumer-right-panel-car-evox-info-icon">
                                    <img className="consumer-right-panel-car-evox-icon" src="images/evox-info.svg" alt="Info" onClick={this.onClick} />
                                </div>
                            </div>
                            {
                                this.state.showInfoBox
                                    ? <div className={"d-flex align-items-center consumer-right-panel-car-evox-info-box " + (this.props.showInspection ? "consumer-right-panel-car-evox-info-box-confirmation" : "")}>
                                        <img src="images/photo-camera.svg" alt="camera" style={{ marginRight: "16px" }} />
                                        <div>
                                            <b style={{ fontSize: "12px" }}>Images provided by EVOX</b>
                                            <p style={{ fontSize: "12px", marginBottom: "0px" }}>Image may not be a perfect match for your vehicle’s model year, trim, body style, or color.</p>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="consumer-top-confirmation-val">
                            <b style={{ fontSize: "14px" }}>{vehicleDetail.year + " " + vehicleDetail.make + " " + vehicleDetail.model}</b><br />
                            {vehicleDetail.trim}
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="fw-bold" style={{ fontSize: "18px" }}>{CommonService.getSiteAttributeValue(this.props.siteAttributes, "Offer Confirmation", "Default", "Offer Label").Value}</div>
                            <div className="fw-bold ac-site-offer-value" style={{ fontSize: "18px", color: "#0271e6" }}>${CommonService.formatMoney(offer.offer)}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div style={{ fontSize: "13px" }}>Expires on {CommonService.formatDate(offer.expirationDate)}</div>
                            <div style={{ textDecoration: "underline", cursor: "pointer", fontSize: "13px" }} onClick={this.onTermsClick}>*Terms & Conditions</div>
                        </div>
                        <hr />
                    </div>

                    <section className="ac-site-body ac-offerconfirmation-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={vehicleDetail.vin} makePlusModel={vehicleDetail.year + " " + vehicleDetail.make + " " + vehicleDetail.model}
                        imageUrl={vehicleDetail.imageUrl} differentColorShown="" odometer={vehicleDetail.odometer} showInspection
                        offerPrice={offer.offer ?? ""} expirationDate={offer.offer ? CommonService.formatDate(offer.expirationDate) : ""}
                        engine={vehicleDetail ? vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Engine")).map((arrayData => arrayData.optionName)) : ""}
                        transmission={vehicleDetail ? vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Transmission")).map((arrayData => arrayData.optionName)) : ""}
                        driveTrain={vehicleDetail ? vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Drivetrain")).map((arrayData => arrayData.optionName)) : ""}
                        trim={vehicleDetail ? vehicleDetail.trim : ""}
                        minimumCondition={CommonService.getMinimumSelectedCondition(vehicleCondition.paintBody, vehicleCondition.windowsLights, vehicleCondition.wheelsTires, vehicleCondition.upholstery, vehicleCondition.interiorEquipment, vehicleCondition.mechanical)}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                        colorBox={vehicleDetail.colorRgb1 ? "#" + vehicleDetail.colorRgb1 : CommonService.checkCSSColorSupports(vehicleDetail.colorSimpleTitle)}
                        colorName={vehicleDetail.colorTitle !== "" ? vehicleDetail.colorTitle : vehicleDetail.colorSimpleTitle}
                    />
                </aside>
            </section>
            <footer className="ac-site-footer ac-offerconfirmation-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}