import React, { Component, Fragment } from "react";

export class SellToWebAdminAlertComponent extends Component {
    constructor(props) {
        super(props);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
    }

    onCancelClick(event) {
        if (this.props.onHideAlert) {
            this.props.onHideAlert();
        }
    }

    onCopyClick(event) {
        let message = this.props.message;
        if (this.props.txnId) {
            message += "\r\nTransaction Id: " + this.props.txnId;
        }

        navigator.clipboard.writeText(message)
            .then(() => {
                if (this.props.onCopyTableCallBack) {
                    this.props.onCopyTableCallBack(event);
                }
            });
    }

    render() {
        if (this.props.message)
            return <div className="admin-alert">
                <div className={"alert show fade alert-" + this.props.alertType} role="alert">
                    <button type="button" className="btn-close float-end" aria-label="Close" onClick={(event) => this.onCancelClick(event)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div>
                        {
                            this.props.message
                        }
                        {
                            this.props.txnId
                                ? <Fragment>
                                    < br />
                                    Transaction Id: {this.props.txnId}
                                </Fragment>
                                : null
                        }
                        {
                            this.props.alertType === "danger"
                                ? <button className="btn btn-primary float-end" onClick={(event) => this.onCopyClick(event)}>
                                    Copy<span className="far fa-copy admin-icon" />
                                </button>
                                : null
                        }
                    </div>
                </div>
            </div>;
        else
            return null;
    }
}
