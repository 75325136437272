import { Component, Fragment } from "react";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableHeaderComponent } from "../common/selltowebtable/SellToWebTableHeaderComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebAdminTextAreaComponent } from "../common/selltowebtextarea/SellToWebAdminTextAreaComponent";

export class GlobalSettingsCacheComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableTitle: "",
            tableData: [],
            paintTable: false,
        };

        this.onRedisCacheTextChange = this.onRedisCacheTextChange.bind(this);
        this.onRedisCacheExpireClick = this.onRedisCacheExpireClick.bind(this);
        this.onRedisCacheGetClick = this.onRedisCacheGetClick.bind(this);
        this.onRedisValueColumnCallback = this.onRedisValueColumnCallback.bind(this);
        this.onRedisActionColumnCallback = this.onRedisActionColumnCallback.bind(this);

        this.onMemoryCacheTextChange = this.onMemoryCacheTextChange.bind(this);
        this.onMemoryCacheExpireClick = this.onMemoryCacheExpireClick.bind(this);
        this.onMemoryCacheGetClick = this.onMemoryCacheGetClick.bind(this);
        this.onMemoryValueColumnCallback = this.onMemoryValueColumnCallback.bind(this);
        this.onMemoryActionColumnCallback = this.onMemoryActionColumnCallback.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Cache";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Global/Settings/Cache", pageType: "Consumer/Admin", isLoggedIn: "Yes", properties: { logon: this.props.logon } });
            CommonService.clientAzureStorageLog("GlobalSettingsCacheComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
            this.onGetCache();
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "componentDidMount");
        }
    }

    onGetCache() {
        try {
            this.setState({ isLoadingData: true });
            this.props.applicationInsights.trackTrace({ message: "GlobalSettingsCacheComponent/onGetCache", properties: { logon: this.props.logon } });
            fetch("/GlobalSettings/GetCache", {
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse)
                        this.setState({
                            tableData: CommonService.addOrdinalColumn(parsedResponse),
                            paintTable: true,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    else {
                        this.setState({
                            tableData: [],
                            paintTable: true,
                            isLoadingData: false
                        }, () => {
                            this.setState({ paintTable: false });
                        });
                    }
                })
                .catch(notOKResponse => {
                    this.setState({
                        tableData: [],
                        paintTable: true,
                        isLoadingData: false
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalSettings/GetCache", ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalSettingsCacheComponent", "onGetCache");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalSettingsCacheComponent", "onGetCache");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "onGetCache");
        }
    }

    onRedisValueColumnCallback(rowData) {
        return <SellToWebAdminTextAreaComponent id={rowData.redis_key} value={rowData.redis_value} onChange={this.onRedisCacheTextChange} rows="3" className="admin-client-cache-value" />;
    }

    onRedisActionColumnCallback(rowData) {
        return <Fragment>
            <button disabled={this.state.isLoadingData} className="btn btn-primary admin-icon" onClick={(event) => this.onRedisCacheExpireClick(event, rowData)}>
                Expire <span className="fas fa-minus-circle admin-icon" />
            </button>
            <button disabled={this.state.isLoadingData} className="btn btn-primary admin-icon" onClick={(event) => this.onRedisCacheGetClick(event, rowData)}>
                Get <i className="fas fa-redo-alt admin-icon" />
            </button>
        </Fragment>;
    }

    onRedisCacheExpireClick(event, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.redis_showSpinner_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                key: rowData.redis_key
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalSettingsCacheComponent/onRedisCacheExpireClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalSettings/ExpireRedisCache", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.redis_value = "";
                    rowData.redis_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.redis_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalSettings/ExpireRedisCache", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalSettingsCacheComponent", "onRedisCacheExpireClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalSettingsCacheComponent", "onRedisCacheExpireClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "onRedisCacheExpireClick");
        }
    }

    onRedisCacheGetClick(event, rowData) {
        let tableData = this.state.tableData;
        rowData.redis_showSpinner_showSpinner = true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            this.onGetCache();
        });
    }

    onRedisCacheTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter(redisCache => redisCache.redis_key === id)[0];
            rowData.redis_value = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "onRedisCacheTextChange");
        }
    }

    onMemoryValueColumnCallback(rowData) {
        return <SellToWebAdminTextAreaComponent id={rowData.memory_key} value={rowData.memory_value} onChange={this.onMemoryCacheTextChange} rows="3" className="admin-client-cache-value" />;
    }

    onMemoryActionColumnCallback(rowData) {
        return <Fragment>
            <button disabled={this.state.isLoadingData} className="btn btn-primary admin-icon" onClick={(event) => this.onMemoryCacheExpireClick(event, rowData)}>
                Expire <span className="fas fa-minus-circle admin-icon" />
            </button>
            <button disabled={this.state.isLoadingData} className="btn btn-primary admin-icon" onClick={(event) => this.onMemoryCacheGetClick(event, rowData)}>
                Get <i className="fas fa-redo-alt admin-icon" />
            </button>
        </Fragment>;
    }

    onMemoryCacheExpireClick(event, rowData) {
        let tableData = this.state.tableData;
        try {
            rowData.memory_showSpinner_showSpinner = true;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
            let inputData = {
                key: rowData.memory_key
            };
            this.props.applicationInsights.trackTrace({ message: "GlobalSettingsCacheComponent/onMemoryCacheExpireClick", properties: { ...inputData, logon: this.props.logon } });
            fetch("/GlobalSettings/ExpireMemoryCache", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.memory_value = "";
                    rowData.memory_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.memory_showSpinner_showSpinner = false;
                    this.setState({
                        tableData: [],
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/GlobalSettings/ExpireMemoryCache", ...inputData, ...parsedError, logon: this.props.logon } });
                                console.logError(parsedError, "GlobalSettingsCacheComponent", "onMemoryCacheExpireClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "GlobalSettingsCacheComponent", "onMemoryCacheExpireClick");
                            });
                    }
                })
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "onMemoryCacheExpireClick");
        }
    }

    onMemoryCacheGetClick(event, rowData) {
        let tableData = this.state.tableData;
        rowData.memory_showSpinner_showSpinner = true;
        this.setState({
            tableData: tableData,
            paintTable: true
        }, () => {
            this.setState({ paintTable: false });
            this.onGetCache();
        });
    }

    onMemoryCacheTextChange(id, value) {
        try {
            let tableData = this.state.tableData;
            let rowData = tableData.filter(memoryCache => memoryCache.memory_key === id)[0];
            rowData.memory_value = value;
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "GlobalSettingsCacheComponent", "onMemoryCacheTextChange");
        }
    }

    render() {
        return <div className="admin-body">
            <SellToWebTableComponent headerText="Redis and Memory Cache" tableData={this.state.tableData} paintTable={this.state.paintTable}>
                <SellToWebTableHeaderComponent position="PreHeader">
                    <tr className="admin-table-header">
                        <td className="app-border-right" />
                        <td colSpan="4" className="admin-cache-head-redis-color text-center font-weight-bold  app-border-right">Redis Cache</td>
                        <td colSpan="4" className="admin-cache-head-memory-color text-center font-weight-bold ">Memory Cache</td>
                    </tr>
                </SellToWebTableHeaderComponent>
                <SellToWebTableRowComponent>
                    <SellToWebTableColumnComponent headerText="Key" dataColumn="redis_key" sortColumn="redis_key" filterColumn="redis_key" headerClassName="admin-client-cache-key app-border-right" className="app-border-right" />
                    <SellToWebTableColumnComponent headerText="TTL (Seconds)" dataColumn="redis_ttl" sortColumn="redis_ttl" filterColumn="redis_ttl" className="text-right" headerClassName="admin-client-cache-key" />
                    <SellToWebTableColumnComponent headerText="Expiration Time (PST)" dataColumn="redis_exp_time" sortColumn="redis_exp_time" filterColumn="redis_exp_time" headerClassName="admin-client-cache-key" className="text-nowrap" />
                    <SellToWebTableColumnComponent headerText="Value" dataColumnCallback={this.onRedisValueColumnCallback} headerClassName="admin-client-cache-value" />
                    <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onRedisActionColumnCallback} dataColumn="redis_showSpinner" headerClassName=" app-border-right" className="text-nowrap app-border-right" showSpinner />
                    <SellToWebTableColumnComponent headerText="TTL (Seconds)" dataColumn="memory_ttl" sortColumn="memory_ttl" filterColumn="ttl" className="text-right" headerClassName="admin-client-cache-key" />
                    <SellToWebTableColumnComponent headerText="Expiration Time (PST)" dataColumn="memory_exp_time" sortColumn="memory_exp_time" filterColumn="exp_time" headerClassName="admin-client-cache-key" className="text-nowrap" />
                    <SellToWebTableColumnComponent headerText="Value" dataColumnCallback={this.onMemoryValueColumnCallback} headerClassName="admin-client-cache-value" />
                    <SellToWebTableColumnComponent headerText="Action" dataColumnCallback={this.onMemoryActionColumnCallback} dataColumn="memory_showSpinner" showSpinner className="text-nowrap" />
                </SellToWebTableRowComponent>
            </SellToWebTableComponent>
        </div>;
    }
}